export const numberFormat = (number) => {
  const numberFormatter = Intl.NumberFormat("en-US");
  const formatted = numberFormatter.format(number);

  return formatted;
};

export const range = (start, end) => {
	/* generate a range : [start, start+1, ..., end-1, end] */
	var len = end - start + 1;
	var a = new Array(len);
	for (let i = 0; i < len; i++) a[i] = start + i;
	return a;
}

export const currentYear = new Date().getFullYear();
