import {
  faPlusCircle,
  faAngleRight,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/modal.css";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { faMultiply } from "@fortawesome/free-solid-svg-icons";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import API from "../../../utils/API";
import axios from "axios";

const Edit = ({ program, fetchPrograms }) => {
  // Modal open state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [studyLevels, setStudyLevels] = useState([]);
  const [campuses, setCampuses] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [errors, setErrors] = useState([]);
  const defaultData = {
    name: "",
    faculty_id: "",
    study_level_id: [],
    about: "",
    program_learning_outcomes: [],
    career_outcomes: [],
    offered_campuses: [],
    eligibility_criteria: "",
    eligibility_criteria_note: "",
    semesters: "",
    credit_hours: "",
    study_modes: "",
    meta_title: "",
    image: "",
    video: "",
    meta_keywords: "",
    meta_description: "",
  };
  const [data, setData] = useState(defaultData);
  const [activeTab, setActiveTab] = useState("1");

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData(document.getElementById("edit-form"));
      plos.forEach((plo, index) => {
        formData.append(`program_learning_outcomes[${index}][id]`, plo.id);
        formData.append(
          `program_learning_outcomes[${index}][content]`,
          plo.content
        );
      });
      careerOutcomes.forEach((careerOutcome, index) => {
        formData.append(`career_outcomes[${index}][id]`, careerOutcome.id);
        formData.append(
          `career_outcomes[${index}][content]`,
          careerOutcome.content
        );
      });

      formData.set(
        "image",
        formData.get("image") ? formData.get("image") : data.image
      );

      formData.set(
        "video",
        formData.get("video") ? formData.get("video") : data.video
      );

      formData.append("_method", "PATCH");
      const response = await API.post(`/api/programs/${data.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setLoading(false);
      fetchPrograms();
      toggle();
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setData(program);
    setPLOs(
      program.program_learning_outcomes.map((item) => {
        return { id: uuidv4(), ...item };
      })
    );
    setCareerOutcomes(
      program.career_outcomes.map((item) => {
        return { id: uuidv4(), ...item };
      })
    );
  }, [program]);

  useEffect(() => {
    fetchCampuses();
  }, []);

  useEffect(() => {
    fetchStudyLevels();
  }, []);

  useEffect(() => {
    fetchFaculties();
  }, []);

  const fetchStudyLevels = async () => {
    try {
      setLoading(true);
      const response = (await API.get("/api/study-levels")).data;
      setStudyLevels(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchCampuses = async () => {
    try {
      setLoading(true);
      const response = (await API.get("/api/campuses")).data;
      setCampuses(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchFaculties = async () => {
    try {
      setLoading(true);
      const response = (await API.get("/api/faculties")).data;
      setFaculties(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleChangeMultiple = (e) => {
    if (errors.length) setErrors([]);
    let opts = [],
      opt;
    for (let i = 0, len = e.target.options.length; i < len; i++) {
      opt = e.target.options[i];
      if (opt.selected) {
        opts.push(opt.value);
      }
    }
    if (e.target.name == "offered_campuses[]")
      setData({ ...data, offered_campuses: opts });
    else setData({ ...data, study_modes: opts });
  };

  function handleChangeImage(e) {
    setData({ ...data, image: e.target.files[0] });
  }
  function handleChangeVideo(e) {
    setData({ ...data, cover_video: e.target.files[0] });
  }

  const [plos, setPLOs] = useState([
    {
      id: uuidv4(),
      content: "",
    },
  ]);

  let addPLO = () => {
    setPLOs([
      ...plos,
      {
        id: uuidv4(),
        content: "",
      },
    ]);
  };

  let removePLO = (id) => {
    const filteredPLOs = plos.filter((plo) => plo.id !== id);
    setPLOs(filteredPLOs);
  };

  const handlePLOChange = (e, id) => {
    const updatedPLOs = plos.map((plo) => {
      if (plo.id !== id) return plo;
      plo.content = e.target.value;
      return plo;
    });

    setPLOs(updatedPLOs);
  };

  const [careerOutcomes, setCareerOutcomes] = useState([
    {
      id: uuidv4(),
      content: "",
    },
  ]);

  let addCareerOutcome = () => {
    setCareerOutcomes([
      ...careerOutcomes,
      {
        id: uuidv4(),
        content: "",
      },
    ]);
  };

  let removeCareerOutcome = (id) => {
    const filteredCareerOutcomes = careerOutcomes.filter(
      (careerOutcome) => careerOutcome.id !== id
    );
    setCareerOutcomes(filteredCareerOutcomes);
  };

  const handleCareerOutcomeChange = (e, id) => {
    const updatedCareerOutcomes = careerOutcomes.map((careerOutcome) => {
      if (careerOutcome.id !== id) return careerOutcome;
      careerOutcome.content = e.target.value;
      return careerOutcome;
    });

    setCareerOutcomes(updatedCareerOutcomes);
  };

  return (
    <div>
      <Button
        color="primary"
        className="btn btn-sm btn-primary d-block w-100 mb-2"
        onClick={toggle}>
        Edit
      </Button>

      <Modal
        modalClassName="custommodal program-modal-box"
        centered
        scrollable
        size="xl"
        toggle={toggle}
        isOpen={modal}
        fade={true}>
        <ModalHeader toggle={toggle}>Update Program</ModalHeader>
        <Form id="edit-form" method="POST" action="#" onSubmit={handleSubmit}>
          <ModalBody className="ps-0">
            <div className="program-modal">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab == "1" ? "active" : ""}
                    onClick={() => setActiveTab("1")}>
                    General
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab == "2" ? "active" : ""}
                    onClick={() => setActiveTab("2")}>
                    Program Learning Outcomes
                  </NavLink>
                  <NavLink
                    className={activeTab == "3" ? "active" : ""}
                    onClick={() => setActiveTab("3")}>
                    Career Outcomes
                  </NavLink>
                  <NavLink
                    className={activeTab == "4" ? "active" : ""}
                    onClick={() => setActiveTab("4")}>
                    Eligiblity Criteria
                  </NavLink>
                  <NavLink
                    className={activeTab == "5" ? "active" : ""}
                    onClick={() => setActiveTab("5")}>
                    SEO Configuration
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div>
                    <h3 className="mb-3">
                      New Program (ADP in Culinary Sciences)
                    </h3>
                    <FormGroup>
                      <Label for="name" className="label-required">
                        Name
                      </Label>
                      <Input
                        className="rounded-2"
                        id="name"
                        type="name"
                        name="name"
                        maxLength={255}
                        value={data.name}
                        onChange={handleChange}
                        autoFocus
                        required
                      />
                      {errors.name && (
                        <Label className="text-danger mt-2" for="name">
                          {errors.name}
                        </Label>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="study_level_id">Study Levels</Label>
                      <Input
                        className="rounded-2"
                        id="study_level_id"
                        type="select"
                        name="study_level_id"
                        value={data.study_level_id}
                        required
                        onChange={handleChange}>
                        <option value="">Select Study Level</option>
                        {studyLevels.map((studyLevel) => (
                          <option key={studyLevel.id} value={studyLevel.id}>
                            {studyLevel.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="offered_campuses" className="label-required">
                        Campuses
                      </Label>
                      <select
                        style={{
                          width: "100%",
                          height: "200px",
                          overflow: "scroll",
                        }}
                        id="multiselect"
                        multiple="multiple"
                        className="rounded-2"
                        type="select"
                        name="offered_campuses[]"
                        maxLength={255}
                        value={data.offered_campuses}
                        required
                        // value={data.excerpt}
                        onChange={handleChangeMultiple}>
                        <option value="">Select Campuses</option>
                        {campuses.map((campus) => (
                          <option key={campus.id} value={campus.id}>
                            {campus.name}
                          </option>
                        ))}
                      </select>
                    </FormGroup>

                    <FormGroup>
                      <Label for="faculty_id">Faculty</Label>
                      <Input
                        className="rounded-2"
                        id="faculty_id"
                        type="select"
                        name="faculty_id"
                        value={data.faculty_id}
                        required
                        onChange={handleChange}>
                        <option value="">Select Faculty</option>
                        {faculties.map((faculty) => (
                          <option key={faculty.id} value={faculty.id}>
                            {faculty.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>

                    <FormGroup>
                      <Label for="study_modes" className="label-required">
                        Study Modes
                      </Label>
                      <select
                        style={{
                          width: "100%",
                          height: "200px",
                          overflow: "scroll",
                        }}
                        id="multiselect"
                        multiple="multiple"
                        className="rounded-2"
                        type="select"
                        name="study_modes[]"
                        value={data.study_modes}
                        maxLength={255}
                        required
                        // value={data.excerpt}
                        onChange={handleChangeMultiple}>
                        <option value="">Select Study Modes</option>
                        <option>Morning</option>
                        <option>Evening</option>
                        <option>Weekend</option>
                      </select>
                    </FormGroup>

                    <FormGroup>
                      <Label for="about" className="label-required">
                        About
                      </Label>
                      <Input
                        className="rounded-2"
                        id="about"
                        type="textarea"
                        name="about"
                        required
                        maxLength={255}
                        rows={6}
                        value={data.about}
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="credit_hours" className="label-required">
                        Credit Hours
                      </Label>
                      <Input
                        className="rounded-2"
                        id="credit_hours"
                        type="number"
                        name="credit_hours"
                        maxLength={255}
                        value={data.credit_hours}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="semesters" className="label-required">
                        Semesters
                      </Label>
                      <Input
                        className="rounded-2"
                        id="semesters"
                        type="number"
                        name="semesters"
                        maxLength={255}
                        value={data.semesters}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    {data.image ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}` + data.image}
                        alt="image"
                        className="studylevel-img mb-3 mt-3"
                      />
                    ) : (
                      ""
                    )}
                    <FormGroup>
                      <Label for="image" className="label-required">
                        Image
                      </Label>
                      <Input
                        className="rounded-2"
                        id="image"
                        type="file"
                        name="image"
                        maxLength={255}
                        onChange={handleChangeImage}
                      />
                      {errors.image && (
                        <Label className="text-danger mt-2" for="name">
                          {errors.image}
                        </Label>
                      )}
                    </FormGroup>
                    {data.video ? (
                      <video
                        className="mb-3 mt-3"
                        width="320"
                        height="240"
                        controls>
                        <source
                          src={`${process.env.REACT_APP_API_URL}` + data.video}
                        />
                      </video>
                    ) : (
                      ""
                    )}
                    <FormGroup>
                      <Label for="video" className="label-required">
                        Video
                      </Label>
                      <Input
                        className="rounded-2"
                        id="video"
                        type="file"
                        name="video"
                        maxLength={255}
                        onChange={handleChangeVideo}
                      />
                      {errors.video && (
                        <Label className="text-danger mt-2" for="name">
                          {errors.video}
                        </Label>
                      )}
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-end mt-4 mb-5 arror-right">
                    <NavLink
                      className={activeTab == "2" ? "active" : ""}
                      onClick={() => setActiveTab("2")}>
                      <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
                    </NavLink>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div>
                    <h3 className="mb-3">
                      New Program (ADP in Culinary Sciences)
                    </h3>
                    <FormGroup>
                      <Label className="label-required">
                        Add Program Learning Objectives
                      </Label>

                      {plos.map((plo, index) => (
                        <div
                          className="form-inline position-relative"
                          key={`plo-${index}`}>
                          <Input
                            type="text"
                            value={plo.content}
                            onChange={(e) => handlePLOChange(e, plo.id)}
                            className="rounded-2 mb-3"
                            autoFocus
                            required
                          />

                          {index ? (
                            <button
                              type="button"
                              className="button remove"
                              onClick={() => removePLO(plo.id)}>
                              <FontAwesomeIcon
                                icon={faMultiply}
                                className=""></FontAwesomeIcon>
                            </button>
                          ) : null}
                        </div>
                      ))}
                      <div className="button-section text-end">
                        <button
                          className="button add rounded-2 btn btn-primary"
                          type="button"
                          onClick={() => addPLO()}>
                          Add Another
                        </button>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-end mt-4 mb-5 arror-right">
                    <div className="me-2">
                      <NavLink
                        className={activeTab == "1" ? "active" : ""}
                        onClick={() => setActiveTab("1")}>
                        <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
                      </NavLink>
                    </div>
                    <NavLink
                      className={activeTab == "3" ? "active" : ""}
                      onClick={() => setActiveTab("3")}>
                      <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
                    </NavLink>
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div>
                    <h3 className="mb-3">
                      New Program (ADP in Culinary Sciences)
                    </h3>
                    <FormGroup>
                      <Label for="career_outcomes" className="label-required">
                        Add Career Outcomes
                      </Label>

                      {careerOutcomes.map((careerOutcome, index) => (
                        <div
                          className="form-inline position-relative"
                          key={`careerOutcome-${index}`}>
                          <Input
                            type="text"
                            value={careerOutcome.content}
                            required
                            onChange={(e) =>
                              handleCareerOutcomeChange(e, careerOutcome.id)
                            }
                            className="rounded-2 mb-3"
                            autoFocus
                          />

                          {index ? (
                            <button
                              type="button"
                              className="button remove"
                              onClick={() =>
                                removeCareerOutcome(careerOutcome.id)
                              }>
                              <FontAwesomeIcon
                                icon={faMultiply}
                                className=""></FontAwesomeIcon>
                            </button>
                          ) : null}
                        </div>
                      ))}
                      <div className="button-section text-end">
                        <button
                          className="button add rounded-2 btn btn-primary"
                          type="button"
                          onClick={() => addCareerOutcome()}>
                          Add Another
                        </button>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-end mt-4 mb-5 arror-right">
                    <div className="me-2">
                      <NavLink
                        className={activeTab == "2" ? "active" : ""}
                        onClick={() => setActiveTab("2")}>
                        <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
                      </NavLink>
                    </div>
                    <NavLink
                      className={activeTab == "4" ? "active" : ""}
                      onClick={() => setActiveTab("4")}>
                      <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
                    </NavLink>
                  </div>
                </TabPane>
                <TabPane tabId="4">
                  <div>
                    <h3 className="mb-3">
                      New Program (ADP in Culinary Sciences)
                    </h3>
                    <FormGroup>
                      <Label
                        for="eligibility_criteria"
                        className="label-required">
                        Eligibility Criteria
                      </Label>
                      <Input
                        className="rounded-2"
                        id="eligibility_criteria"
                        type="text"
                        name="eligibility_criteria"
                        maxLength={255}
                        value={data.eligibility_criteria}
                        onChange={handleChange}
                        autoFocus
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label
                        for="eligibility_criteria_note"
                        className="label-required">
                        Eligibility Criteria Note
                      </Label>
                      <Input
                        className="rounded-2"
                        id="eligibility_criteria_note"
                        type="text"
                        name="eligibility_criteria_note"
                        maxLength={255}
                        value={data.eligibility_criteria_note}
                        onChange={handleChange}
                        autoFocus
                        required
                      />
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-end mt-4 mb-5 arror-right">
                    <div className="me-2">
                      <NavLink
                        className={activeTab == "3" ? "active" : ""}
                        onClick={() => setActiveTab("3")}>
                        <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
                      </NavLink>
                    </div>
                    <NavLink
                      className={activeTab == "5" ? "active" : ""}
                      onClick={() => setActiveTab("5")}>
                      <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
                    </NavLink>
                  </div>
                </TabPane>
                <TabPane tabId="5">
                  <div>
                    <h3 className="mb-3">
                      New Program (ADP in Culinary Sciences)
                    </h3>

                    <FormGroup>
                      <Label for="meta_title" className="label-required">
                        Meta Title
                      </Label>
                      <Input
                        className="rounded-2"
                        id="meta_title"
                        type="text"
                        name="meta_title"
                        maxLength={255}
                        value={data.meta_title}
                        onChange={handleChange}
                        autoFocus
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="meta_keywords" className="label-required">
                        Meta Keyword
                      </Label>
                      <Input
                        className="rounded-2"
                        id="meta_keywords"
                        type="text"
                        name="meta_keywords"
                        maxLength={255}
                        value={data.meta_keywords}
                        onChange={handleChange}
                        autoFocus
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="meta_description" className="label-required">
                        Meta Description
                      </Label>
                      <Input
                        className="rounded-2"
                        id="meta_description"
                        type="textarea"
                        rows={6}
                        name="meta_description"
                        maxLength={255}
                        value={data.meta_description}
                        onChange={handleChange}
                        autoFocus
                      />
                    </FormGroup>

                    <ModalFooter>
                      <div className="d-flex justify-content-start me-auto arror-right me-2">
                        <NavLink
                          className={activeTab == "4" ? "active" : ""}
                          onClick={() => setActiveTab("4")}>
                          <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
                        </NavLink>
                      </div>
                      <Button
                        type="button"
                        onClick={toggle}
                        color="light"
                        className="rounded-2">
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        className="rounded-2"
                        disabled={loading}>
                        {loading && (
                          <Spinner className="me-2" size="sm">
                            Loading...
                          </Spinner>
                        )}
                        Update
                      </Button>{" "}
                    </ModalFooter>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </ModalBody>
        </Form>
      </Modal>
    </div>
  );
};

export default Edit;
