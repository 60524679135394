import "../../../css/modal.css";
import moment from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import API from "../../../utils/API";
import axios from "axios";

const Edit = ({ timeSlot, fetchTimeSlots }) => {
  // Modal open state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const [data, setData] = useState({
    start: "",
    end: "",
    is_active: 0,
  });

  useEffect(() => {
    setData(timeSlot);
  }, []);

  // Toggle for Modal
  const toggle = () => setModal(!modal);
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);

      const response = await API.put(`api/time-slots/${data.id}`, data);
      setLoading(false);
      fetchTimeSlots();
      toggle();
      setErrors([]);
    } catch (error) {
      setErrors(
        error?.response?.data?.errors ? error.response.data.errors : []
      );
      setLoading(false);
    }
  }

  const handleChangeCheckbox = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.checked ? 1 : 0 });
  };
  const handleChange = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Button
        color="primary"
        className="btn btn-sm btn-primary d-block w-100 mb-2"
        onClick={toggle}>
        Edit
      </Button>

      <Modal
        modalClassName="custommodal"
        centered
        scrollable
        size="xl"
        toggle={toggle}
        isOpen={modal}
        fade={true}>
        <ModalHeader toggle={toggle}>Update Timeslot</ModalHeader>
        <Form method="POST" action="#" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for="start" className="label-required">
                Start
              </Label>
              <Input
                className="rounded-2 without_ampm"
                id="start"
                type="time"
                name="start"
                maxLength={255}
                value={moment(data.start, "HH:mm").format("hh:mm")}
                onChange={handleChange}
                required
                autoFocus
              />
              {errors.start && (
                <Label className="text-danger mt-2" for="name">
                  {errors.start}
                </Label>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="end" className="label-required">
                End
              </Label>
              <Input
                className="rounded-2 without_ampm"
                id="end"
                type="time"
                name="end"
                maxLength={255}
                value={moment(data.end, "HH:mm").format("hh:mm")}
                onChange={handleChange}
                required
                autoFocus
              />
              {errors.end && (
                <Label className="text-danger mt-2" for="name">
                  {errors.end}
                </Label>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="is_active" className="label-required">
                Active
              </Label>
              <Input
                className="rounded-2"
                id="is_active"
                type="checkbox"
                name="is_active"
                maxLength={255}
                value={data.is_active}
                checked={data.is_active}
                onChange={handleChangeCheckbox}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggle}
              color="light"
              className="rounded-2">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="rounded-2"
              disabled={loading}>
              {loading && (
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              )}
              Update
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Edit;
