import React, { useEffect, useState } from "react";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { Card, CardBody, Container } from "reactstrap";
import FullscreenLoader from "../../../components/FullscreenLoader";
import { useParams } from "react-router-dom";

export const EmployesProfile = () => {
  const [profile, setProfile] = useState(null);
  const {employeeId} = useParams();
  const [loading , setLoading] = useState(false);


  const fetchEmployeeProfile = async () => {
    try {
      const response = (await API.get(`/api/employee/${employeeId}`)).data.data;
      setProfile(response);
      setLoading(true);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployeeProfile();
  }, []);
  
  return (
    <AppLayout>
      <Breadcrumbs
        title="Profile"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Profile",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          {!profile ? (
            <FullscreenLoader />
          ) : (
            <CardBody>
              <div className="d-flex">
                <img
                  src="https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png"
                  alt=""
                  className="me-2 mb-2"
                  style={{ width: "200px", height: "100%" }}
                />
                <div
                  style={{ flex: "1" }}
                  className="d-flex flex-column justify-content-between"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="display-6 fw-bold text-light-blue">
                      EMP-CODE: {profile?.employee_id}
                    </div>
                  </div>
                  <div className="d-flex flex-wrap">
                    <div
                      style={{ flex: "1", minWidth: "fit-content" }}
                      className="bg-shade-blue p-3 me-2 mb-2"
                    >
                      <span className="fw-bold me-2">Name:</span>
                      <span>
                        {profile?.name} S/O {profile?.father_name}
                      </span>
                    </div>
                    <div
                      style={{ flex: "1", minWidth: "fit-content" }}
                      className="bg-shade-blue p-3 me-2 mb-2"
                    >
                      <span className="fw-bold me-2">Email:</span>
                      <span>{profile?.email}</span>
                    </div>
                    <div
                      className="d-flex"
                      style={{ flex: "1", minWidth: "fit-content" }}
                    >
                      <div
                        style={{ flex: "1", minWidth: "fit-content" }}
                        className="bg-shade-blue p-3 me-2 mb-2"
                      >
                        <span className="fw-bold me-2">Marital Status:</span>
                        <span>{profile?.marital_status}</span>
                      </div>
                      <div
                        style={{ flex: "1", minWidth: "fit-content" }}
                        className="bg-shade-blue p-3 me-2 mb-2"
                      >
                        <span className="fw-bold me-2">Gender:</span>
                        <span>{profile?.gender}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex"
                style={{ flex: "1", minWidth: "fit-content" }}
              >
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">CNIC No.:</span>
                  <span>{profile.cnic ? profile.cnic : "N/A"}</span>
                </div>
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">Joining Date:</span>
                  <span>
                    {profile.joining_date ? profile?.joining_date : "N/A"}
                  </span>
                </div>
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">Salary:</span>
                  <span>{profile.salary ? profile.salary : "N/A"}</span>
                </div>
              </div>
              <div
                className="d-flex"
                style={{ flex: "1", minWidth: "fit-content" }}
              >
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">Current Address:</span>
                  <span>{profile?.current_address}</span>
                </div>
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">Permanent Address:</span>
                  <span>{profile?.permanent_address}</span>
                </div>
              </div>
              <div className="d-flex flex-wrap align-items-end">
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">Date of Birth: </span>
                  <span>{profile?.date_of_birth}</span>
                </div>
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">Cell Number:</span>
                  <span>{profile?.cell_number}</span>
                </div>
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">Phone Number:</span>
                  <span>{profile?.phone_number}</span>
                </div>
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">Department:</span>
                  <span>{profile?.relationships?.department.name}</span>
                </div>
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">Campus:</span>
                  <span>{profile?.relationships?.campus.name}</span>
                </div>
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">Designation:</span>
                  <span>{profile?.relationships?.designation.name}</span>
                </div>
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">Shift Start Time:</span>
                  <span>{profile?.shift_start_time}</span>
                </div>
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">Shift End Time:</span>
                  <span>{profile?.shift_end_time}</span>
                </div>
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">Working Hours:</span>
                  <span>{profile?.working_hours}</span>
                </div>
              </div>
            </CardBody>
          )}
        </Card>
      </Container>
    </AppLayout>
  );
};