import React from "react";
import { Spinner } from "reactstrap";

const FullscreenLoader = () => {
  return (
    <div className="vh-100 align-items-center d-flex justify-content-center">
      <Spinner style={{ width: "75px", height: "75px" }} />
    </div>
  );
};

export default FullscreenLoader;
