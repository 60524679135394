import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/modal.css";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import API from "../../../utils/API";
import axios from "axios";

const Edit = ({ campuses, fetchCampuses }) => {
  // Modal open state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [image, setImage] = useState();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);

  const [data, setData] = useState({
    name: "",
    type: "",
    email: "",
    address: "",
    contact_number: "",
    fax: "",
    city_id: "",
    total_area: "",
    starting_date: "",
    registration_number: "",
    total_grounds: "",
    google_map_embed: "",
    google_map_link: "",
    has_parking: 0,
    has_cafe: 0,
    has_library: 0,
    has_mosque: 0,
    has_parking: 0,
    is_active: 0,
  });

  useEffect(() => {
    campuses.city_id = campuses?.relationships?.city_id;
    setData(campuses);
    setSelectedCountry(campuses.relationships?.country_id);
    fetchCountries();

    if (campuses.relationships?.country_id) {
      setSelectedState(campuses.relationships?.state_id);
      fetchStates(campuses.relationships?.country_id);
      setSelectedCity(campuses.relationships?.city_id);
      fetchCities(campuses.relationships?.state_id);
    }
  }, []);

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData(document.getElementById("edit-form"));
      formData.append("has_mosque", data.has_mosque);
      formData.append("has_parking", data.has_parking);
      formData.append("has_cafe", data.has_cafe);
      formData.append("has_library", data.has_library);
      formData.append("is_active", data.is_active);
      formData.append("city_id", selectedCity);
      formData.append("state_id", selectedState);
      formData.append("country_id", selectedCountry);

      formData.set(
        "image",
        formData.get("image") ? formData.get("image") : data.image
      );

      formData.set(
        "video",
        formData.get("video") ? formData.get("video") : data.video
      );

      formData.append("_method", "PATCH");

      const response = await API.post(`/api/campuses/${data.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setLoading(false);
      fetchCampuses();
      toggle();
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChangeCheckbox = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.checked ? 1 : 0 });
  };

  const fetchCountries = async () => {
    try {
      setLoading(true);
      const response = (await API.get("/api/country")).data;
      setCountries(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      setLoading(true);
      const response = (await API.get(`/api/state/${countryId}`)).data;
      setStates(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchCities = async (stateId) => {
    try {
      setLoading(true);
      const response = (await API.get(`/api/city/${stateId}`)).data;
      setCities(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleChangeSelect = (e) => {
    setSelectedCountry(e.target.value);
    fetchStates(e.target.value);
  };
  const handleChangeState = (e) => {
    setSelectedState(e.target.value);
    fetchCities(e.target.value);
  };
  const handleChangeCity = (e) => {
    setSelectedCity(e.target.value);
  };
  const handleChange = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.value });
  };
  function handleChangeImage(e) {
    setData({ ...data, image: e.target.files[0] });
  }
  function handleChangeVideo(e) {
    setData({ ...data, video: e.target.files[0] });
  }

  return (
    <div>
      <Button
        color="primary"
        className="btn btn-sm btn-primary d-block w-100 mb-2"
        onClick={toggle}
      >
        Edit
      </Button>

      <Modal
        modalClassName="custommodal"
        centered
        scrollable
        size="xl"
        toggle={toggle}
        isOpen={modal}
        fade={true}
      >
        <ModalHeader toggle={toggle}>Updated Campus</ModalHeader>
        <Form id="edit-form" method="POST" action="#" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label htmlFor="name" className="label-required">
                Name
              </Label>
              <Input
                className="rounded-2"
                id="name"
                type="name"
                name="name"
                maxLength={255}
                value={data.name}
                onChange={handleChange}
                required
                autoFocus
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="type" className="label-required">
                Type
              </Label>
              <Input
                className="rounded-2"
                id="type"
                type="select"
                name="type"
                maxLength={255}
                value={data.type}
                onChange={handleChange}
                required
              >
                <option value="">Select an campus type</option>
                <option>Online</option>
                <option>Physical</option>
              </Input>
            </FormGroup>
            {data.type == "Physical" && (
              <FormGroup>
                <Label htmlFor="google_map_embed" className="label-required">
                  Google Map Embed
                </Label>
                <Input
                  className="rounded-2"
                  id="google_map_embed"
                  type="text"
                  name="google_map_embed"
                  maxLength={255}
                  value={data.google_map_embed}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            )}
            {data.type == "Physical" && (
              <FormGroup>
                <Label htmlFor="google_map_link" className="label-required">
                  Google Map Link
                </Label>
                <Input
                  className="rounded-2"
                  id="google_map_link"
                  type="text"
                  name="google_map_link"
                  maxLength={255}
                  value={data.google_map_link}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            )}

            <FormGroup>
              <Label htmlFor="email" className="label-required">
                Email
              </Label>
              <Input
                className="rounded-2"
                id="email"
                type="email"
                name="email"
                maxLength={255}
                value={data.email}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="address" className="label-required">
                Address
              </Label>
              <Input
                className="rounded-2"
                id="address"
                type="text"
                name="address"
                maxLength={255}
                value={data.address}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="contact_number" className="label-required">
                Contact Number
              </Label>
              <Input
                className="rounded-2"
                id="contact_number"
                type="numeric"
                name="contact_number"
                maxLength={255}
                value={data.contact_number}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="fax" className="label-required">
                Fax
              </Label>
              <Input
                className="rounded-2"
                id="fax"
                type="numeric"
                name="fax"
                maxLength={255}
                value={data.fax}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="country">Country</Label>
              <Input
                className="rounded-2"
                id="country"
                required
                type="select"
                name="country"
                value={selectedCountry}
                onChange={handleChangeSelect}
              >
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="state">State</Label>
              <Input
                className="rounded-2"
                id="state"
                required
                type="select"
                name="state"
                value={selectedState}
                onChange={handleChangeState}
              >
                <option value="">Select state</option>
                {states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="state">City</Label>
              <Input
                className="rounded-2"
                id="city"
                required
                type="select"
                name="city"
                value={selectedCity}
                onChange={handleChangeCity}
              >
                <option value="">Select city</option>
                {cities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="image">Image</Label>
              <Input
                className="rounded-2"
                id="image"
                type="file"
                name="image"
                onChange={handleChangeImage}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="video">Video</Label>
              <Input
                className="rounded-2"
                id="video"
                type="file"
                name="video"
                onChange={handleChangeVideo}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="total_area" className="label-required">
                Total Area
              </Label>
              <Input
                className="rounded-2"
                id="total_area"
                type="text"
                name="total_area"
                maxLength={255}
                value={data.total_area}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="starting_date" className="label-required">
                Starting Date
              </Label>
              <Input
                className="rounded-2"
                id="starting_date"
                type="date"
                name="starting_date"
                maxLength={255}
                defaultValue={data.starting_date.split(" ")[0]}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="registration_number" className="label-required">
                Registration Number
              </Label>
              <Input
                className="rounded-2"
                id="registration_number"
                type="text"
                name="registration_number"
                maxLength={255}
                value={data.registration_number}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="total_grounds" className="label-required">
                Total Grounds
              </Label>
              <Input
                className="rounded-2"
                id="total_grounds"
                type="text"
                name="total_grounds"
                maxLength={255}
                value={data.total_grounds}
                onChange={handleChange}
              />
            </FormGroup>

            <div className="row">
              <div className="col-md-4">
                <FormGroup>
                  <Label htmlFor="has_cafe" className="label-required">
                    Has Cafe
                  </Label>
                  <Input
                    className="rounded-2"
                    id="has_cafe"
                    type="checkbox"
                    name="has_cafe"
                    maxLength={255}
                    value={data.has_cafe}
                    checked={data.has_cafe}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label htmlFor="has_library" className="label-required">
                    Has Library
                  </Label>
                  <Input
                    className="rounded-2"
                    id="has_library"
                    type="checkbox"
                    name="has_library"
                    maxLength={255}
                    value={data.has_library}
                    checked={data.has_library}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label htmlFor="has_mosque" className="label-required">
                    Has Mosque
                  </Label>
                  <Input
                    className="rounded-2"
                    id="has_mosque"
                    type="checkbox"
                    name="has_mosque"
                    maxLength={255}
                    value={data.has_mosque}
                    checked={data.has_mosque}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <FormGroup>
                  <Label htmlFor="has_parking" className="label-required">
                    Has Parking
                  </Label>
                  <Input
                    className="rounded-2"
                    id="has_parking"
                    type="checkbox"
                    name="has_parking"
                    maxLength={255}
                    value={data.has_parking}
                    checked={data.has_parking}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label htmlFor="is_active" className="label-required">
                    Is Active
                  </Label>
                  <Input
                    className="rounded-2"
                    id="is_active"
                    type="checkbox"
                    name="is_active"
                    maxLength={255}
                    value={data.is_active}
                    checked={data.is_active}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggle}
              color="light"
              className="rounded-2"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="rounded-2"
              disabled={loading}
            >
              {loading && (
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              )}
              Update
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Edit;
