import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
  Table,
} from "reactstrap";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Container from "../../../components/Container";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const Index = () => {
  const [vouchers, setVouchers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState();
  const [totalFee, setTotalFee] = useState(0);
  const [formData, setFormData] = useState({
    start_date: "",
    end_date: "",
    paid_at: "",
    paid_start: "",
    paid_end: "",
  });

  useEffect(() => {
    fetchVouchers();
  }, []);

  const fetchVouchers = async (e) => {
    try {
      setLoading(true);
      const data = (await API.get("/api/report/voucher/raiwind")).data;
      setVouchers(data.vouchers);
      setTotalFee(data.total_collection);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const resetVouchers = (e) => {
    e.preventDefault();
    setFormData({
      start_date: "",
      end_date: "",
      paid_at: "",
      paid_start: "",
      paid_end: "",
    });
    setTotalFee(0);
    fetchVouchers();
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      setTotalFee(0);
      const response = await API.post(
        "/api/report/voucher/raiwind/filters",
        formData
      );
      if (response) {
        setLoading(false);
        setVouchers(response.data.vouchers);
        setTotalFee(response.data.total_collection);
      }
    } catch {
      setLoading(false);
    }
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const checkHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked ? "on" : "" });
  };

  const fileType = "xlsx";
  const exportData = () => {
    const fileData = vouchers.map((voucher) => {
      const particularData = Object.keys(voucher.particulars.payables).map(
        (payable, key) => payable + ": " + voucher.particulars.payables[payable]
      );
      return {
        "Voucher No.": voucher?.id,
        "Applicant Name": voucher?.relationships?.chargeable?.name,
        "Applicant ID": "A-" + voucher?.chargeable_id,
        "Roll Number":
          voucher?.relationships?.chargeable?.relationships?.student
            ?.legacy_roll_no,
        Campus: voucher?.relationships?.chargeable?.relationships?.campus?.name,
        "Fee Type": voucher?.fee_type,
        Particulars: particularData.join(", "),
        Total: voucher?.total,
        "Payment Method": voucher.account_details
          ? voucher.account_details?.name
          : "",
        "Received By": voucher.received_by ? voucher.received_by?.name : "",
        "Created at": moment(voucher?.created_at).format("DD/MM/YY"),
        "Paid at": voucher.paid_at
          ? moment(voucher?.created_at).format("DD/MM/YY")
          : "",
      };
    });
    const ws = XLSX.utils.json_to_sheet(fileData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const ExcelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([ExcelBuffer], { type: fileType });
    FileSaver.saveAs(data, "RaiwindVoucherReport" + ".xlsx");
  };

  return (
    <AppLayout>
      <Breadcrumbs
        title="Raiwind Vouchers"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Raiwind Vouchers",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3 d-flex align-items-center justify-content-between border-bottom pb-4">
            <h1 className="mb-0">Raiwind Voucher Report</h1>
          </div>
          {errors && (
            <p
              className="text-danger mt-2 text-right"
              style={{
                textAlign: "right",
                position: "absolute",
                right: "238px",
                top: "52px",
              }}
              for="name"
            >
              {errors}
            </p>
          )}
          <Card className="border-0 border-bottom">
            <CardBody>
              <div className="d-flex flex-wrap justify-content-between">
                <Form method="POST" action="#" onSubmit={handleSubmit}>
                  <FormGroup className="d-flex align-items-center">
                    <Label style={{ width: "200px" }} for="exampleEmail">
                      Start Date
                    </Label>
                    <Input
                      onChange={handleChange}
                      type="date"
                      name="start_date"
                      className="me-3"
                      value={formData.start_date}
                    />

                    <Label style={{ width: "200px" }} for="exampleEmail">
                      End Date
                    </Label>
                    <Input
                      onChange={handleChange}
                      name="end_date"
                      value={formData.end_date}
                      type="date"
                    />
                  </FormGroup>
                  <FormGroup className="d-flex align-items-center">
                    <Label style={{ width: "200px" }} for="exampleEmail">
                      Paid Start Date
                    </Label>
                    <Input
                      onChange={handleChange}
                      type="date"
                      name="paid_start"
                      className="me-3"
                      value={formData.paid_start}
                    />

                    <Label style={{ width: "200px" }} for="exampleEmail">
                      Paid End Date
                    </Label>
                    <Input
                      onChange={handleChange}
                      name="paid_end"
                      value={formData.paid_end}
                      type="date"
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Input
                      variant="primary"
                      className="me-2"
                      type="checkbox"
                      name="paid_at"
                      checked={formData.paid_at === "on"}
                      onChange={checkHandler}
                    />{" "}
                    Paid
                  </FormGroup>

                  <div>
                    <button type="submit" className="btn btn-primary me-3">
                      Submit
                    </button>
                    <button onClick={resetVouchers} className="btn btn-primary">
                      Reset
                    </button>
                  </div>
                </Form>
                <div>
                  <div
                    className="btn btn-success rounded-0"
                    onClick={exportData}
                  >
                    <FontAwesomeIcon icon={faPrint} className="me-2" /> Export
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <CardBody>
            <Table hover responsive>
              <thead>
                <tr className="bg-shade-blue">
                  <th>Voucher No</th>
                  <th>Applicant Name</th>
                  <th>Applicant ID</th>
                  <th>Roll Number</th>
                  <th>Program</th>
                  <th>Fee Type</th>
                  <th>Particulars</th>
                  <th>Total</th>
                  <th>Payment Method</th>
                  <th>Received By</th>
                  <th>Created at</th>
                  <th>Paid at</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr className="text-center">
                    <td colSpan={10}>
                      <Spinner style={{ width: "30px", height: "30px" }} />
                    </td>
                  </tr>
                ) : vouchers.length > 0 ? (
                  vouchers.map((voucher) => (
                    <>
                      <tr key={`voucher-${voucher.id}`}>
                        <th scope="row">{voucher.id}</th>
                        <td>{voucher?.relationships?.chargeable?.name}</td>
                        <td>A-{voucher?.chargeable_id}</td>
                        <td>
                          {
                            voucher?.relationships?.chargeable?.relationships
                              ?.student?.legacy_roll_no
                          }
                        </td>
                        <td>
                          {
                            voucher?.relationships?.chargeable?.relationships
                              ?.campus?.name
                          }
                        </td>
                        <td>{voucher?.fee_type}</td>
                        <th className="fw-normal">
                          {voucher?.particulars ? (
                            <>
                              {Object.keys(voucher.particulars.payables).map(
                                (payable, key) => (
                                  <div key={key}>
                                    {payable}:{" "}
                                    {voucher.particulars.payables[payable]}
                                  </div>
                                )
                              )}
                            </>
                          ) : (
                            <div> N/A </div>
                          )}
                        </th>
                        <td>{voucher?.total}</td>
                        <td>{voucher?.account_details?.name}</td>
                        <td>{voucher?.received_by?.name}</td>
                        <td>
                          {moment(voucher?.created_at).format("DD/MM/YY")}
                        </td>
                        <td>
                          {voucher.paid_at
                            ? moment(voucher?.paid_at).format("DD/MM/YY")
                            : ""}
                        </td>
                      </tr>
                    </>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {loading ? (
                        <Spinner style={{ width: "30px", height: "30px" }} />
                      ) : (
                        "No vouchers Exists"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
              {vouchers.length > 0 ? (
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <b> Total </b> = {totalFee}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              ) : (
                <></>
              )}
            </Table>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};

export default Index;
