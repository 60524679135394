import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Container from "../../../components/Container";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Enrollment from "./Enrollment";
import BulkEnrollment from "./BulkEnrollment";

const Timetable = () => {
  const [timeTables, setTimetables] = useState([]);
  const [selectedTimetable, setSelectedTimetable] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [campuses, setCampuses] = useState([]);
  const [selectedCampus, setSelectedCampus] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [activeTab, setActiveTab] = useState("1");

  const [loading, setLoading] = useState(false);

  const fetchDropdownData = async () => {
    const [campuses, sessions] = await Promise.all([
      API.get("/api/campuses"),
      API.get("/api/sessions"),
    ]);
    setCampuses(campuses.data.data);
    setSessions(sessions.data.data);
  };

  const fetchTimetable = async () => {
    try {
      setLoading(true);
      const data = (
        await API.get(
          `/api/timetable?campus_id=${selectedCampus}&session_id=${selectedSession}`
        )
      ).data.data;
      setTimetables(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const enrollStudent = (timeTable) => {
    setSelectedTimetable(timeTable);
    setShowModal(true);
  };
  const bulkEnrollStudent = (timeTable) => {
    setSelectedTimetable(timeTable);
    setShowBulkModal(true);
  };

  const handleChange = (e) => {
    if (e.target.name == "campus_id") {
      setSelectedCampus(e.target.value);
    }
    if (e.target.name == "session_id") {
      setSelectedSession(e.target.value);
    }
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);

  useEffect(() => {
    if (selectedSession && selectedCampus) {
      fetchTimetable();
    }
  }, [selectedCampus, selectedSession, refresh]);

  return (
    <>
      <AppLayout>
        <Breadcrumbs
          title="Enrollments"
          links={[
            {
              title: "Dashboard",
              link: null,
            },
            {
              title: "Enrollments",
              link: null,
            },
          ]}
        />
        <Container>
          <div className="enrollment-tabs">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={activeTab == "1" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("1");
                    setTimetables([]);
                    setSelectedCampus("null");
                    setSelectedSession("null");
                  }}>
                  Enrollments
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab == "2" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("2");
                    setTimetables([]);
                    setSelectedCampus("null");
                    setSelectedSession("null");
                  }}>
                  Bulk Enrollments
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="mt-4">
                  <div className="row">
                    <div className="col-md-4">
                      <FormGroup>
                        <Label for="campus_id">Campuses</Label>
                        <Input
                          className="rounded-2"
                          id="campus_id"
                          required
                          type="select"
                          value={selectedCampus}
                          name="campus_id"
                          onChange={handleChange}>
                          <option value="">Select Campus</option>
                          {campuses.map((campus) => (
                            <option key={campus.id} value={campus.id}>
                              {campus.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup>
                        <Label for="session_id">Sessions</Label>
                        <Input
                          className="rounded-2"
                          id="session_id"
                          required
                          type="select"
                          name="session_id"
                          value={selectedSession}
                          onChange={handleChange}>
                          <option value="">Select Session</option>
                          {sessions.map((session) => (
                            <option key={session.id} value={session.id}>
                              {session.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </div>
                  </div>

                  {selectedCampus &&
                    selectedSession &&
                    (timeTables.length > 0 ? (
                      timeTables.map((timeTable) => (
                        <div
                          className="timetable-box"
                          onClick={() => enrollStudent(timeTable)}>
                          <h5 className="">
                            {" "}
                            <span className="fw-light fs-6">Course :</span>{" "}
                            {timeTable.relationships.course.name}
                          </h5>
                          <h5>
                            {" "}
                            <span className="fw-light fs-6">
                              Teacher :
                            </span>{" "}
                            {timeTable.relationships.employee.name}
                          </h5>
                          <h6>
                            <span className="fw-light"> Day:</span>{" "}
                            {
                              timeTable.relationships.detail.relationships.day
                                .days
                            }
                          </h6>
                          <div>
                            <h6>
                              <span className="fw-light">Time-Slots: </span>
                              {moment(
                                timeTable.relationships.detail.relationships
                                  .slot.start,
                                "HH:mm"
                              ).format("hh:mma")}{" "}
                              -{" "}
                              {moment(
                                timeTable.relationships.detail.relationships
                                  .slot.end,
                                "HH:mm"
                              ).format("hh:mma")}
                            </h6>
                            <h6>
                              <span className="fw-light">Room: </span>
                              {
                                timeTable.relationships.detail.relationships
                                  .room.name
                              }
                            </h6>

                            <h6>
                              {" "}
                              <span className="fw-light">Section: </span>
                              {timeTable.relationships.section.name}
                            </h6>
                          </div>
                        </div>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={10}
                          className="text-center d-flex justify-content-center">
                          {loading ? (
                            <Spinner
                              className="text-center d-flex justify-content-center"
                              style={{ width: "30px", height: "30px" }}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="mt-4">
                  <div className="row">
                    <div className="col-md-4">
                      <FormGroup>
                        <Label for="campus_id">Campuses</Label>
                        <Input
                          className="rounded-2"
                          id="campus_id"
                          required
                          type="select"
                          name="campus_id"
                          value={selectedCampus}
                          onChange={handleChange}>
                          <option value="">Select Campus</option>
                          {campuses.map((campus) => (
                            <option key={campus.id} value={campus.id}>
                              {campus.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup>
                        <Label for="session_id">Sessions</Label>
                        <Input
                          className="rounded-2"
                          id="session_id"
                          required
                          type="select"
                          name="session_id"
                          value={selectedSession}
                          onChange={handleChange}>
                          <option value="">Select Session</option>
                          {sessions.map((session) => (
                            <option key={session.id} value={session.id}>
                              {session.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </div>
                  </div>

                  {selectedCampus &&
                    selectedSession &&
                    (timeTables.length > 0 ? (
                      timeTables.map((timeTable) => (
                        <div
                          className="timetable-box"
                          onClick={() => bulkEnrollStudent(timeTable)}>
                          <h5 className="">
                            {" "}
                            <span className="fw-light fs-6">Course :</span>{" "}
                            {timeTable.relationships.course.name}
                          </h5>
                          <h5>
                            {" "}
                            <span className="fw-light fs-6">
                              Teacher :
                            </span>{" "}
                            {timeTable.relationships.employee.name}
                          </h5>
                          <h6>
                            <span className="fw-light"> Day:</span>{" "}
                            {
                              timeTable.relationships.detail.relationships.day
                                .days
                            }
                          </h6>
                          <div>
                            <h6>
                              <span className="fw-light">Time-Slots: </span>
                              {moment(
                                timeTable.relationships.detail.relationships
                                  .slot.start,
                                "HH:mm"
                              ).format("hh:mma")}{" "}
                              -{" "}
                              {moment(
                                timeTable.relationships.detail.relationships
                                  .slot.end,
                                "HH:mm"
                              ).format("hh:mma")}
                            </h6>
                            <h6>
                              <span className="fw-light">Room: </span>
                              {
                                timeTable.relationships.detail.relationships
                                  .room.name
                              }
                            </h6>

                            <h6>
                              {" "}
                              <span className="fw-light">Section: </span>
                              {timeTable.relationships.section.name}
                            </h6>
                          </div>
                        </div>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={10} className="text-center">
                          {loading ? (
                            <Spinner
                              style={{ width: "30px", height: "30px" }}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                </div>
              </TabPane>
            </TabContent>
          </div>
        </Container>
      </AppLayout>

      <Enrollment
        // fetchTimetable={fetchTimetable}
        // selectedTimeslot={selectedTimeslot}
        // selectedRoom={selectedRoom}
        // selectedDay={selectedDay}
        // fetchEnrollments={fetchTimetable}
        selectedTimetable={selectedTimetable}
        selectedCampus={selectedCampus}
        selectedSession={selectedSession}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <BulkEnrollment
        // fetchTimetable={fetchTimetable}
        // selectedTimeslot={selectedTimeslot}
        // selectedRoom={selectedRoom}
        // selectedDay={selectedDay}
        // fetchEnrollments={fetchTimetable}
        selectedTimetable={selectedTimetable}
        selectedCampus={selectedCampus}
        selectedSession={selectedSession}
        showBulkModal={showBulkModal}
        setShowBulkModal={setShowBulkModal}
      />
    </>
  );
};

export default Timetable;
