import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import API from "../../utils/API";

const FeeCollection = ({ voucher, bankAccounts, fetchApplicant }) => {
  // Modal open state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const [data, setData] = useState({
    name: "",
    submitDate: "",
  });

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const response = await API.post(
        `/api/vouchers/${voucher.id}/collection`,
        data
      );

      setLoading(false);

      fetchApplicant();

      toggle();
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Button
        color="primary"
        className="rounded-0 btn-block w-100 btn-sm"
        onClick={toggle}
      >
        Fee Collection
      </Button>
      <Modal centered scrollable size="lg" toggle={toggle} isOpen={modal}>
        <ModalHeader toggle={toggle}>Fee Collection</ModalHeader>
        <Form method="POST" action="#" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label htmlFor="account_id" className="label-required">
                Select Bank Account
              </Label>
              <Input
                className="rounded-0"
                id="account_id"
                type="select"
                required
                name="account_id"
                value={data.account_id}
                onChange={handleChange}
              >
                <option value="">Select bank account</option>
                {bankAccounts.map((account) => (
                  <option key={`account-${account.id}`} value={account.id}>
                    {account.name}
                  </option>
                ))}
              </Input>

              <Label htmlFor="account_id" className="label-required">
                Select Date of Submission
              </Label>
              <Input
                className="rounded-0"
                id="submitdate"
                type="date"
                required
                name="submitDate"
                value={data.submitDate}
                onChange={handleChange}
              ></Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggle}
              color="light"
              className="rounded-0"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="rounded-0"
              disabled={loading}
            >
              {loading && (
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              )}
              Confirm
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default FeeCollection;
