import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Spinner, Table } from "reactstrap";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Container from "../../../components/Container";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Create from "./Create";
import Delete from "./Delete";
import Edit from "./Edit";

const Index = () => {
  const [sections, setSections] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchSessions = async () => {
    try {
      const data = (await API.get("/api/sections")).data.data;
      setSections(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSessions();
  }, [refresh]);

  return (
    <AppLayout>
      <Breadcrumbs
        title="Sections"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Sections",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <CardBody>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h1 className="mb-0">All Sections</h1>
              <Create section={sections} fetchSections={fetchSessions} />
            </div>
            <Table hover responsive>
              <thead>
                <tr className="bg-shade-blue">
                  <th>#</th>
                  <th>Name</th>
                  <th>Campus</th>
                  <th>Active</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {sections.length > 0 ? (
                  sections.map((section, index) => (
                    <tr
                      key={`section-${section.id}`}
                      style={{ verticalAlign: "middle" }}>
                      <th scope="row">{index + 1}</th>
                      <td>{section.name}</td>
                      <td>{section.relationships.campus.name}</td>
                      {section.is_active == 1 ? <td>Yes</td> : <td>No</td>}
                      <td>
                        <Edit section={section} fetchSections={fetchSessions} />
                        <Delete
                          section={section}
                          refresh={refresh}
                          setRefresh={setRefresh}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {loading ? (
                        <Spinner style={{ width: "30px", height: "30px" }} />
                      ) : (
                        "No Sections Exists"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};

export default Index;
