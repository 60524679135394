import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CanAny = ({ permissions, children }) => {
  const auth = useSelector((state) => state?.auth);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (auth?.authData) {
      var user = auth?.authData.data;
      if (
        user?.relationships?.permissions.some((permission) => {
          if (permission.name.indexOf(permissions) !== -1) return true;

          return false;
        }) ||
        user?.relationships?.role[0].name.indexOf("Superadmin") !== -1
      )
        setShow(true);
    }
  }, [auth?.authData]);

  if (show) return children;

  return null;
};

export default CanAny;
