import {
  faPlusCircle,
  faArrowsUpDownLeftRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Spinner, Table } from "reactstrap";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Container from "../../../components/Container";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Create from "./Create";
import Delete from "./Delete";
import Edit from "./Edit";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const Index = () => {
  const [days, setDays] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(days);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    const newOrder = tempData.map((item) => item.id);
    API.post("/api/days/order", {
      order: newOrder,
    });
    setDays(tempData);
  };

  const fetchDays = async () => {
    try {
      const data = (await API.get("/api/days")).data.data;
      setDays(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDays();
  }, [refresh]);

  return (
    <AppLayout>
      <Breadcrumbs
        title="Days"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Days",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <CardBody>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h1 className="mb-0">All Days</h1>
              <Create day={days} fetchDays={fetchDays} />
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Table hover responsive>
                <thead>
                  <tr className="bg-shade-blue">
                    <th>#</th>
                    <th></th>
                    <th>days</th>
                    <th>Active</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <Droppable droppableId="droppable-1" className="w-100">
                  {(provider) => (
                    <tbody
                      className="text-capitalize"
                      ref={provider.innerRef}
                      {...provider.droppableProps}>
                      {days.length > 0 ? (
                        days.map((day, index) => (
                          <Draggable
                            key={day.days}
                            draggableId={day.days}
                            index={index}>
                            {(provider) => (
                              <tr
                                className="drag-box"
                                {...provider.draggableProps}
                                {...provider.dragHandleProps}
                                ref={provider.innerRef}
                                key={`day-${day.id}`}>
                                <th
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                  scope="row">
                                  {index + 1}
                                </th>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                  {...provider.dragHandleProps}>
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={
                                      faArrowsUpDownLeftRight
                                    }></FontAwesomeIcon>{" "}
                                </td>

                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}>
                                  {day.days}
                                </td>
                                {day.is_active == 1 ? (
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}>
                                    Yes
                                  </td>
                                ) : (
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}>
                                    No
                                  </td>
                                )}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}>
                                  {moment(day.created_at).format("DD/MM/YY")}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}>
                                  {moment(day.updated_at).format("DD/MM/YY")}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}>
                                  <Edit day={day} fetchDays={fetchDays} />
                                  <Delete
                                    day={day}
                                    refresh={refresh}
                                    setRefresh={setRefresh}
                                  />
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={10} className="text-center">
                            {loading ? (
                              <Spinner
                                style={{ width: "30px", height: "30px" }}
                              />
                            ) : (
                              "No Days Exists"
                            )}
                          </td>
                        </tr>
                      )}
                      {provider.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </Table>
            </DragDropContext>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};

export default Index;
