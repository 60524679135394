import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../../components/Breadcrumbs';
import AppLayout from '../../../components/layouts/AppLayout';
import Container from "../../../components/Container";
import { Card, CardBody,Table } from 'reactstrap';
import API from '../../../utils/API';


const Leaves = () => {
  const [leaves, setLeaves] = useState([]);

  const fetchLeaves = async () => {
    try {
      const data = (await API.get(`/api/leave`)).data.data;
      setLeaves(data);
    } catch (error) {
      
    }
  };
  useEffect(() => {
    fetchLeaves();
  }, [])
  
  return (
   <AppLayout>
         <Breadcrumbs
        title="Leaves"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Leaves",
            link: null,
          },
        ]}
      />
       <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3 d-flex align-items-center justify-content-between border-bottom">
            <h1 className="mb-0">Leaves</h1>
            </div>
            <CardBody>
            <Table hover responsive>
              <thead>
                <tr className="bg-shade-blue">
                  <th>#</th>
                  <th>Employee Name</th>
                  <th>Employee Id</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {leaves.map((leave, index) => (
                  <tr className='table-row' key={`leave-${leave.id}`}>
                    <th scope="row">{index + 1}</th>
                    <td>{leave.name}</td>
                    <td>{leave.employee_id}</td>
                    <td></td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </Table>
            </CardBody>
            </Card>
            </Container>
   </AppLayout>
  )
}

export default Leaves