import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    common: {
      "X-Requested-With": "XMLHttpRequest",
    },
  },
});
