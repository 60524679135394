import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { Card, CardBody, Table } from "reactstrap";
import Breadcrumbs from "../../components/Breadcrumbs";
import Container from "../../components/Container";
import FullscreenLoader from "../../components/FullscreenLoader";
import AppLayout from "../../components/layouts/AppLayout";
import API from "../../utils/API";
import moment from "moment";
import FeeCollection from "./FeeCollection";
import { numberFormat } from "../../utils/helpers";
import axios from "axios";
import { toast } from "react-toastify";

const Show = () => {
  const params = useParams();
  const [applicant, setApplicant] = useState([]);
  const [scholarships, setScholarships] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bankAccounts, setBankAccounts] = useState([]);

  const fetchApplicant = async () => {
    try {
      const data = (await API.get(`/api/applicants/${params.applicantId}`)).data
        .data;
      setApplicant(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchScholarships = async () => {
    try {
      const data = (await API.get(`/api/scholarships/${params.applicantId}`))
        .data.data;
      setScholarships(data);
      console.log(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApplicant();
    fetchScholarships();
    const fetchBankAccounts = async () => {
      try {
        setLoading(true);
        const response = (
          await API.get("/api/accounts?parent-account-id=6-07-02-0000")
        ).data.data;
        setBankAccounts(response);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchBankAccounts();
  }, []);
  const extendDate = (id) => {
    API.post(`api/extendvoucher/${id}`)
      .then((res) => {
        console.log(res);
        fetchApplicant();
        toast(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const splitVoucher = (id) => {
    API.post(`api/splitvoucher/${id}`)
      .then((res) => {
        console.log(res);
        fetchApplicant();
        toast(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loading) return <FullscreenLoader />;

  return (
    <AppLayout>
      <Breadcrumbs
        title={`Admissions - Applicant #${applicant?.id}`}
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Admissions",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <CardBody>
            <Card>
              <CardBody>
                <div className="d-flex">
                  <img
                    src={applicant?.picture}
                    alt=""
                    className="me-2 mb-2"
                    style={{ width: "200px" }}
                  />
                  <div
                    style={{ flex: "1" }}
                    className="d-flex flex-column justify-content-between"
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="display-4 fw-bold text-light-blue">
                        ID: A-{applicant?.id}
                      </div>
                      <div className="btn btn-success rounded-0">
                        <FontAwesomeIcon icon={faPrint} className="me-2" />{" "}
                        Export
                      </div>
                    </div>
                    <div className="d-flex flex-wrap">
                      <div
                        style={{ flex: "1", minWidth: "fit-content" }}
                        className="bg-shade-blue p-3 me-2 mb-2"
                      >
                        <span className="fw-bold me-2">Name:</span>
                        <span>{applicant?.name}</span>
                      </div>
                      <div
                        style={{ flex: "1", minWidth: "fit-content" }}
                        className="bg-shade-blue p-3 me-2 mb-2"
                      >
                        <span className="fw-bold me-2">Roll No:</span>
                        <span>
                          {applicant?.relationships?.student &&
                          applicant?.relationships?.student.roll_number
                            ? applicant.relationships?.student?.roll_number
                            : "Pending"}
                        </span>
                      </div>
                      <div
                        style={{ flex: "1", minWidth: "fit-content" }}
                        className="bg-shade-blue p-3 me-2 mb-2"
                      >
                        <span className="fw-bold me-2">Applicant No:</span>
                        <span>{applicant?.id}</span>
                      </div>
                      <div
                        style={{ flex: "1", minWidth: "fit-content" }}
                        className="bg-shade-blue p-3 me-2 mb-2"
                      >
                        <span className="fw-bold me-2">Email:</span>
                        <span>{applicant?.email}</span>
                      </div>
                      <div
                        className="d-flex"
                        style={{ flex: "1", minWidth: "fit-content" }}
                      >
                        <div
                          style={{ flex: "1", minWidth: "fit-content" }}
                          className="bg-shade-blue p-3 me-2 mb-2"
                        >
                          <span className="fw-bold me-2">Phone Number:</span>
                          <span>{applicant?.phone_number}</span>
                        </div>
                        <div
                          style={{ flex: "1", minWidth: "fit-content" }}
                          className="bg-shade-blue p-3 me-2 mb-2"
                        >
                          <span className="fw-bold me-2">WhatsApp Number:</span>
                          <span>{applicant?.whatsapp_number}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-wrap align-items-end">
                  <div
                    style={{ flex: "1", minWidth: "fit-content" }}
                    className="bg-shade-blue p-3 me-2 mb-2"
                  >
                    <span className="fw-bold me-2">CNIC/B-Form:</span>
                    <span>{applicant?.cnic}</span>
                  </div>
                  <div
                    style={{ flex: "1", minWidth: "fit-content" }}
                    className="bg-shade-blue p-3 me-2 mb-2"
                  >
                    <span className="fw-bold me-2">Faculty:</span>
                    <span>
                      {applicant?.relationships?.program &&
                        applicant?.relationships?.program?.relationships
                          ?.faculty.name}
                    </span>
                  </div>
                  <div
                    style={{ flex: "1", minWidth: "fit-content" }}
                    className="bg-shade-blue p-3 me-2 mb-2"
                  >
                    <span className="fw-bold me-2">Program:</span>
                    <span>{applicant?.relationships?.program?.name}</span>
                  </div>
                </div>
                {/* <div
                    style={{ flex: "1", minWidth: "fit-content" }}
                    className="bg-shade-blue p-3 me-2 mb-2"
                  >
                    <span className="fw-bold me-2">Section:</span>
                    <span>
                      {applicant.relationships.student
                        ? applicant.student.section
                        : "Pending"}
                    </span>
                  </div> */}
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">Session:</span>
                  <span>
                    {applicant?.relationships?.academic_session?.name}
                  </span>
                </div>
                {/* <div
                  < div
                    style={{ flex: '1', minWidth: 'fit-content' }}
                    className='bg-shade-blue p-3 me-2 mb-2'
                  >
                    <span className="fw-bold me-2">Session:</span>
                    <span>{applicant.relationships?.academic_session?.name}</span>
                  </div>
                  {/* <div

                    style={{ flex: "1", minWidth: "fit-content" }}
                    className="bg-shade-blue p-3 me-2 mb-2"
                  >
                    <span className="fw-bold me-2">Batch:</span>
                    <span>
                      {applicant.relationships.student
                        ? applicant.relationships.student.batch
                        : "Pending"}
                    </span>
                  </div> */}
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">Shift:</span>
                  <span>{applicant?.shift}</span>
                </div>
                <div
                  style={{ flex: "1", minWidth: "fit-content" }}
                  className="bg-shade-blue p-3 me-2 mb-2"
                >
                  <span className="fw-bold me-2">Campus:</span>
                  <span>{applicant?.relationships?.campus?.name}</span>
                </div>
                <div
                  className="d-flex"
                  style={{ flex: "1", minWidth: "fit-content" }}
                >
                  <div
                    style={{ flex: "1", minWidth: "fit-content" }}
                    className="bg-shade-blue p-3 me-2 mb-2"
                  >
                    <span className="fw-bold me-2">Campus:</span>
                    <span>{applicant?.relationships?.campus?.name}</span>
                  </div>
                  <div
                    className="d-flex"
                    style={{ flex: "1", minWidth: "fit-content" }}
                  >
                    <div
                      style={{ flex: "1", minWidth: "fit-content" }}
                      className="bg-shade-blue p-3 me-2 mb-2"
                    >
                      <span className="fw-bold me-2">Current Address:</span>
                      <span>{applicant?.current_address}</span>
                    </div>
                    <div
                      style={{ flex: "1", minWidth: "fit-content" }}
                      className="bg-shade-blue p-3 me-2 mb-2"
                    >
                      <span className="fw-bold me-2">Permanent Address:</span>
                      <span>{applicant?.permanent_address}</span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            {applicant?.relationships?.campus?.id != 2 && (
              <>
                <Card className="mt-3">
                  <CardBody>
                    <h2 className="fw-bold mb-3 text-light-blue">
                      Fee Schedule
                    </h2>
                    <div className="d-flex flex-wrap">
                      <div
                        style={{ flex: "1", minWidth: "fit-content" }}
                        className="bg-shade-blue p-3 me-2 mb-2"
                      >
                        <span className="fw-bold me-2">Application Fee:</span>
                        <span>2,000</span>
                        <br />
                        <small className="text-muted">
                          Payable at the time of admission
                        </small>
                      </div>
                      <div
                        style={{ flex: "1", minWidth: "fit-content" }}
                        className="bg-shade-blue p-3 me-2 mb-2"
                      >
                        <span className="fw-bold me-2">Admission Fee:</span>
                        <span>25,000</span>
                        <br />
                        <small className="text-muted">
                          Payable at the time of admission
                        </small>
                      </div>
                      <div
                        style={{ flex: "1", minWidth: "fit-content" }}
                        className="bg-shade-blue p-3 me-2 mb-2"
                      >
                        <span className="fw-bold me-2">Program Fee:</span>
                        <span>
                          {numberFormat(
                            applicant?.relationships?.program?.semester_fee
                          )}
                        </span>
                        <br />
                        <small className="text-muted">Per Semester</small>
                      </div>
                      <div
                        style={{ flex: "1", minWidth: "fit-content" }}
                        className="bg-shade-blue p-3 me-2 mb-2"
                      >
                        <span className="fw-bold me-2">Basic Fee:</span>
                        <span>{numberFormat(applicant?.basic_fee)}</span>
                        <br />
                        <small className="text-muted">After discount</small>
                      </div>
                      <div
                        style={{ flex: "1", minWidth: "fit-content" }}
                        className="d-flex align-items-center bg-shade-blue p-3 me-2 mb-2"
                      >
                        <div>
                          <span className="fw-bold me-2">
                            Work Base Scholarship:
                          </span>
                          <span>
                            {applicant?.scholarship
                              ? applicant?.scholarship
                              : "None"}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{ flex: "1", minWidth: "fit-content" }}
                        className="d-flex align-items-center bg-shade-blue p-3 me-2 mb-2"
                      >
                        <div>
                          <span className="fw-bold me-2">Discount:</span>
                          <span>
                            {applicant?.scholarship_discount
                              ? applicant?.scholarship_discount + "%"
                              : "None"}
                          </span>
                        </div>
                      </div>

                      {/* <div
                    style={{ flex: "1", minWidth: "fit-content" }}
                    className="d-flex align-items-center bg-shade-blue p-3 me-2 mb-2">
                    <div>
                      <span className="fw-bold me-2">Total Tax:</span>
                      <span>{numberFormat(applicant?.total_tax)}</span>
                    </div>
                  </div> */}
                      {/* <div className="d-flex w-100">
                    <div
                      style={{ flex: "1", minWidth: "fit-content" }}
                      className="bg-shade-blue p-3 me-2 mb-2">
                      <span className="fw-bold me-2">Total Fee:</span>
                      <span>{numberFormat(applicant?.total_fee)}</span>
                      <br />
                      <small className="text-muted">Inclusive of Taxes</small>
                    </div>
                    <div
                      style={{ flex: "1", minWidth: "fit-content" }}
                      className="bg-shade-blue p-3 me-2 mb-2">
                      <span className="fw-bold me-2">Net Fee:</span>
                      <span>{numberFormat(applicant?.net_fee)}</span>
                      <br />
                      <small className="text-muted">
                        Includes taxes, application &amp; admission fee
                      </small>
                    </div>
                  </div> */}
                    </div>
                    <div className="d-flex flex-wrap">
                      {scholarships && scholarships.length > 0 ? (
                        scholarships.map((scholarship, index) => (
                          <div
                            style={{ flex: "1", minWidth: "fit-content" }}
                            className="d-flex align-items-center bg-shade-blue p-3 me-2 mb-2"
                          >
                            <span className="fw-bold me-2">
                              {" "}
                              Other Scholarship:
                            </span>
                            <span>
                              {scholarship?.scholarship_type
                                ? scholarship?.scholarship_type
                                : "None"}
                            </span>
                          </div>
                        ))
                      ) : (
                        <div
                          style={{ flex: "1", minWidth: "fit-content" }}
                          className="d-flex align-items-center bg-shade-blue p-3 me-2 mb-2"
                        >
                          <span className="fw-bold me-2">
                            {" "}
                            No Other Scholarship:
                          </span>
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>

                <Card className="mt-3">
                  <CardBody>
                    <h2 className="fw-bold mb-3 text-light-blue">
                      All Vouchers
                    </h2>
                    <Table hover responsive className="nowrap">
                      <thead>
                        <tr className="bg-shade-blue">
                          <th>#</th>
                          <th>Voucher No.</th>
                          <th>Session</th>
                          <th>Type</th>
                          <th>Status</th>
                          {/* <th>Particulars</th> */}
                          {/* <th>Scholarship Type</th> */}
                          <th>Total</th>
                          <th>Payment Method</th>
                          <th>Received By</th>
                          <th>Due Date</th>
                          <th>Expiry Date</th>
                          <th>Created At</th>
                          <th>Updated At</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {applicant?.relationships?.vouchers &&
                        applicant?.relationships?.vouchers?.length > 0 ? (
                          applicant.relationships.vouchers.map(
                            (voucher, index) => (
                              <tr key={`voucher-${voucher.id}`}>
                                <th scope="row">{index + 1}</th>
                                <td>{voucher?.id}</td>
                                <td>{voucher?.fee_period}</td>
                                <td>{voucher?.fee_type}</td>
                                <td>
                                  {voucher?.paid_at ? (
                                    <div className="badge rounded-pill bg-success">
                                      Paid @{" "}
                                      {moment(voucher?.paid_at).format(
                                        "DD/MM/YY"
                                      )}
                                    </div>
                                  ) : (
                                    <div className="badge rounded-pill bg-info">
                                      Pending
                                    </div>
                                  )}
                                </td>
                                {/* <td>{voucher.scholarship} @ {voucher.scholarship_discount}%</td> */}
                                <td>{numberFormat(voucher?.total)}</td>
                                <td>{voucher?.account_details?.name}</td>
                                <td>{voucher?.received_by?.name}</td>
                                <td>
                                  {moment(voucher?.due_date).format("DD/MM/YY")}
                                </td>
                                <td>
                                  {moment(voucher?.expiry_date).format(
                                    "DD/MM/YY"
                                  )}
                                </td>
                                <td>
                                  {moment(voucher?.created_at).format(
                                    "DD/MM/YY"
                                  )}
                                </td>
                                <td>
                                  {moment(voucher?.updated_at).format(
                                    "DD/MM/YY"
                                  )}
                                </td>
                                <td>
                                  <Link
                                    to={`/voucher/${voucher?.id}`}
                                    target="_blank"
                                    className="btn btn-primary d-block mb-2 btn-sm w-100 rounded-0"
                                    rel="noreferrer"
                                  >
                                    View
                                  </Link>
                                  <Link
                                    to={`/voucher/${voucher?.id}???`}
                                    target="_blank"
                                    className="btn btn-primary d-block mb-2 btn-sm w-100 rounded-0"
                                    rel="noreferrer"
                                  >
                                    Print Cash Voucher
                                  </Link>
                                  {!voucher.paid_at && (
                                    <FeeCollection
                                      bankAccounts={bankAccounts}
                                      voucher={voucher}
                                      fetchApplicant={fetchApplicant}
                                    />
                                  )}
                                  {!voucher.paid_at && (
                                    <button
                                      onClick={() => extendDate(voucher.id)}
                                      className="btn btn-primary d-block mt-2 mb-2 btn-sm w-100 rounded-0"
                                    >
                                      Extend Date (7 Days)
                                    </button>
                                  )}
                                  {!voucher.paid_at &&
                                    voucher.fee_type == "Admission Fee" && (
                                      <button
                                        onClick={() => splitVoucher(voucher.id)}
                                        className="btn btn-primary d-block mt-2 mb-2 btn-sm w-100 rounded-0"
                                      >
                                        Split Voucher
                                      </button>
                                    )}
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan={10} className="text-center">
                              No Vouchers Exists
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};

export default Show;
