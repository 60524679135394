import "../../../css/modal.css";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavLink,
  Spinner,
} from "reactstrap";
import API from "../../../utils/API";

const Create = ({
  selectedTimeslot,
  selectedDay,
  selectedRoom,
  selectedCampus,
  showModal,
  selectedDepartment,
  selectedSession,
  setShowModal,
  fetchData: fetchTimetable,
}) => {
  // Modal open state
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const [employees, setEmployees] = useState([]);
  const [sections, setSections] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [courses, setCourses] = useState([]);

  const defaultData = {
    timeslot_id: selectedTimeslot?.id,
    day_id: selectedDay?.id,
    room_id: selectedRoom?.id,
    course_id: "",
    employee_id: "",
    section_id: "",
    semester: "",
    campus_id: null,
    department_id: null,
    session_id: null,
    program_id: "",
    course_id: "",
    is_exam: 0,
    class_start_date: "",
    is_active: 0,
    is_open_for_all: 0,
    is_locked: 0,
    is_self_study: 0,
    is_auto_enrolled: 0,
  };
  const [data, setData] = useState(defaultData);

  // Toggle for Modal
  const toggle = () => setShowModal(!showModal);

  async function handleSubmitData(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await API.post("/api/timetable", data);

      setLoading(false);
      toggle();
      setData(defaultData);
      setErrors([]);

      fetchTimetable();
    } catch (error) {
      setErrors(
        error?.response?.data?.errors ? error.response.data.errors : []
      );
      setLoading(false);
    }
  }

  const fetchData = async () => {
    const [employees, sections, programs, courses] = await Promise.all([
      API.get("/api/employee"),
      API.get("/api/sections"),
      API.get("/api/programs"),
      API.get(`/api/courses?department_id=${data.department_id}`),
    ]);

    setEmployees(employees.data.data);
    setSections(sections.data.data);
    setPrograms(programs.data.data);
    setCourses(courses.data.data);
  };

  useEffect(() => {
    if (selectedCampus) data.campus_id = selectedCampus;
    if (selectedDepartment) data.department_id = selectedDepartment;
    if (selectedSession) data.session_id = selectedSession;

    if (selectedTimeslot) data.timeslot_id = selectedTimeslot.id;
    if (selectedDay) data.day_id = selectedDay.id;
    if (selectedRoom) data.room_id = selectedRoom.id;
  }, [
    selectedCampus,
    selectedDepartment,
    selectedTimeslot,
    selectedTimeslot,
    selectedDay,
    selectedRoom,
  ]);

  useEffect(() => {
    if (selectedDepartment) {
      setData({ ...data, department_id: selectedDepartment.id });
      fetchData();
    }
  }, [selectedDepartment]);

  const handleChange = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleChangeCheckbox = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.checked ? 1 : 0 });
  };

  if (!showModal && !selectedTimeslot && !selectedDay && !selectedRoom) return;

  return (
    <div>
      <NavLink className="rounded-pill bg-none" onClick={toggle}>
        Create New
      </NavLink>

      <Modal
        modalClassName="custommodal"
        centered
        scrollable
        size="xl"
        toggle={toggle}
        isOpen={showModal}
        fade={true}>
        <ModalHeader toggle={toggle}>Add Course</ModalHeader>
        <Form method="POST" action="#" onSubmit={handleSubmitData}>
          <ModalBody>
            <FormGroup>
              <Label for="timeslot" className="label-required">
                Timeslot
              </Label>
              <Input
                className="rounded-2"
                id="timeslot"
                type="text"
                defaultValue={`${moment(selectedTimeslot.start, "HH:mm").format(
                  "hh:mma"
                )} - ${moment(selectedTimeslot.end, "HH:mm").format("hh:mma")}`}
                readOnly
              />
            </FormGroup>
            <FormGroup>
              <Label for="day" className="label-required">
                Day
              </Label>
              <Input
                className="rounded-2"
                id="day"
                type="text"
                defaultValue={selectedDay.days}
                readOnly
              />
            </FormGroup>
            <FormGroup>
              <Label for="room" className="label-required">
                Room
              </Label>
              <Input
                className="rounded-2"
                id="room"
                type="text"
                defaultValue={selectedRoom.name}
                readOnly
              />
            </FormGroup>
            <FormGroup>
              <Label for="semester" className="label-required">
                Semester
              </Label>
              <Input
                className="rounded-2"
                id="semester"
                type="text"
                name="semester"
                maxLength={255}
                value={data.semester}
                onChange={handleChange}
                required
                autoFocus
              />
              {errors.name && (
                <Label className="text-danger mt-2" for="name">
                  {errors.name}
                </Label>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="employee_id">Employees</Label>
              <Input
                className="rounded-2"
                id="employee_id"
                required
                type="select"
                name="employee_id"
                onChange={handleChange}>
                <option value="">Select Users</option>
                {employees.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.name}
                  </option>
                ))}
              </Input>
              {errors.employee_id && (
                <Label className="text-danger mt-2" for="name">
                  {errors.employee_id}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="section_id">Sections</Label>
              <Input
                className="rounded-2"
                id="section_id"
                required
                type="select"
                name="section_id"
                onChange={handleChange}>
                <option value="">Select Section</option>
                {sections.map((section) => (
                  <option key={section.id} value={section.id}>
                    {section.name}
                  </option>
                ))}
              </Input>
              {errors.section_id && (
                <Label className="text-danger mt-2" for="name">
                  {errors.section_id}
                </Label>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="program_id">Programs</Label>
              <Input
                className="rounded-2"
                id="program_id"
                required
                type="select"
                name="program_id"
                onChange={handleChange}>
                <option value="">Select Program</option>
                {programs.map((program) => (
                  <option key={program.id} value={program.id}>
                    {program.name}
                  </option>
                ))}
              </Input>
              {errors.program_id && (
                <Label className="text-danger mt-2" for="name">
                  {errors.program_id}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="course_id">courses</Label>
              <Input
                className="rounded-2"
                id="course_id"
                required
                type="select"
                name="course_id"
                onChange={handleChange}>
                <option value="">Select Course</option>
                {courses.map((course) => (
                  <option key={course.id} value={course.id}>
                    {course.name}
                  </option>
                ))}
              </Input>
              {errors.course_id && (
                <Label className="text-danger mt-2" for="name">
                  {errors.course_id}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="class_start_date" className="label-required">
                Class Start Date
              </Label>
              <Input
                className="rounded-2"
                id="class_start_date"
                type="date"
                name="class_start_date"
                maxLength={255}
                value={data.class_start_date}
                onChange={handleChange}
                required
                autoFocus
              />
              {errors.class_start_date && (
                <Label className="text-danger mt-2" for="name">
                  {errors.class_start_date}
                </Label>
              )}
            </FormGroup>
            <div className="row">
              <div className="col-md-4">
                <FormGroup>
                  <Label for="is_exam" className="label-required">
                    Exam
                  </Label>
                  <Input
                    className="rounded-2"
                    id="is_exam"
                    type="checkbox"
                    name="is_exam"
                    maxLength={255}
                    value={data.is_exam}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label for="is_active" className="label-required">
                    Active
                  </Label>
                  <Input
                    className="rounded-2"
                    id="is_active"
                    type="checkbox"
                    name="is_active"
                    maxLength={255}
                    value={data.is_active}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label for="is_open_for_all" className="label-required">
                    Open For All
                  </Label>
                  <Input
                    className="rounded-2"
                    id="is_open_for_all"
                    type="checkbox"
                    name="is_open_for_all"
                    maxLength={255}
                    value={data.is_open_for_all}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <FormGroup>
                  <Label for="is_locked" className="label-required">
                    Locked
                  </Label>
                  <Input
                    className="rounded-2"
                    id="is_locked"
                    type="checkbox"
                    name="is_locked"
                    maxLength={255}
                    value={data.is_locked}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label for="is_self_study" className="label-required">
                    Self Study
                  </Label>
                  <Input
                    className="rounded-2"
                    id="is_self_study"
                    type="checkbox"
                    name="is_self_study"
                    maxLength={255}
                    value={data.is_self_study}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label for="is_auto_enrolled" className="label-required">
                    Auto Enrolled
                  </Label>
                  <Input
                    className="rounded-2"
                    id="is_auto_enrolled"
                    type="checkbox"
                    name="is_auto_enrolled"
                    maxLength={255}
                    value={data.is_auto_enrolled}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggle}
              color="light"
              className="rounded-2">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="rounded-2"
              disabled={loading}>
              {loading && (
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              )}
              Submit
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};
export default Create;
