import React, { useEffect, useState } from "react";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { Card, CardBody, Container, Spinner, Table } from "reactstrap";
import CreateQuestion from "../QuestionBank/CreateQuestion";
import UpdateQuestion from "../QuestionBank/UpdateQuestion";
import DeleteQuestion from "./DeleteQuestion";

export const MidTerm = () => {
  const [subjective, setSubjective] = useState();
  const [objective, setObjective] = useState();
  const [midTerm, setMidTerm] = useState();
  const [refresh, setRefresh] = useState(false);

  const [loading, setLoading] = useState(true);

  const fetcMidTermQuestions = async () => {
    try {
      const response = (await API.get("/api/question/bank")).data.data;
      setMidTerm(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setLoading(false);
    fetcMidTermQuestions();
  }, [refresh]);

  return (
    <>
      <div className="mt-3 midTermBtn">
        <CreateQuestion
          setRefresh={setRefresh}
          refresh={refresh}
          setMidTerm={setMidTerm}
          midTerm={midTerm}
        />
      </div>
      <ul
        class="nav nav-tabs timeTableNav timeTableNavBtm mt-5"
        id="myTab"
        role="tablist"
      >
        <li class="nav-item d-grid" role="presentation">
          <button
            class="nav-link active"
            id="subjective-tab"
            data-bs-toggle="tab"
            data-bs-target="#subjective"
            type="button"
            role="tab"
            aria-controls="subjective"
            aria-selected="true"
          >
            Subjective Questions
          </button>
        </li>
        <li class="nav-item d-grid" role="presentation">
          <button
            class="nav-link"
            id="objective-tab"
            data-bs-toggle="tab"
            data-bs-target="#objective"
            type="button"
            role="tab"
            aria-controls="objective"
            aria-selected="false"
          >
            Objective Questions
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="subjective"
          role="tabpanel"
          aria-labelledby="subjective-tab"
        >
          <Table hover responsive border className="mt-3">
            <thead>
              <tr className="bg-shade-blue">
                <th>#</th>
                <th>Question</th>
                <th>Level</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {midTerm?.length > 0 ? (
                midTerm?.map((questions) =>
                  questions?.relationships?.questions
                    ?.filter(
                      (question) =>
                        question?.relationships?.type?.title == "long" &&
                        question?.type_of == "midterm"
                    )
                    .map((singleQuestion, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{singleQuestion?.question} </td>
                        <td>
                          {" "}
                          <span class="badge bg-primary">
                            {singleQuestion?.level}
                          </span>{" "}
                        </td>
                        <td>
                          {/* <UpdateQuestion
                            data={singleQuestion}
                            setRefresh={setRefresh}
                            refresh={refresh}
                          /> */}
                          <DeleteQuestion
                            data={singleQuestion}
                            setRefresh={setRefresh}
                            refresh={refresh}
                          />
                        </td>
                      </tr>
                    ))
                )
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    {loading ? (
                      <span>No record found</span>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <div
          class="tab-pane fade"
          id="objective"
          role="tabpanel"
          aria-labelledby="objective-tab"
        >
          <Table hover responsive border className="mt-3">
            <thead>
              <tr className="bg-shade-blue">
                <th>#</th>
                <th>Question</th>
                <th>Level</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {midTerm?.length > 0 ? (
                midTerm?.map((questions) =>
                  questions?.relationships?.questions
                    ?.filter(
                      (question) =>
                        (question?.relationships?.type?.title == "short" ||
                          question?.relationships?.type?.title == "mcq" ||
                          question?.relationships?.type?.title ==
                            "checkboxes") &&
                        question?.type_of == "midterm"
                    )
                    .map((singleQuestion, index) => (
                      <tr question={index}>
                        <td>{index + 1}</td>
                        <td>{singleQuestion?.question}</td>
                        <td>
                          {" "}
                          <span class="badge bg-primary">
                            {singleQuestion?.level}
                          </span>{" "}
                        </td>
                        <td>
                          {/* <UpdateQuestion /> */}
                          <DeleteQuestion
                            data={singleQuestion}
                            setRefresh={setRefresh}
                            refresh={refresh}
                          />
                        </td>
                      </tr>
                    ))
                )
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    {loading ? (
                      <span>No record found</span>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};
