import React, { useEffect, useState } from "react";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Breadcrumbs from "../../../components/Breadcrumbs";

import {
  Card,
  CardBody,
  Container,
  Spinner,
  Table,
  Row,
  Col,
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export const Create = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [enrolledStudents, setEnrolledStudents] = useState([]);
  const currentDate = new Date();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");

  const keys = [
    "roll_number",
    "semester",
    // "relationships?.student?.relationships?.applicant?.name",
  ];

  const [attendance, setAttendance] = useState({
    topic: "",
    date: currentDate.toLocaleDateString("en-CA"),
    attendance: [],
    timetable_id: params.id,
  });

  const studentAttendance = async () => {
    try {
      const response = (await API.get(`/api/enrollment/${params.id}`)).data
        .data;
      setEnrolledStudents(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    studentAttendance();
  }, []);

  const markAttendance = (e) => {
    const rollNumber = e.target.name.replace("status-", "");

    const attendanceList = attendance.attendance;

    const alreadyExists = attendanceList.find(
      (rec) => rec.roll_number == rollNumber
    );

    if (alreadyExists) {
      const updatedAttendance = attendanceList.map((rec) => {
        if (rec.roll_number != rollNumber) {
          return rec;
        } else {
          rec.status = e.target.value;
          return rec;
        }
      });

      setAttendance({
        ...attendance,
        attendance: updatedAttendance,
      });
    } else {
      attendanceList.push({
        roll_number: rollNumber,
        status: e.target.value,
      });

      setAttendance({
        ...attendance,
        attendance: attendanceList,
      });
    }
  };

  const handleChange = (e) => {
    setAttendance({ ...attendance, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    API.post(`/api/attendence`, attendance)
      .then(() => {
        setLoading(false);
        toast.success("Attendance Marked Successfully");
        navigate(`/student-attendance/${params.id}`);
        setLoading(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <AppLayout>
      <ToastContainer />
      <Breadcrumbs
        title="Student Attendance"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Student Attendance",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3 d-flex align-items-center justify-content-between border-bottom">
            <h1 className="mb-0">Student Attendance</h1>
          </div>
          <CardBody>
            <label className="form-label mb-5">
              <input
                key={query.id}
                type="text"
                placeholder="Search by roll number"
                onChange={(e) => setQuery(e.target.value)}
                className="form-control"
                autoComplete="off"
              />
            </label>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={8}>
                  <label htmlFor="topicCovered" className="form-label">
                    Topic Covered
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    name="topic"
                    id="topicCovered"
                    onChange={handleChange}
                    placeholder="Type..."
                    autoComplete="off"
                  />
                </Col>
                <Col md={4}>
                  <label htmlFor="topicDate" className="form-label">
                    Select Date
                  </label>
                  <input
                    type="date"
                    id="topicDate"
                    name="date"
                    className="form-control"
                    required
                    value={attendance.date}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Table hover responsive border className="mt-3">
                <thead>
                  <tr className="bg-shade-blue">
                    <th>#</th>
                    <th>Roll No.</th>
                    <th>Name</th>
                    <th>Semester</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {enrolledStudents.length > 0 ? (
                    enrolledStudents
                      .filter((detail) =>
                        keys.some((key) =>
                          detail[key].toString().toLowerCase().includes(query)
                        )
                      )
                      .map((detail, index) => (
                        <tr key={detail.id}>
                          <td scope="row">{index + 1}</td>
                          <td>{detail.roll_number}</td>
                          <td>
                            {
                              detail?.relationships?.student?.relationships
                                ?.applicant?.name
                            }
                          </td>
                          <td> {detail?.relationships?.timetable?.semester}</td>
                          <td>
                            <div className="d-block">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`status-${detail.roll_number}`}
                                  value="present"
                                  id={`present-${detail.roll_number}`}
                                  onChange={markAttendance}
                                  required
                                />
                                <label
                                  className="form-check-label"
                                  for={`present-${detail.roll_number}`}
                                >
                                  Present
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id={`absent-${detail.roll_number}`}
                                  name={`status-${detail.roll_number}`}
                                  value="absent"
                                  onChange={markAttendance}
                                  required
                                />
                                <label
                                  className="form-check-label"
                                  for={`absent-${detail.roll_number}`}
                                >
                                  Absent
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id={`on-leave-${detail.roll_number}`}
                                  name={`status-${detail.roll_number}`}
                                  value="on_leave"
                                  onChange={markAttendance}
                                  required
                                />
                                <label
                                  className="form-check-label"
                                  for={`on-leave-${detail.roll_number}`}
                                >
                                  Leave
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        <div className="d-flex justify-content-center">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <hr />
              <div className="text-end">
                <button
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading && (
                    <Spinner className="me-2" size="sm">
                      Loading...
                    </Spinner>
                  )}
                  Submit
                </button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};
