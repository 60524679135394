import {
  faPlusCircle,
  faArrowsUpDownLeftRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Spinner, Table } from "reactstrap";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Container from "../../../components/Container";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Create from "./Create";
import Delete from "./Delete";
import Edit from "./Edit";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const Index = () => {
  const [programs, setPrograms] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(programs);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    const newOrder = tempData.map((item) => item.id);
    API.post("/api/programs/order", {
      order: newOrder,
    });
    setPrograms(tempData);
  };

  const fetchPrograms = async () => {
    try {
      const data = (await API.get("/api/programs")).data.data;
      setPrograms(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrograms();
  }, [refresh]);

  return (
    <AppLayout>
      <Breadcrumbs
        title="Programs"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Programs",
            link: null,
          },
        ]}
      />

      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <CardBody>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h1 className="mb-0">All Programs</h1>
              <Create program={programs} fetchPrograms={fetchPrograms} />
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Table hover responsive>
                <thead>
                  <tr className="bg-shade-blue">
                    <th>#</th>
                    <th></th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Study Modes</th>
                    <th>Semesters</th>
                    <th>Credit Hours</th>
                    <th>Offered Campuses</th>
                    <th>Study Level</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <Droppable droppableId="droppable-1" className="w-100">
                  {(provider) => (
                    <tbody
                      className="text-capitalize"
                      ref={provider.innerRef}
                      {...provider.droppableProps}>
                      {programs.length > 0 ? (
                        programs.map((program, index) => (
                          <Draggable
                            key={program.image}
                            draggableId={program.image}
                            index={index}>
                            {(provider) => (
                              <tr
                                className="drag-box"
                                {...provider.draggableProps}
                                {...provider.dragHandleProps}
                                ref={provider.innerRef}
                                key={`program-${program.id}`}>
                                <th
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                  scope="row">
                                  {index + 1}
                                </th>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                  {...provider.dragHandleProps}>
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={
                                      faArrowsUpDownLeftRight
                                    }></FontAwesomeIcon>{" "}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}>
                                  <img
                                    style={{ width: "90px" }}
                                    src={
                                      `${process.env.REACT_APP_API_URL}` +
                                      program.image
                                    }
                                    alt="Image"
                                  />
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}>
                                  {program.name}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}>
                                  {program.study_modes.join(", ")}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}>
                                  {program.semesters}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}>
                                  {program.credit_hours}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}>
                                  {program.relationships.offeredCampus.map(
                                    (campus, index) =>
                                      index + 1 !=
                                      program.relationships.offeredCampus.length
                                        ? campus.name + ", "
                                        : campus.name
                                  )}
                                </td>

                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}>
                                  {program.study_level_id}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}>
                                  {moment(program.created_at).format(
                                    "DD/MM/YY"
                                  )}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}>
                                  {moment(program.updated_at).format(
                                    "DD/MM/YY"
                                  )}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}>
                                  <Edit
                                    program={program}
                                    fetchPrograms={fetchPrograms}
                                  />

                                  <Delete
                                    program={program}
                                    refresh={refresh}
                                    setRefresh={setRefresh}
                                  />
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={10} className="text-center">
                            {loading ? (
                              <Spinner
                                style={{ width: "30px", height: "30px" }}
                              />
                            ) : (
                              "No Programs Exists"
                            )}
                          </td>
                        </tr>
                      )}
                      {provider.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </Table>
            </DragDropContext>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};

export default Index;
