import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/modal.css";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import API from "../../../utils/API";
import { range, currentYear } from "../../../utils/helpers";

const Create = ({ fetchPayroll }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  let defaultData = {
    month: "",
    year: "",
    month_days: "",
    salary_days: "",
    absent_days: "",
    payment_method: "",
    payment_description: "",
    gross_salary: "",
    adj_lastmonth: "",
    pf_contribution: "",
    income_tax: "",
    advance: "",
    loan: "",
    other_deduction: "",
    unpaid_leaves: "",
    late_shorts: "",
    status: "",
    net_salary: "",
  };
  useEffect(() => {}, []);

  const [data, setData] = useState(defaultData);

  const toggle = () => setModal(!modal);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);

      const response = await API.post("/api/payroll", data);
      setLoading(false);

      fetchPayroll();
      toggle();
      setData(defaultData);
      setErrors([]);
    } catch (error) {
      setErrors(
        error?.response?.data?.errors ? error.response.data.errors : []
      );
      setLoading(false);
    }
  }

  const handleChange = (e) => {
    if (errors?.length) setErrors([]);
    setData({ ...data, [e?.target.name]: e?.target.value });
  };

  return (
    <div>
      <Button color="primary" className="rounded-pill" onClick={toggle}>
        <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
        Create New
      </Button>

      <Modal
        modalClassName="custommodal"
        centered
        scrollable
        size="xl"
        toggle={toggle}
        isOpen={modal}
        fade={true}
      >
        <ModalHeader toggle={toggle}>Create New Payroll</ModalHeader>
        <Form id="form" method="POST" action="#" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for="name" className="label-required">
                Month
              </Label>
              <Input
                className="rounded-2"
                id="name"
                type="select"
                name="month"
                value={data?.month}
                onChange={handleChange}
                required
                autoFocus
              >
                <option>January</option>
                <option>Feburary</option>
                <option>March</option>
                <option>April</option>
                <option>May</option>
                <option>June</option>
                <option>July</option>
                <option>August</option>
                <option>September</option>
                <option>October</option>
                <option>November</option>
                <option>December</option>
              </Input>
              {errors?.month && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.month}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="year" className="label-required">
                Year
              </Label>

              <Input
                name="year"
                type="select"
                id="year"
                onChange={handleChange}
                value={data?.year}
              >
                <option disabled>Select year</option>
                {range(1995, currentYear)
                  .sort((f, s) => s - f)
                  .map((year) => (
                    <option value={year}>{year}</option>
                  ))}
              </Input>
              {errors?.year && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.year}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="month_days" className="label-required">
                Month Days
              </Label>
              <Input
                className="rounded-2"
                id="month_days"
                type="text"
                name="month_days"
                value={data?.month_days}
                onChange={handleChange}
                required
                autoFocus
              />
              {errors?.month_days && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.month_days}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="salary_days" className="label_required">
                Salary Days
              </Label>
              <Input
                className="rounded-2"
                id="salary_days"
                type="text"
                name="salary_days"
                onChange={handleChange}
                value={data?.salary_days}
                required
                autoFocus
              />
              {errors?.salary_days && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.salary_days}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="absent_days" className="label-required">
                Absent Days
              </Label>
              <Input
                className="rounded-2"
                name="absent_days"
                type="text"
                id="absent_days"
                onChange={handleChange}
                value={data?.absent_days}
                required
                autoFocus
              />
              {errors?.absent_days && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.absent_days}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="payment_method" className="label-required">
                Payment Method
              </Label>
              <Input
                className="rounded-2"
                id="payment_method"
                name="payment_method"
                type="text"
                onChange={handleChange}
                value={data?.payment_method}
                required
                autoFocus
              />
              {errors?.payment_method && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.payment_method}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="payment_description" className="label-required">
                Payment Description
              </Label>
              <Input
                className="rounded-2"
                id="payment_description"
                name="payment_description"
                type="text"
                onChange={handleChange}
                value={data?.payment_description}
                required
                autoFocus
              />
              {errors?.payment_description && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.payment_description}
                </Label>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="gross_salary" className="label-required">
                Gross Salary
              </Label>
              <Input
                className="rounded-2"
                id="gross_salary"
                name="gross_salary"
                type="text"
                onChange={handleChange}
                value={data?.gross_salary}
                required
                autoFocus
              />
              {errors?.gross_salary && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.gross_salary}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="adj_lastmonth" className="label-required">
                Adjust Last Month
              </Label>
              <Input
                className="rounded-2"
                id="adj_lastmonth"
                name="adj_lastmonth"
                type="text"
                onChange={handleChange}
                value={data?.adj_lastmonth}
                required
                autoFocus
              />
              {errors?.adj_lastmonth && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.adj_lastmonth}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="pf_contribution" className="label-required">
                Provident Fund
              </Label>
              <Input
                className="rounded-2"
                id="pf_contribution"
                name="pf_contribution"
                type="text"
                onChange={handleChange}
                value={data?.pf_contribution}
                required
                autoFocus
              />
              {errors?.pf_contribution && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.pf_contribution}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="income_tax" className="label-required">
                Income Tax
              </Label>
              <Input
                className="rounded-2"
                id="income_tax"
                name="income_tax"
                type="text"
                onChange={handleChange}
                value={data?.income_tax}
                required
                autoFocus
              />
              {errors?.income_tax && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.income_tax}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="advance" className="label-required">
                Advance
              </Label>
              <Input
                className="rounded-0"
                id="advance"
                name="advance"
                type="text"
                onChange={handleChange}
                value={data?.advance}
                required
                autoFocus
              />
              {errors?.advance && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.advance}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="loan" className="label-required">
                Loan
              </Label>
              <Input
                className="rounded-0"
                id="loan"
                name="loan"
                type="text"
                onChange={handleChange}
                value={data?.loan}
                required
                autoFocus
              />
              {errors?.loan && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.loan}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="other_deduction">Other Deduction</Label>
              <Input
                className="rounded-0"
                id="other_deduction"
                name="other_deduction"
                type="text"
                onChange={handleChange}
                value={data?.other_deduction}
                required
                autoFocus
              />
              {errors?.other_deduction && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.other_deduction}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="unpaid_leaves">Unpaid Leaves</Label>
              <Input
                className="rounded-0"
                id="unpaid_leaves"
                name="unpaid_leaves"
                type="text"
                onChange={handleChange}
                value={data?.unpaid_leaves}
                required
                autoFocus
              />
              {errors?.unpaid_leaves && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.unpaid_leaves}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="late_shorts" className="label-required">
                Late/Shorts
              </Label>
              <Input
                className="rounded-2"
                id="late_shorts"
                name="late_shorts"
                type="text"
                onChange={handleChange}
                value={data?.late_shorts}
                required
                autoFocus
              />
              {errors?.late_shorts && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.late_shorts}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="status" className="label-required">
                Status
              </Label>
              <Input
                className="rounded-2"
                id="status"
                name="status"
                type="text"
                onChange={handleChange}
                value={data?.status}
                required
                autoFocus
              />
              {errors?.status && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.status}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="net_salary" className="label-required">
                Net Salary
              </Label>
              <Input
                className="rounded-2"
                id="net_salary"
                name="net_salary"
                type="text"
                onChange={handleChange}
                value={data?.net_salary}
                required
                autoFocus
              />
              {errors?.net_salary && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.net_salary}
                </Label>
              )}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggle}
              color="light"
              className="rounded-0"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="rounded-0"
              disabled={loading}
            >
              {loading && (
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              )}
              Submit
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Create;
