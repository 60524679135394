import {
  SET_AUTH_ERRORS,
  LOGIN,
  LOGOUT,
  CLEAR_AUTH_ERRORS,
  SET_AUTH_LOADER,
  CLEAR_AUTH_LOADER,
} from "../constants/actionTypes";

const initialState = { authData: null, error: null, loading: true };

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN:
      return { ...state, authData: payload, error: null, loading: false };
    case SET_AUTH_ERRORS:
      return { ...state, error: "Invalid Credentials", loading: false };
    case CLEAR_AUTH_ERRORS:
      return { ...state, error: null, loading: false };
    case SET_AUTH_LOADER:
      return { ...state, loading: true };
    case CLEAR_AUTH_LOADER:
      return { ...state, loading: false };
    case LOGOUT:
      return { ...state, authData: null, loading: false, error: null };
    default:
      return state;
  }
};

export default reducer;
