import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FormGroup, Input, Label, Spinner } from "reactstrap";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Container from "../../../components/Container";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Create from "./Create";

const Timetable = () => {
  const [timetable, setTimetable] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [campuses, setCampuses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [selectedCampus, setSelectedCampus] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [days, setDays] = useState([]);
  const [timeslots, setTimeslots] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);

  const fetchDropdownData = async () => {
    const [campuses, departments, sessions] = await Promise.all([
      API.get("/api/campuses"),
      API.get("/api/departments"),
      API.get("/api/sessions"),
    ]);

    setCampuses(campuses.data.data);
    setDepartments(departments.data.data);
    setSessions(sessions.data.data);
  };

  const fetchData = async () => {
    setLoading(true);
    setDays([]);
    const [days, timeslots, rooms, timetable] = await Promise.all([
      API.get("/api/days?is_active=1"),
      API.get("/api/time-slots?is_active=1"),
      API.get(`/api/rooms?is_active=1&campus_id=${selectedCampus}`),
      API.get(
        `/api/timetable?session_id=${selectedSession}&campus_id=${selectedCampus}`
      ),
    ]);
    setLoading(false);
    setDays(days.data.data);
    setTimeslots(timeslots.data.data);
    setRooms(rooms.data.data);

    const newTimetable = {};

    timetable.data.data.forEach((offeredCourse) => {
      newTimetable[
        `${offeredCourse.relationships.detail.day_id}-${offeredCourse.relationships.detail.timeslot_id}-${offeredCourse.relationships.detail.room_id}`
      ] = {
        timetable_id: offeredCourse.id,
        courseName: offeredCourse.relationships.course.name,
        teacherName: offeredCourse.relationships.employee.name,
        program: offeredCourse.relationships.program.name,
        semester: offeredCourse.semester,
        sectionName: offeredCourse.relationships.section.name,
      };
    });

    setTimetable(newTimetable);
  };

  const addCourse = (day, timeslot, room) => {
    // Show modal with a form where user selects a course, a teacher, a section, a semester field, a program
    setSelectedDay(day);
    setSelectedTimeslot(timeslot);
    setSelectedRoom(room);
    setShowModal(true);
  };

  const deleteCourse = async (id) => {
    try {
      setLoading(true);
      await API.delete(`/api/timetable/${id}`);
      toast("Timetable entry deleted successfully!");
      fetchData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    if (e.target.name == "campus_id") {
      setSelectedCampus(e.target.value);
    }
    if (e.target.name == "department_id") {
      setSelectedDepartment(e.target.value);
    }
    if (e.target.name == "session_id") {
      setSelectedSession(e.target.value);
    }
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);

  useEffect(() => {
    if (selectedSession && selectedCampus && selectedDepartment) {
      fetchData();
    }
  }, [selectedSession, selectedCampus, selectedDepartment, refresh]);

  return (
    <>
      <AppLayout>
        <Breadcrumbs
          title="Timetable"
          links={[
            {
              title: "Dashboard",
              link: null,
            },
            {
              title: "Timetable",
              link: null,
            },
          ]}
        />
        <Container>
          <div className="mt-4">
            <div className="row">
              <div className="col-md-4">
                <FormGroup>
                  <Label for="campus_id">Campuses</Label>
                  <Input
                    className="rounded-2"
                    id="campus_id"
                    required
                    type="select"
                    name="campus_id"
                    onChange={handleChange}>
                    <option value="">Select Campus</option>
                    {campuses.map((campus) => (
                      <option key={campus.id} value={campus.id}>
                        {campus.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label for="department_id">Departments</Label>
                  <Input
                    className="rounded-2"
                    id="department_id"
                    required
                    type="select"
                    name="department_id"
                    onChange={handleChange}>
                    <option value="">Select Department</option>
                    {departments.map((department) => (
                      <option key={department.id} value={department.id}>
                        {department.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label for="session_id">Sessions</Label>
                  <Input
                    className="rounded-2"
                    id="session_id"
                    required
                    type="select"
                    name="session_id"
                    onChange={handleChange}>
                    <option value="">Select Session</option>
                    {sessions.map((session) => (
                      <option key={session.id} value={session.id}>
                        {session.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </div>
            </div>

            {selectedCampus &&
              selectedDepartment &&
              selectedSession &&
              (days.length > 0 ? (
                days.map((day) => (
                  <section className="card mb-3 border-0 shadow">
                    <div className="card-body">
                      <div className="card-header">
                        <h3 className="text-center mb-0 py-2">{day.days}</h3>
                      </div>
                      <table
                        className="table table-bordered"
                        style={{
                          overflowX: "scroll",
                          width: "100%",
                          display: "block",
                        }}>
                        <tbody>
                          <tr>
                            <th
                              scope="column"
                              rowSpan={2}
                              className="align-middle text-center table-primary">
                              Timelots
                            </th>
                            <th
                              scope="column"
                              colSpan={rooms.length}
                              style={{ width: "85%" }}>
                              Rooms
                            </th>
                          </tr>
                          <tr>
                            {rooms.map((room) => (
                              <th>{room.name}</th>
                            ))}
                          </tr>
                          {timeslots.map((timeslot) => (
                            <tr
                              style={{ height: "100px" }}
                              className="align-middle text-center">
                              <th scope="row">
                                {moment(timeslot.start, "HH:mm").format(
                                  "hh:mma"
                                )}{" "}
                                -{" "}
                                {moment(timeslot.end, "HH:mm").format("hh:mma")}
                              </th>
                              {rooms.map((room) => (
                                <td
                                  style={{
                                    cursor: "pointer",
                                    padding: "0",
                                    position: "relative",
                                  }}
                                  className="text-center align-middle border-dotted">
                                  {timetable[
                                    `${day.id}-${timeslot.id}-${room.id}`
                                  ] ? (
                                    <div>
                                      <button
                                        onClick={() =>
                                          deleteCourse(
                                            timetable[
                                              `${day.id}-${timeslot.id}-${room.id}`
                                            ].timetable_id
                                          )
                                        }
                                        className="btn btn-secondary rounded-0 btn-sm"
                                        style={{
                                          position: "absolute",
                                          top: "8px",
                                          right: "8px",
                                        }}>
                                        <FontAwesomeIcon
                                          icon={faTimes}></FontAwesomeIcon>
                                      </button>
                                      <h3 className="h6">
                                        {
                                          timetable[
                                            `${day.id}-${timeslot.id}-${room.id}`
                                          ].courseName
                                        }
                                      </h3>
                                      <small>
                                        {
                                          timetable[
                                            `${day.id}-${timeslot.id}-${room.id}`
                                          ].teacherName
                                        }
                                        (
                                        {
                                          timetable[
                                            `${day.id}-${timeslot.id}-${room.id}`
                                          ].sectionName
                                        }
                                        )
                                      </small>
                                    </div>
                                  ) : (
                                    <p
                                      className="mb-0 timetable_hover"
                                      style={{
                                        padding: "70px 40px",
                                        width: "210px",
                                      }}
                                      onClick={() =>
                                        addCourse(day, timeslot, room)
                                      }>
                                      Click to Add
                                    </p>
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </section>
                ))
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    {loading ? (
                      <Spinner style={{ width: "30px", height: "30px" }} />
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
          </div>
        </Container>
      </AppLayout>

      <Create
        fetchData={fetchData}
        selectedTimeslot={selectedTimeslot}
        selectedRoom={selectedRoom}
        selectedDay={selectedDay}
        selectedCampus={selectedCampus}
        selectedSession={selectedSession}
        selectedDepartment={selectedDepartment}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default Timetable;
