import React, { useState,useEffect } from 'react';
import Breadcrumbs from '../../../components/Breadcrumbs';
import AppLayout from '../../../components/layouts/AppLayout'
import Container from "../../../components/Container";
import { Card, CardBody, Table,Spinner } from 'reactstrap';
import API from '../../../utils/API';
import Create from './CreateEmployee';
import Delete from './DeleteEmployee';
import Edit from './EditEmployee';
import { Link, useNavigate } from 'react-router-dom';

const EmployeesData = () => {

  const [employees, setEmployees] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const toggle = () => {
    setShowModal(!showModal);
  };


  const fetchEmployees = async () => {
    try {
      const data = (await API.get(`/api/employee`)).data?.data;
      setEmployees(data);
      setLoading(true)
    } catch (error) {
      setLoading(false)
    }
  };

 

  useEffect(() => {
    fetchEmployees();
  }, [refresh])
  
  return (
    <AppLayout>
         <Breadcrumbs
        title="Employees"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Employees",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3 d-flex align-items-center justify-content-between border-bottom">
            <h1 className="mb-0">Employees</h1>        
           <Create
            EmployeesData={employees}
            fetchEmployees={fetchEmployees}
            />
            </div>
            <CardBody>
            <Table hover responsive>
              <thead>
                <tr className="bg-shade-blue">
                  <th>#</th>
                  <th>Employee Name</th>
                  <th>Employee Id</th>
                  <th>Email</th>
                  <th>CNIC</th>
                  <th>Contact Number</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {employees.length > 0 ? (employees?.map((employee, index) => (
                  <tr className='table-row' key={`employee-${employee?.id}`}>
                    <th scope="row">{index + 1}</th>
                    <td>{employee?.name}</td>
                    <td>{employee?.employee_id}</td>
                    <td>{employee?.email}</td>
                    <td>{employee?.nic}</td>
                    <td>
                      
                          {employee?.phone_number}
                       
                    </td>
                
                    <td>
                     <div className='w-100'>
                     <Link to={`/employee/${employee?.id}`} className="detail-btn"><span className='btn btn-sm btn-primary d-block w-100 mb-2'>View</span></Link>
                     <Edit
                     employee={employee}
                     fetchEmployees={fetchEmployees}
                     />
                     </div>
                     <div className='mt-2'>
                     <Delete
                          employee={employee}
                          refresh={refresh}
                          setRefresh={setRefresh}
                        />
                     </div>
                    </td>
                  </tr>
                ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {loading ? (
                        <Spinner style={{ width: "30px", height: "30px" }} />
                      ) : (
                        "No Employees Exists"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </CardBody>
            </Card>
            </Container>
    </AppLayout>
  )
}

export default EmployeesData;




