import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../utils/API";
import "../../css/vouchers.css";
import moment from "moment";
import FullscreenLoader from "../../components/FullscreenLoader";
import { numberFormat } from "../../utils/helpers";

const Show = () => {

  const [show,setShow]=useState(true);

  let url = window.location.href;

  let copies=[];
  if(url.includes('?')){
    copies = ["Bank Copy", "Accounts Copy", "Student Copy"];
  }else{
  copies = ["Bank Copy", "Accounts Copy", "Student Copy"];
  }



  const params = useParams();
  const [voucher, setVoucher] = useState([]);
  const [loading, setLoading] = useState(true);

  

  const fetchVoucher = async () => {
    try {
      const data = (await API.get(`/api/vouchers/${params.voucherId}`)).data
        .data;
      setVoucher(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVoucher();
  }, []);

  if (loading) return <FullscreenLoader />;

  return (
    <div>
      <div className="container">
        <div className="card shadow mt-3 mb-5">
          <div className="card-body py-md-4 px-md-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-9">
                <div className="h2 font-raleway text-blue fw-bold">
                  Pay {voucher.fee_period}
                </div>
                Please submit the voucher below at any nearest
                {voucher.relationships.chargeable.campus_id == 2
                  ? "JS Bank"
                  : "Bank Alfalah"}{" "}
                Branch. Once fee is submitted you will get acknowledgement
                within 24 hours.
              </div>
              <div className="col-md-3 text-end">
                <button
                  className="btn btn-danger btn-lg animate__animated animate__pulse animate__infinite btn-lg rounded-0 shadow"
                  onClick={() => window.print()}
                >
                  Print Challan
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          id="challan-form"
          className="d-flex align-items-center justify-content-center py-5 mb-5 border"
          style={{ overflow: "auto" }}
        >
          {copies.map((copy, index) => (
            <div
              style={{ fontSize: "11px", minWidth: "420px" }}
              className="px-4 challan-copy challans"
              key={`copy-${index}`}
            >
              <div className="text-center">
                <img
                  src="http://localhost:5000/images/vouchers/logo.svg"
                  width="75px"
                  className="d-block mb-1 mx-auto"
                  alt=""
                />
                <div className="text-dark-blue h5 fw-bold mb-0">
                  University of South Asia
                </div>
                <small
                  className="small mb-1 d-block"
                  style={{ fontSize: "9px" }}
                >
                  (The project of Gateway Technologies Pvt. Ltd.)
                </small>

                {
                    url.includes('?')? 

                <small className="mb-0 small" id="bank-details" style={{ fontSize: "10px" }}>
                  {/* Transaction to be posted through
                  {voucher.relationships.chargeable.campus_id == "2"
                    ? "JS"
                    : "Alfalah"}{" "}
                  Connect only */}
                </small>
                :
                <small className="mb-0 small" id="bank-details" style={{ fontSize: "10px" }}>
                  {/* Transaction to be posted through
                  {voucher.relationships.chargeable.campus_id == "2"
                    ? "JS"
                    : "Alfalah"}{" "}
                  Connect only */}
                </small>
                }

                <div className="d-flex align-items-center justify-content-between my-2">
                  <p className="fw-bold mb-0">Voucher #{voucher.id}</p>
                  {/* <p className="fw-bold mb-0">Company Code: USA</p>  */}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div>
                  {/* {voucher.relationships.chargeable.campus_id == "2" ? (
                    <img
                      src="http://localhost:5000/images/vouchers/js-bank.svg"
                      width="100px"
                      alt=""
                    />
                  ) : (
                    <img
                      src="http://localhost:5000/images/vouchers/bank-alfalah.png"
                      width="100px"
                      alt=""
                    />
                  )} */}
                </div>
                {/* <div className="text-right" style={{ fontSize: "11px" }}>
                  Issue Date:{" "}
                  <span style={{ textDecoration: "underline" }}>
                    {moment(voucher?.created_at).format("DD/MM/YY")}
                  </span>
                </div> */}
              </div>
              <div className="row mb-1" style={{ fontWeight: 400 }}>
                <div className="col-4">Name:</div>
                <div className="border-bottom border-dark col-8">
                  {voucher.relationships.chargeable.name}
                </div>
              </div>
              <div className="row mb-1" style={{ fontWeight: 400 }}>
                <div className="col-4">Reg. No:</div>
                <div className="border-bottom border-dark col-8">
                  A-{voucher.relationships.chargeable.id}
                </div>
              </div>
              <div className="row mb-1" style={{ fontWeight: 400 }}>
                <div className="col-4">Program: </div>
                <div className="border-bottom border-dark col-8">
                  {voucher.relationships.chargeable.relationships.program?.name}
                </div>
              </div>
              <div className="row mb-1" style={{ fontWeight: 400 }}>
                <div className="col-6">Dues for Period: </div>
                <div className="border-bottom border-dark col-6">
                  {voucher.fee_period}
                </div>
              </div>
              <div className="bg-dark-blue rounded-pill px-4 py-2 mt-4 text-white">
                <div className="fw-bold mb-0">FEE</div>
              </div>
              <div className="card mt-3 border-dark rounded-0 bg-transparent">
                <div className="card-body mb-0 px-2 py-2">
                  {Object.keys(voucher.particulars).map((particular) => (
                    <div className="border-dark">
                      <div className="row">
                        {Object.keys(voucher.particulars[particular]).map(
                          (item, index) => (
                            <>
                              <div className="col-7 fw-bold">{item}</div>
                              <div className="col-5 text-end">
                                Rs.{" "}
                                <strong>
                                  {numberFormat(
                                    voucher.particulars[particular][item]
                                  )}
                                </strong>
                              </div>
                            </>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="border border-dark-blue text-white bg-dark-blue px-2 py-2 position-relative mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fw-bold">Total</div>
                  <div className="fw-bold">
                    Rs. {numberFormat(voucher.total)}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="text-left">
                  <div className="small fw-bold">Due Date</div>
                  {moment(voucher.due_date).format("DD/MM/YY")}
                </div>
                <div className="text-right">
                  <div className="small fw-bold">Expiry Date</div>
                  {moment(voucher.expiry_date).format("DD/MM/YY")}
                </div>
              </div>
              <div className="bg-dark-blue rounded-pill px-4 mt-3 py-2 text-white">
                <div className="fw-bold mb-0">Instructions</div>
              </div>
              {voucher.fee_type === "Semester Fee" ? (
                <ol className="p-0 ml-5 mt-3">
                  <li className="font-size: 11px;">
                    Fine of Rs. 500 will be charged per day after due date
                  </li>
                  <li className="font-size: 11px;">
                    Your admission will be cancelled after expiry date.
                  </li>
                </ol>
              ) : (
                <ol className="p-0 ml-5 mt-3">
                  <li className="font-size: 11px;">
                    Admission is conditional to the payment of this fee voucher
                    within the due date.
                  </li>
                  <li className="font-size: 11px;">
                    Your admission will be cancelled after expiry date.
                  </li>
                </ol>
              )}
              <div className="text-right">
                <div className="text-secondary fw-bold h3 my-3">{copy}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};



export default Show;
