import React, { useEffect, useState } from "react";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  Card,
  CardBody,
  Container,
  Spinner,
  Table,
  Row,
  Col,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";

export const Index = () => {
  const [loading, setLoading] = useState(false);
  const paramData = useParams();

  const [allAttendance, setAllAttendance] = useState([]);
  const fetchAllAttendances = async () => {
    try {
      setLoading(false);
      const response = (await API.get(`/api/attendence/${paramData.id}`)).data
        .data;
      setLoading(true);
      setAllAttendance(response);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllAttendances();
  }, []);

  return (
    <AppLayout>
      <Breadcrumbs
        title="Student Attendance"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Student Attendance",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3 d-flex align-items-center justify-content-between border-bottom">
            <h1 className="mb-0">Student Attendance</h1>
            <Link to={`/mark-attendance/${paramData.id}`}>
              <button className="btn btn-primary">Mark Attendance</button>
            </Link>
          </div>
          <CardBody>
            <Row className="d-none">
              <Col md={8}>
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  {allAttendance.map((detail, index) => (
                    <option selected>{detail.topic}</option>
                  ))}
                </select>
              </Col>

              <Col md={4}>
                <input
                  type="date"
                  className="form-control"
                  id=""
                  placeholder="Type..."
                />
              </Col>
            </Row>

            <Table hover responsive border className="mt-3">
              <thead>
                <tr className="bg-shade-blue">
                  <th>Date</th>
                  <th>Topic Covered</th>
                  <th>Present</th>
                  <th>Absent</th>
                  <th>Leave</th>
                  <th>Enrolled</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allAttendance.length > 0 ? (
                  allAttendance.map((detail, index) => (
                    <tr>
                      <td>{detail.date}</td>
                      <td>{detail.topic}</td>
                      <td>{detail.present}</td>
                      <td>{detail.absent}</td>
                      <td>{detail.leave}</td>

                      <td>{detail.total_enrollment}</td>
                      <td>
                        <Link
                          state={{
                            topic: detail?.topic,
                            status: detail?.status,
                          }}
                          to={`/student-attendance/view/${detail?.timetable_id}/${detail?.date}`}
                        >
                          <button className="btn btn-primary btn-sm">
                            View
                          </button>
                        </Link>{" "}
                        {""}
                        <Link
                          state={{ topic: detail?.topic }}
                          to={`/student-attendance/edit/${detail?.timetable_id}/${detail?.date}`}
                        >
                          <button className="btn btn-primary btn-sm">
                            Edit
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {loading ? (
                        <span>No record found</span>
                      ) : (
                        <div className="d-flex justify-content-center">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};
