import "../../../css/modal.css";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import API from "../../../utils/API";
import axios from "axios";

const Edit = ({ studyLevel, fetchStudyLevels }) => {
  // Modal open state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const defaultData = {
    name: "",
    excerpt: "",
    description: "",
    thumbnail: "",
    cover_image: "",
    cover_video: "",
    programs: "",
  };
  const [data, setData] = useState(defaultData);

  useEffect(() => {
    setData(studyLevel);
  }, [studyLevel]);

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData(document.getElementById("edit-form"));

      formData.set(
        "image",
        formData.get("image") ? formData.get("image") : data.image
      );

      formData.set(
        "video",
        formData.get("video") ? formData.get("video") : data.video
      );

      formData.append("_method", "PATCH");

      const response = await API.post(
        `/api/study-levels/${data.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setLoading(false);
      fetchStudyLevels();
      toggle();
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.value });
  };
  function handleChangeImage(e) {
    setData({ ...data, image: e.target.files[0] });
  }
  function handleChangeVideo(e) {
    setData({ ...data, cover_video: e.target.files[0] });
  }

  return (
    <div>
      <Button
        color="primary"
        className="btn btn-sm btn-primary d-block w-100 mb-2"
        onClick={toggle}>
        Edit
      </Button>

      <Modal
        modalClassName="custommodal"
        centered
        scrollable
        size="xl"
        toggle={toggle}
        isOpen={modal}
        fade={true}>
        <ModalHeader toggle={toggle}>Update Study Level</ModalHeader>
        <Form id="edit-form" method="POST" action="#" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for="name" className="label-required">
                Name
              </Label>
              <Input
                className="rounded-2"
                id="name"
                type="name"
                name="name"
                maxLength={255}
                value={data.name}
                onChange={handleChange}
                required
                autoFocus
              />
              {errors.name && (
                <Label className="text-danger mt-2" for="name">
                  {errors.name}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="excerpt" className="label-required">
                Excerpt
              </Label>
              <Input
                className="rounded-2"
                id="excerpt"
                type="textarea"
                rows={6}
                name="excerpt"
                maxLength={255}
                value={data.excerpt}
                onChange={handleChange}
                required></Input>
            </FormGroup>

            <FormGroup>
              <Label for="description" className="label-required">
                Description
              </Label>
              <Input
                className="rounded-2"
                id="description"
                type="textarea"
                name="description"
                maxLength={255}
                rows={6}
                value={data.description}
                onChange={handleChange}
                required
              />
            </FormGroup>

            {data.thumbnail ? (
              <img
                src={`${process.env.REACT_APP_API_URL}` + data.thumbnail}
                alt="image"
                className="studylevel-img mb-3 mt-3"
              />
            ) : (
              ""
            )}

            <FormGroup>
              <Label for="thumbnail" className="label-required">
                Image
              </Label>

              <Input
                className="rounded-2"
                id="thumbnail"
                type="file"
                name="thumbnail"
                maxLength={255}
                onChange={handleChangeImage}
              />
              {errors.thumbnail && (
                <Label className="text-danger mt-2" for="name">
                  {errors.thumbnail}
                </Label>
              )}
            </FormGroup>

            {data.cover_image ? (
              <img
                src={`${process.env.REACT_APP_API_URL}` + data.cover_image}
                alt="image"
                className="studylevel-img mb-3 mt-3"
              />
            ) : (
              ""
            )}
            <FormGroup>
              <Label for="cover_image" className="label-required">
                Cover Image
              </Label>
              <Input
                className="rounded-2"
                id="cover_image"
                type="file"
                name="cover_image"
                maxLength={255}
                onChange={handleChangeImage}
              />
              {errors.cover_image && (
                <Label className="text-danger mt-2" for="name">
                  {errors.cover_image}
                </Label>
              )}
            </FormGroup>

            {data.cover_video ? (
              <video className="mb-3 mt-3" width="320" height="240" controls>
                <source
                  src={`${process.env.REACT_APP_API_URL}` + data.cover_video}
                />
              </video>
            ) : (
              ""
            )}

            <FormGroup>
              <Label for="cover_video" className="label-required">
                Cover Video
              </Label>
              <Input
                className="rounded-2"
                id="cover_video"
                type="file"
                name="cover_video"
                maxLength={255}
                onChange={handleChangeVideo}
              />
              {errors.cover_video && (
                <Label className="text-danger mt-2" for="name">
                  {errors.cover_video}
                </Label>
              )}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggle}
              color="light"
              className="rounded-2">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="rounded-2"
              disabled={loading}>
              {loading && (
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              )}
              Update
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Edit;
