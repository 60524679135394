import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const StatCard = ({ icon, stat, title }) => {
  return (
    <div className="card border-0 shadow mt-4">
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-center">
          <div>
            <div
              className="bg-secondary d-flex align-items-center justify-content-center rounded-circle"
              style={{ width: "100px", height: "100px" }}
            >
              <FontAwesomeIcon
                icon={icon}
                className="text-light-blue"
                style={{ fontSize: "40px" }}
              ></FontAwesomeIcon>
            </div>
          </div>
          <div className="ps-4">
            <div className="fw-bold h1 mb-0">{stat}</div>
            <div className="h5 mb-0 text-muted">{title}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatCard;
