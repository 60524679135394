import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Spinner, Table } from "reactstrap";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Container from "../../../components/Container";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Create from "./Create";
import Delete from "./Delete";
import Edit from "./Edit";

const Index = () => {
  const [timeSlots, setTimeSlots] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchTimeSlots = async () => {
    try {
      const data = (await API.get("/api/time-slots")).data.data;
      setTimeSlots(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTimeSlots();
  }, [refresh]);

  return (
    <AppLayout>
      <Breadcrumbs
        title="Timeslots"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Timeslots",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <CardBody>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h1 className="mb-0">All Timeslots</h1>
              <Create timeslot={timeSlots} fetchTimeSlots={fetchTimeSlots} />
            </div>
            <Table hover responsive>
              <thead>
                <tr className="bg-shade-blue">
                  <th>#</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Active</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {timeSlots.length > 0 ? (
                  timeSlots.map((timeSlot, index) => (
                    <tr
                      key={`timeSlot-${timeSlot.id}`}
                      style={{ verticalAlign: "middle" }}>
                      <th scope="row">{index + 1}</th>

                      <td>
                        {moment(timeSlot.start, "hh:mm a").format("hh:mm a")}
                        {/* {timeSlot.start} */}
                      </td>

                      <td>
                        {moment(timeSlot.end, "hh:mm a").format("hh:mm a")}
                        {/* {timeSlot.end} */}
                      </td>
                      {timeSlot.is_active == 1 ? <td>Yes</td> : <td>No</td>}
                      <td>
                        <Edit
                          timeSlot={timeSlot}
                          fetchTimeSlots={fetchTimeSlots}
                        />
                        <Delete
                          timeSlot={timeSlot}
                          refresh={refresh}
                          setRefresh={setRefresh}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {loading ? (
                        <Spinner
                          className="text-center"
                          style={{ width: "30px", height: "30px" }}
                        />
                      ) : (
                        "No TimeSlots Exists"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};

export default Index;
