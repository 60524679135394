import "../../../css/modal.css";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import API from "../../../utils/API";
import axios from "axios";

const Edit = ({ course, fetchCourses }) => {
  // Modal open state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [prerequisites, setPrerequisites] = useState([]);

  const [data, setData] = useState({
    name: "",
    department_id: "",
    prerequisite_course_id: "",
    course_code: "",
    credit_hours: "",
    is_active: 0,
  });

  useEffect(() => {
    setData(course);
  }, []);

  useEffect(() => {
    fetchDepartments();
  }, []);

  useEffect(() => {
    fetchPrerequisites();
  }, []);

  const fetchDepartments = async () => {
    try {
      setLoading(true);
      const response = (await API.get("/api/departments")).data;
      setDepartments(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchPrerequisites = async () => {
    try {
      setLoading(true);
      const response = (await API.get("/api/courses")).data;
      setPrerequisites(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await API.put(`api/courses/${data.id}`, data);
      setLoading(false);
      fetchCourses();
      toggle();
      setErrors([]);
    } catch (error) {
      setErrors(
        error?.response?.data?.errors ? error.response.data.errors : []
      );
      setLoading(false);
    }
  }

  const handleChangeCheckbox = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.checked ? 1 : 0 });
  };
  const handleChange = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Button
        color="primary"
        className="btn btn-sm btn-primary d-block w-100 mb-2"
        onClick={toggle}>
        Edit
      </Button>

      <Modal
        modalClassName="custommodal"
        centered
        scrollable
        size="xl"
        toggle={toggle}
        isOpen={modal}
        fade={true}>
        <ModalHeader toggle={toggle}>Update Course</ModalHeader>
        <Form method="POST" action="#" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for="name" className="label-required">
                Name
              </Label>
              <Input
                className="rounded-2"
                id="name"
                type="text"
                name="name"
                maxLength={255}
                value={data.name}
                onChange={handleChange}
                required
                autoFocus
              />
              {errors.name && (
                <Label className="text-danger mt-2" for="name">
                  {errors.name}
                </Label>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="course_code" className="label-required">
                Course Code
              </Label>
              <Input
                className="rounded-2"
                id="course_code"
                type="text"
                name="course_code"
                maxLength={255}
                value={data.course_code}
                onChange={handleChange}
                required
                autoFocus
              />
              {errors.course_code && (
                <Label className="text-danger mt-2" for="name">
                  {errors.course_code}
                </Label>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="department_id">Department</Label>
              <Input
                className="rounded-2"
                id="department_id"
                required
                type="select"
                name="department_id"
                value={data.department_id}
                onChange={handleChange}>
                <option value="">Select Department</option>
                {departments.map((demartment) => (
                  <option key={demartment.id} value={demartment.id}>
                    {demartment.name}
                  </option>
                ))}
              </Input>
              {errors.department_id && (
                <Label className="text-danger mt-2" for="name">
                  {errors.department_id}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="prerequisite_course_id">Prerequisite Course</Label>
              <Input
                className="rounded-2"
                id="prerequisite_course_id"
                type="select"
                name="prerequisite_course_id"
                value={data.prerequisite_course_id}
                onChange={handleChange}>
                <option value="">Select Prerequisite Course</option>
                {prerequisites.map((prerequisite) => (
                  <option key={prerequisite.id} value={prerequisite.id}>
                    {prerequisite.name}
                  </option>
                ))}
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="credit_hours" className="label-required">
                Credit Hours
              </Label>
              <Input
                className="rounded-2"
                id="credit_hours"
                type="number"
                name="credit_hours"
                maxLength={255}
                value={data.credit_hours}
                onChange={handleChange}
                required
                autoFocus
              />
            </FormGroup>

            <FormGroup>
              <Label for="is_active" className="label-required">
                Active
              </Label>
              <Input
                className="rounded-2"
                id="is_active"
                type="checkbox"
                name="is_active"
                maxLength={255}
                value={data.is_active}
                checked={data.is_active}
                onChange={handleChangeCheckbox}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggle}
              color="light"
              className="rounded-2">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="rounded-2"
              disabled={loading}>
              {loading && (
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              )}
              Update
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Edit;
