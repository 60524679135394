import React, { useEffect, useState } from "react";
import "../../../css/modal.css";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import API from "../../../utils/API";

const Edit = ({ employee, fetchEmployees }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designations, setDeignations] = useState([]);
  const [campuses, setCampuses] = useState([]);
  let defaultData = {
    name: "",
    father_name: "",
    department_id: "",
    designation_id: "",
    marital_status: "",
    email: "",
    nic: "",
    initial_salary: "",
    gender: "",
    joining_date: "",
    leaving_date: "",
    current_address: "",
    permanent_address: "",
    shift_start_time: "",
    status: "",
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0,
    shift_end_time: 0,
    working_hours: 0,
    is_working: 0,
    is_visiting: 0,
    is_active: 0,
    is_faculty: 0,
  };

  const [data, setData] = useState(defaultData);

  useEffect(() => {
    setData(employee);
    fetchDepartments();
    fetchDesignations();
    fetchCampuses();
  }, []);

  const toggle = () => setModal(!modal);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);

      const response = await API.put(`/api/employee/${data.id}`, data);
      setLoading(false);
      fetchEmployees();
      toggle();
      setData(defaultData);
      setErrors([]);
    } catch (error) {
      setErrors(
        error?.response?.data?.errors ? error.response.data.errors : []
      );
      setLoading(false);
    }
  }

  const handleChange = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e?.target?.name]: e?.target?.value });
  };
  const handleChangeCheckbox = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e?.target?.name]: e?.target?.checked ? 1 : 0 });
  };
  const fetchDepartments = async () => {
    try {
      const data = (await API.get("/api/department")).data.data;
      setDepartments(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchDesignations = async () => {
    try {
      const data = (await API.get("/api/designation")).data?.data;
      setDeignations(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchCampuses = async () => {
    try {
      const data = (await API.get("/api/campuses")).data?.data;
      setCampuses(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <button
        className="btn btn-sm btn-primary d-block w-100 mb-2"
        onClick={toggle}
      >
        Edit
      </button>

      <Modal
        modalClassName="custommodal"
        centered
        scrollable
        size="xl"
        toggle={toggle}
        isOpen={modal}
        fade={true}
      >
        <ModalHeader toggle={toggle}>Edit Employee</ModalHeader>
        <Form id="form" method="POST" action="#" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for="name" className="label-required">
                Name
              </Label>
              <Input
                className="rounded-2"
                id="name"
                type="name"
                name="name"
                maxLength={255}
                value={data?.name}
                onChange={handleChange}
                required
                autoFocus
              />
              {errors?.name && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.name}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="type" className="label-required">
                Father Name
              </Label>
              <Input
                className="rounded-0"
                id="father_name"
                type="father_name"
                name="father_name"
                maxLength={255}
                value={data?.father_name}
                onChange={handleChange}
                required
              >
                {errors?.father_name && (
                  <Label className="text-danger mt-2" for="father_name">
                    {errors?.father_name}
                  </Label>
                )}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="email" className="label-required">
                Email
              </Label>
              <Input
                className="rounded-0"
                id="email"
                type="email"
                name="email"
                value={data?.email}
                onChange={handleChange}
                required
              />
              {errors?.email && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.email}
                </Label>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="department_id">Department</Label>
              <Input
                className="rounded-2"
                id="department_id"
                required
                type="select"
                name="department_id"
                value={data?.department_id}
                onChange={handleChange}
              >
                <option value="">Select Department</option>
                {departments?.map((department) => (
                  <option key={department?.id} value={department?.id}>
                    {department?.name}
                  </option>
                ))}
              </Input>
              {errors?.department_id && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.department_id}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="designation_id">Designation</Label>
              <Input
                className="rounded-2"
                id="designation_id"
                required
                type="select"
                name="designation_id"
                value={data?.designation_id}
                onChange={handleChange}
              >
                <option value="">Select Designation</option>
                {designations?.map((designation) => (
                  <option key={designation?.id} value={designation?.id}>
                    {designation?.name}
                  </option>
                ))}
              </Input>
              {errors?.designation_id && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.designation_id}
                </Label>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="marital_status" className="label-required">
                Marital Status
              </Label>
              <Input
                className="rounded-0"
                id="marital_status"
                name="marital_status"
                type="select"
                value={data?.marital_status}
                onChange={handleChange}
                required
              >
                <option>Select Marital Status</option>
                <option>Married</option>
                <option>Unmarried</option>
              </Input>
              {errors?.marital_status && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.marital_status}
                </Label>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="nic">CNIC</Label>
              <Input
                className="rounded-0"
                id="nic"
                required
                type="nic"
                name="nic"
                value={data?.nic}
                onChange={handleChange}
              ></Input>
              {errors?.nic && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.nic}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="initial_salary" className="label-required">
                Initial Salary
              </Label>
              <Input
                id="salary"
                name="initial_salary"
                type="text"
                required
                value={data?.initial_salary}
                onChange={handleChange}
              >
                {errors?.initial_salary && (
                  <Label className="text-danger mt-2" for="name">
                    {errors?.initial_salary}
                  </Label>
                )}
              </Input>
            </FormGroup>
            
            <FormGroup>
              <Label for="gender" className="label-required">
                Gender
              </Label>
              <Input
                className="rounded-0"
                id="gender"
                type="select"
                name="gender"
                value={data?.gender}
                onChange={handleChange}
              >
                <option>Select Gender</option>
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </Input>
              {errors?.gender && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.gender}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="joining_date">Joining Date</Label>
              <Input
                className="rounded-0"
                id="joining_date"
                type="date"
                name="joining_date"
                value={data?.joining_date}
                onChange={handleChange}
              />
              {errors?.joining_date && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.joining_date}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="leaving_date">Leaving Date</Label>
              <Input
                className="rounded-0"
                id="leaving_date"
                type="date"
                name="leaving_date"
                value={data?.leaving_date}
                onChange={handleChange}
              />
              {errors?.leaving_date && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.leaving_date}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="current_address">Current Address</Label>
              <Input
                className="rounded-0"
                id="current_address"
                type="current_address"
                name="current_address"
                maxLength={255}
                value={data?.current_address}
                onChange={handleChange}
              />
              {errors?.current_address && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.current_address}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="permanent_address" className="label-required">
                Permanent Address
              </Label>
              <Input
                className="rounded-0"
                id="permanent_address"
                type="permanent_address"
                name="permanent_address"
                value={data?.permanent_address}
                onChange={handleChange}
                required
              />
              {errors?.permanent_address && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.permanent_address}
                </Label>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="shift_start_time" className="label-required">
                Shift Start Time
              </Label>
              <Input
                className="rounded-0"
                id="shift_start_time"
                type="time"
                name="shift_start_time"
                value={data?.shift_start_time}
                onChange={handleChange}
              />
              {errors?.shift_start_time && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.shift_start_time}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="shift_end_time" className="label-required">
                Shift End Time
              </Label>
              <Input
                className="rounded-0"
                id="shift_end_time"
                type="time"
                name="shift_end_time"
                value={data?.shift_end_time}
                onChange={handleChange}
              />
              {errors?.shift_end_time && (
                <Label className="text-danger mt-2" for="name">
                  {errors.shift_end_time}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="working_hours" className="label-required">
                Working Hours
              </Label>
              <Input
                className="rounded-0"
                id="working_hours"
                type="numeric"
                name="working_hours"
                value={data?.working_hours}
                onChange={handleChange}
              />
              {errors?.working_hours && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.working_hours}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="phone_number" className="label-required">
                Phone Number
              </Label>
              <Input
                className="rounded-0"
                id="phone_number"
                type="numeric"
                name="phone_number"
                value={data?.phone_number}
                onChange={handleChange}
              />
              {errors?.phone_number && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.phone_number}
                </Label>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="cell_number" className="label-required">
                Cell Number
              </Label>
              <Input
                className="rounded-0"
                id="cell_number"
                type="numeric"
                name="cell_number"
                value={data?.cell_number}
                onChange={handleChange}
              />
              {errors?.cell_number && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.cell_number}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="campus_id">Campus</Label>
              <Input
                className="rounded-2"
                id="campus_id"
                required
                type="select"
                name="campus_id"
                value={data?.campus_id}
                onChange={handleChange}
              >
                <option value="">Select Campus</option>
                {campuses?.map((campus) => (
                  <option key={campus?.id} value={campus?.id}>
                    {campus?.name}
                  </option>
                ))}
              </Input>
              {errors?.campus_id && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.campus_id}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="religion" className="label-required">
                Religion
              </Label>
              <Input
                className="rounded-0"
                id="religion"
                type="text"
                name="religion"
                maxLength={255}
                value={data?.religion}
                onChange={handleChange}
              />
              {errors?.religion && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.religion}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="date_of_birth" className="label-required">
                Date of birth
              </Label>
              <Input
                className="rounded-0"
                id="date_of_birth"
                type="date"
                name="date_of_birth"
                value={data?.date_of_birth}
                onChange={handleChange}
              />
              {errors?.date_of_birth && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.date_of_birth}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="status" className="label-required">
                Status
              </Label>
              <Input
                className="rounded-0"
                id="status"
                type="text"
                name="status"
                value={data?.status}
                onChange={handleChange}
              />
              {errors?.status && (
                <Label className="text-danger mt-2" for="name">
                  {errors?.status}
                </Label>
              )}
            </FormGroup>

            <div className="row">
              <div className="col-md-4">
                <FormGroup>
                  <Label for="is_working" className="label-required">
                    Working
                  </Label>
                  <Input
                    className="rounded-0"
                    id="is_working"
                    type="checkbox"
                    name="is_working"
                    value={data?.is_working}
                    checked={data?.is_working}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label for="is_visiting" className="label-required">
                    Visitor
                  </Label>
                  <Input
                    className="rounded-0"
                    id="is_visiting"
                    type="checkbox"
                    name="is_visiting"
                    value={data?.is_visiting}
                    checked={data?.is_visiting}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label for="is_faculty" className="label-required">
                    Faculty
                  </Label>
                  <Input
                    className="rounded-0"
                    id="is_faculty"
                    type="checkbox"
                    name="is_faculty"
                    value={data?.is_faculty}
                    checked={data?.is_faculty}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <FormGroup>
                  <Label for="monday" className="label-required">
                    Monday
                  </Label>
                  <Input
                    className="rounded-0"
                    id="monday"
                    type="checkbox"
                    name="monday"
                    value={data?.monday}
                    checked={data?.monday}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label for="tuesday" className="label-required">
                    Tuesday
                  </Label>
                  <Input
                    className="rounded-0"
                    id="tuesday"
                    type="checkbox"
                    name="tuesday"
                    value={data?.tuesday}
                    checked={data?.tuesday}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label for="wednesday" className="label-required">
                    Wednesday
                  </Label>
                  <Input
                    className="rounded-0"
                    id="wednesday"
                    type="checkbox"
                    name="wednesday"
                    value={data?.wednesday}
                    checked={data?.wednesday}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label for="thursday" className="label-required">
                    Thursday
                  </Label>
                  <Input
                    className="rounded-0"
                    id="thursday"
                    type="checkbox"
                    name="thursday"
                    value={data?.thursday}
                    checked={data?.thursday}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label for="friday" className="label-required">
                    Friday
                  </Label>
                  <Input
                    className="rounded-0"
                    id="friday"
                    type="checkbox"
                    name="friday"
                    value={data?.friday}
                    checked={data?.friday}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label for="saturday" className="label-required">
                    Saturday
                  </Label>
                  <Input
                    className="rounded-0"
                    id="saturday"
                    type="checkbox"
                    name="saturday"
                    value={data?.saturday}
                    checked={data?.saturday}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label for="sunday" className="label-required">
                    Sunday
                  </Label>
                  <Input
                    className="rounded-0"
                    id="sunday"
                    type="checkbox"
                    name="sunday"
                    value={data?.sunday}
                    checked={data?.sunday}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggle}
              color="light"
              className="rounded-0"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="rounded-0"
              disabled={loading}
            >
              {loading && (
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              )}
              Update
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Edit;
