import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
  Table,
} from "reactstrap";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Container from "../../../components/Container";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import moment from "moment";
import { numberFormat } from "../../../utils/helpers";
import FeeCollection from "./FeeCollection";
import { useEffect } from "react";

const Index = () => {
  const [bankAccounts, setBankAccounts] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [vouchers, setVouchers] = useState([]);

  const fetchApplicant = async () => {
    try {
      setErrors("");
      setLoading(true);
      const { data } = await API.get(
        `/api/raiwind-vouchers?applicant_id=${filterValue}`
      );
      console.log(data.data);
      setLoading(false);
      setVouchers(data.data);
    } catch (error) {
      setLoading(false);
      setErrors(error.message);
    }
  };

  const fetchBankAccounts = async () => {
    try {
      const response = (
        await API.get("/api/accounts?parent-account-id=6-07-02-0000")
      ).data.data;
      setBankAccounts(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBankAccounts();
  }, []);
  return (
    <AppLayout>
      <Breadcrumbs
        title="Raiwind Vouchers"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Raiwind Vouchers",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3 d-flex align-items-center justify-content-between border-bottom pb-4">
            <h1 className="mb-0">Applicant Vouchers</h1>
          </div>

          <Card className="border-0 border-bottom">
            <CardBody>
              <div className="d-flex flex-wrap">
                <InputGroup style={{ flex: 2 }} className="mx-1">
                  <InputGroupText>Applicant No.</InputGroupText>
                  <Input
                    type="text"
                    value={filterValue}
                    required
                    onChange={(e) => setFilterValue(e.target.value)}
                  />
                  <button
                    className="btn btn-sm btn-primary d-block p-2 btn btn-primary"
                    type="button"
                    onClick={() => fetchApplicant()}
                    disabled={filterValue === ""}
                  >
                    Search
                  </button>
                </InputGroup>
              </div>
            </CardBody>
          </Card>
          <CardBody>
            {loading ? (
              <div className="text-center">
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              </div>
            ) : errors ? (
              <Alert variant="danger">{"something when wrong"}</Alert>
            ) : (
              <Table hover responsive>
                <thead>
                  <tr className="bg-shade-blue">
                    <th>#</th>
                    <th>Voucher No.</th>
                    <th>Session</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Total</th>
                    <th>Payment Method</th>
                    <th>Received By</th>
                    <th>Due Date</th>
                    <th>Expiry Date</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {vouchers.length > 0 ? (
                    vouchers.map((voucher, index) => (
                      <tr key={`vouchers-${voucher.id}`}>
                        <td>{index}</td>
                        <td>{voucher?.id}</td>
                        <td>{voucher?.fee_period}</td>
                        <td>{voucher?.fee_type}</td>
                        <td>
                          {voucher?.paid_at ? (
                            <div className="badge rounded-pill bg-success">
                              Paid @{" "}
                              {moment(voucher?.paid_at).format("DD/MM/YY")}
                            </div>
                          ) : (
                            <div className="badge rounded-pill bg-info">
                              Pending
                            </div>
                          )}
                        </td>
                        <td>{numberFormat(voucher?.total)}</td>
                        <td>{voucher?.account_details?.name}</td>
                        <td>{voucher?.received_by?.name}</td>
                        <td>{moment(voucher?.due_date).format("DD/MM/YY")}</td>
                        <td>
                          {moment(voucher?.expiry_date).format("DD/MM/YY")}
                        </td>
                        <td>
                          {moment(voucher?.created_at).format("DD/MM/YY")}
                        </td>
                        <td>
                          {moment(voucher?.updated_at).format("DD/MM/YY")}
                        </td>
                        <td>
                          {!voucher.paid_at && (
                            <div>
                              <FeeCollection
                                bankAccounts={bankAccounts}
                                voucher={voucher}
                                fetchApplicant={fetchApplicant}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan={11}>No Vouchers</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};

export default Index;
