import React, { useEffect, useState } from "react";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  Card,
  CardBody,
  Container,
  Spinner,
  Table,
  Row,
  Col,
} from "reactstrap";
import { Link, useParams, useLocation } from "react-router-dom";

export const Show = () => {
  const location = useLocation();
  const { topic } = location.state;

  const paramData = useParams();
  const [todayAttendance, settodayAttendance] = useState([]);
  const fetchTodayAttendances = async () => {
    try {
      const response = (
        await API.get(`/api/attendence/detail/${paramData.id}`, {
          params: {
            date: paramData.date,
          },
        })
      ).data.data;
      settodayAttendance(response);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchTodayAttendances();
  }, []);
  return (
    <AppLayout>
      <Breadcrumbs
        title="Student Attendance"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Student Attendance",
            link: null,
          },
        ]}
      />
      {/* ${detail?.timetable_id}/${detail?.date} */}
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3 d-flex align-items-center justify-content-between border-bottom">
            <h1 className="mb-0">Student Attendance {}</h1>
            {/* <Link to="/mark-attendance">
              <button className="btn btn-primary">Edit</button>
            </Link> */}
          </div>
          <CardBody>
            <Row>
              <Col md={8}>
                <strong className="d-block">Topic: {topic}</strong>
              </Col>
            </Row>

            <Table hover responsive border className="mt-3">
              <thead>
                <tr className="bg-shade-blue">
                  <th>#</th>
                  <th>Date</th>
                  <th>Roll Number</th>
                  <th>Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {todayAttendance.length > 0 ? (
                  todayAttendance.map((detail, index) => (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{detail.date}</td>
                      <td>{detail.enrollment_number}</td>
                      <td>
                        {
                          detail?.relationships?.student?.relationships
                            ?.applicant?.name
                        }
                      </td>
                      <td>
                        {/* {detail.status.charAt(0).toUpperCase() +
                          detail.status.slice(1)} */}
                        {detail.status.charAt(0).toUpperCase() +
                          detail.status.slice(1).replaceAll("_", " ")}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};
