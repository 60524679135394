import React, { useEffect, useState } from "react";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { Card, CardBody, Container, Col, Row, Button } from "reactstrap";
import { Space, Table, Tag } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import Create from "./Create";
import Edit from "./Edit";
export const Grading = () => {
  const [gradingData, setGradingData] = useState();
  const [refresh, setRefresh] = useState(false);

  const paramData = useParams();

  const fetchGradingData = async () => {
    try {
      const response = (await API.get(`/api/grading/${paramData?.id}`)).data
        .data;
      setGradingData(response);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchGradingData();
  }, [refresh]);

  return (
    <AppLayout>
      <Breadcrumbs
        title="Grading"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Grading",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3 d-flex align-items-center justify-content-between border-bottom">
            <h1 className="mb-0">Grading</h1>
            <Create
              gradingData={gradingData}
              setGradingData={setGradingData}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </div>
          <CardBody>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Exam Type</th>
                  <th scope="col">Weightage</th>
                  <th scope="col">Marks</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {gradingData?.map((data, index) => (
                  <tr key={index}>
                    <td>
                      {data?.relationships?.exam_type?.type
                        .charAt(0)
                        .toUpperCase() +
                        data?.relationships?.exam_type?.type.slice(1)}
                    </td>
                    <td>{data?.total_weightage}</td>
                    <td>{data?.total_marks} </td>
                    <td className="d-flex">
                      {" "}
                      <Edit
                        id={data?.id}
                        data={data}
                        setGradingData={setGradingData}
                        setRefresh={setRefresh}
                        refresh={refresh}
                      />
                      <Link
                        to={`/add-result/${paramData?.id}`}
                        state={{
                          exam_type_id: data?.exam_type_id,
                          result_id: data?.id,
                        }}>
                        <Button color="primary" className="rounded-pill btn-sm">
                          Add Marks
                        </Button>
                      </Link>
                      <Link
                        to={`/result/${paramData?.id}`}
                        state={{
                          exam_type_id: data?.exam_type_id,
                          result_id: data?.id,
                        }}>
                        <Button color="primary" className="rounded-pill btn-sm">
                          View Marks
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};
