import React, { useState, useEffect } from "react";
import AppLayout from "../../components/layouts/AppLayout";
import API from "../../utils/API";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import moment from "moment";
import { useSelector } from "react-redux";

export const ExamsSchedule = () => {
  const auth = useSelector((state) => state.auth);
  const [datesheet, setDatesheet] = useState(null);

  const fetchDatesheet = async () => {
    try {
      const response = await (await API.get("/api/datesheet")).data.data;
      setDatesheet(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDatesheet();
  }, []);

  return (
    <AppLayout>
      <Breadcrumbs
        title="Exams Schedule"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Exams Schedule",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3 d-flex align-items-center justify-content-between border-bottom">
            <h1 className="mb-0">Exams Schedule</h1>
          </div>
          <CardBody>
            <div
              class="tab-pane fade show active"
              id="currentCourses"
              role="tabpanel"
              aria-labelledby="currentCourses-tab"
            >
              <div>
                {datesheet?.map((datesheet, index) => (
                  <Col>
                    <div className="course shadow">
                      <Row>
                        <Col>
                          <h2>
                            {
                              datesheet?.relationships?.timeTable?.relationships
                                ?.course?.course_code
                            }{" "}
                            -{" "}
                            {
                              datesheet?.relationships?.timeTable?.relationships
                                ?.course?.name
                            }
                            <p className="small mb-0">
                              (
                              {moment(
                                datesheet?.relationships?.time_slot?.start,
                                "HH:mm a"
                              ).format("HH:mm a")}{" "}
                              -
                              {moment(
                                datesheet?.relationships?.time_slot.end,
                                "HH:mm a"
                              ).format("HH:mm a")}
                              )
                            </p>
                            <p className="small">
                              {
                                datesheet?.relationships?.detail?.relationships
                                  ?.day?.days
                              }
                            </p>
                          </h2>
                          <p>{auth.authData.data.name}</p>
                        </Col>
                        <Col>
                          <div className="mb-1 bg-light d-flex justify-content-between px-2">
                            {" "}
                            <p className="mb-0">Semester:</p>{" "}
                            <p className="mb-0">
                              {datesheet?.relationships.timeTable.semester}
                            </p>
                          </div>
                          <div className="mb-1 bg-light d-flex justify-content-between px-2">
                            {" "}
                            <p className="mb-0">Department:</p>{" "}
                            <p className="mb-0">
                              {
                                datesheet?.relationships?.timeTable
                                  ?.relationships?.department?.name
                              }
                            </p>
                          </div>
                          <div className="mb-1 bg-light d-flex justify-content-between px-2">
                            {" "}
                            <p className="mb-0">
                              Room: {datesheet?.room ? datesheet?.room : "N/A"}
                            </p>{" "}
                            <p className="mb-0">
                              {" "}
                              Section:{" "}
                              {
                                datesheet?.relationships?.timeTable
                                  ?.relationships?.section?.name
                              }
                            </p>
                          </div>
                          <div className="mb-1 bg-light d-flex justify-content-between px-2">
                            Block (
                            {
                              datesheet?.relationships?.timeTable?.relationships
                                ?.detail?.relationships?.room?.relationships
                                ?.block
                            }{" "}
                            ) - Room (
                            {
                              datesheet?.relationships?.timeTable?.relationships
                                ?.detail?.relationships?.room?.name
                            }
                            )
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                ))}
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};
