import { faFileExcel, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ReactPaginate from "react-paginate";
import { Link } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
  Table,
} from 'reactstrap';
import Breadcrumbs from '../../components/Breadcrumbs';
import Container from '../../components/Container';
import AppLayout from '../../components/layouts/AppLayout';
import API from '../../utils/API';
import { CSSTransition } from 'react-transition-group';
const Index = () => {
  const [applicants, setApplicants] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [academicSessions, setAcademicSessions] = useState([]);
  const [campuses, setCampuses] = useState([]);
  const [studyLevels, setStudyLevels] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [errors, setErrors] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setMeta] = useState(1);
  const [filterValue, setFilterValue] = useState("");
  // const fetchApplicants = async () => {
  //   try {
  //     const data = (await API.get('/api/applicants')).data.data;
  //     setApplicants(data);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //   }
  // };

  const fetchApplicants = async (filterValue,currentPage) => {
    try {
      setLoading(true);

      let url = `/api/applicants?applicantId=${filterValue}&page=${currentPage}`;

      const data = (await API.get(url)).data;
      setLoading(false);

      setApplicants(
        data?.data?.map((item, index) => {
          item.index = index + 1 + (currentPage - 1) * 20;
          return item;
        })
      );
      setMeta(data?.meta.last_page);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  function uplodBankImport(e) {
    setFile(e.target.files[0]);
    setButtonDisabled(false);
  }
  const submitBanKFile = async () => {
    try {
      const formdata = new FormData;
      formdata.append('file', file);
      const data = await API.post('/api/bank/vouchers-import', formdata);
      setFile();
      setButtonDisabled(true);
      setApplicants(data);
      setLoading(false);
    } catch (error) {
      console.log()
      setErrors(
        error?.response?.data?.message?.file
      );
      setLoading(false);
    }
  }
  const handlePageClick = async (page) => {
    fetchApplicants("",page.selected + 1);
    setCurrentPage(page.selected + 1);
  };
  useEffect(() => {
    fetchApplicants("",currentPage);
  }, []);
  // useEffect(() => {
  //   fetchApplicants();
  // }, []);

  return (
    <AppLayout>
      <Breadcrumbs
        title='Admissions'
        links={[
          {
            title: 'Dashboard',
            link: null,
          },
          {
            title: 'Admissions',
            link: null,
          },
        ]}
      />
      <Container>
        <Card className='border-0 shadow mt-5' style={{ minHeight: '70vh' }}>
          <div className='p-3 d-flex align-items-center justify-content-between border-bottom pb-4'>
            <h1 className='mb-0'>All Applicants</h1>

            <label for='import_paid_list'>

              <input name='file' onChange={(e) => uplodBankImport(e)} id="import_paid_list" type='file' />
              <button disabled={buttonDisabled} className='btn btn-primary rounded-0' onClick={() => submitBanKFile()}>
                <FontAwesomeIcon icon={faFileExcel} className='me-2' />
                Submit paid list
              </button>
            </label>

          </div>
          {errors && (
            <p className="text-danger mt-2 text-right" style={{
              textAlign: 'right', position: "absolute",
              right: "238px",
              top: "52px",
            }} for="name">
              {errors}
            </p>
          )}
          <Card className='border-0 border-bottom'>
            <CardBody>
            <div className="d-flex flex-wrap">
                <InputGroup style={{ flex: 2 }} className="mx-1">
                  <InputGroupText>Applicant No.</InputGroupText>
                  <Input
                    value={filterValue}
                    onChange={(e) => setFilterValue(e.target.value)}
                  />
                  <button
                    className="btn btn-sm btn-primary d-block p-2 btn btn-primary"
                    type="button"
                    onClick={() => fetchApplicants(filterValue,'')}>
                    Search
                  </button>
                </InputGroup>
              {/* <div className='d-flex flex-wrap'>
                <InputGroup style={{ flex: 2 }} className='mx-1'>
                  <InputGroupText> Search By Applicant No.</InputGroupText>
                  <Input />
                </InputGroup> */}
                {/* <InputGroup style={{ flex: 2 }} className='mx-1'>
                  <InputGroupText>Name/Phone</InputGroupText>
                  <Input />
                </InputGroup>
                <InputGroup style={{ flex: 2 }} className='mx-1'>
                  <InputGroupText>CNIC/B-Form</InputGroupText>
                  <Input />
                </InputGroup> */}
                {/* <div style={{ flex: 1 }} className='mx-1'>
                  <Button
                    onClick={() => setShowFilters(!showFilters)}
                    color='secondary'
                    className='w-100'
                  >
                    <FontAwesomeIcon icon={faFilter} className='me-2' />
                    Additional Filters
                  </Button>
                </div> */}
              </div>
              <CSSTransition
                unmountOnExit
                in={showFilters}
                timeout={200}
                classNames='accordion'
              >
                <Card className='border-0 border-bottom bg-secondary rounded-0 mt-3'>
                  <CardBody>
                    <div className='d-flex flex-wrap'>
                      <InputGroup style={{ flex: 1 }} className='mx-1'>
                        <InputGroupText>Academic Session</InputGroupText>
                        <Input type='select'>
                          <option value=''>Select an option</option>
                        </Input>
                      </InputGroup>
                      <InputGroup style={{ flex: 1 }} className='mx-1'>
                        <InputGroupText>Program</InputGroupText>
                        <Input type='select'>
                          <option value=''>Select an option</option>
                        </Input>
                      </InputGroup>
                      <InputGroup style={{ flex: 1 }} className='mx-1'>
                        <InputGroupText>Status</InputGroupText>
                        <Input type='select'>
                          <option value=''>Select an option</option>
                          <option value='1'>Due: Application Fee</option>
                          <option value='2'>
                            Due: Admission Fee + Semester Fee
                          </option>
                          <option value='3'>Admission Confirmed</option>
                        </Input>
                      </InputGroup>
                    </div>
                  </CardBody>
                </Card>
              </CSSTransition>
            </CardBody>
          </Card>
          <CardBody>
            <Table hover responsive>
              <thead>
                <tr className='bg-shade-blue'>
                  <th>#</th>
                  <th>Applicant ID</th>
                  <th>Name</th>
                  <th>CNIC/B-Form</th>
                  <th>Program</th>
                  <th>Campus</th>
                  <th>Shift</th>
                  <th>Session</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {applicants.length > 0 ? (
                  applicants.map((applicant, index) => (
                    <tr key={`applicant-${applicant.id}`}>
                      <th scope='row'>{index+1}</th>
                      <td>{applicant.id}</td>
                      <td>{applicant?.name}</td>
                      <td>{applicant.cnic}</td>
                      <td>{applicant.relationships?.program?.name}</td>
                      <td>{applicant.relationships?.campus?.name}</td>
                      <td>{applicant.shift}</td>
                      <td>{applicant.relationships?.academic_session?.name}</td>
                      <td>
                        {applicant.relationships?.vouchers.length == 1 &&
                          !applicant.relationships?.vouchers.find(
                            (voucher) => voucher.fee_type === 'Application Fee'
                          ).paid_at && (
                            <Badge pill color='info'>
                              Due: Application Fee
                            </Badge>
                          )}
                        {applicant.relationships?.vouchers.length == 1 &&
                          applicant.relationships?.vouchers.find(
                            (voucher) => voucher.fee_type === 'Application Fee'
                          ).paid_at && (
                            <Badge pill color='warning'>
                              Pending Interview &amp; Test
                            </Badge>
                          )}
                        {applicant.relationships?.vouchers.length != 1 &&
                          applicant.relationships?.vouchers.every(
                            (voucher) => voucher.paid_at
                          ) && (
                            <Badge pill color='success'>
                              Admission Confirmed
                            </Badge>
                          )}
                        {applicant.relationships?.vouchers.length != 1 &&
                          !applicant.relationships?.vouchers.every(
                            (voucher) => voucher.paid_at
                          ) && (
                            <Badge color='info' pill>
                              Due: Admission Fee
                            </Badge>
                          )}
                      </td>
                      <td>
                        <Link
                          to={`/admissions/${applicant.id}`}
                          className='btn btn-primary rounded-0'
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      {loading ? (
                        <Spinner style={{ width: '30px', height: '30px' }} />
                      ) : (
                        'No Applicants Exists'
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
              <div className="w-100 d-table-caption pt-5">
                <ReactPaginate
                  breakLabel={"..."}
                  nextLabel={">>"}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  pageCount={meta}
                  previousLabel={"<<"}
                  margibPageDisply={3}
                  containerClassName={
                    "Pagination d-flex justify-content-center list-unstyled w-100"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </Table>
          </CardBody>
        </Card>
      </Container>
    </AppLayout >
  );
};

export default Index;
