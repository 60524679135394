import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/modal.css";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import API from "../../../utils/API";
import moment from "moment";

const Create = ({ Session, fetchSessions }) => {
  // Modal open state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [yearErrors, setYearErrors] = useState(null);
  const [campuses, setCampuses] = useState([]);
  // const [session, setSession] = useState("");

  const [sessionTypes, setSessionTypes] = useState([]);

  const defaltData = {
    name: "",
    session_type_id: "",
    campus_id: "",
    year: "",
    enrollment_start: "",
    enrollment_end: "",
    is_active: 0,
    is_result_unlocked: 0,
  };
  const [data, setData] = useState(defaltData);

  useEffect(() => {
    fetchCampuses();
  }, []);

  useEffect(() => {
    fetchSessionTypes();
  }, []);

  const fetchCampuses = async () => {
    try {
      setLoading(true);
      const response = (await API.get("/api/campuses")).data;
      setCampuses(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchSessionTypes = async () => {
    try {
      setLoading(true);
      const response = (await API.get("/api/session-types")).data;
      setSessionTypes(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // Toggle for Modal
  const toggle = () => setModal(!modal);
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (data.year === "") {
        setYearErrors("Year is required");
        return;
      }
      setLoading(true);
      const response = await API.post("/api/sessions", data);
      setLoading(false);
      fetchSessions();
      toggle();
      setData(defaltData);
      setErrors([]);
    } catch (error) {
      setErrors(
        error?.response?.data?.errors ? error.response.data.errors : []
      );
      setLoading(false);
    }
  }
  const onChangeType = (e) => {
    if (errors.length) setErrors([]);
    setData({
      ...data,
      [e.target.name]: e.target.value,
      name:
        sessionTypes.find((item) => item.id == e.target.value).name +
        "-" +
        data.year,
    });
  };
  const onChangeYear = (e) => {
    if (errors.length) setErrors([]);

    setData({
      ...data,
      [e.target.name]: e.target.value,
      name:
        sessionTypes.find((item) => item.id == data.session_type_id).name +
        "-" +
        e.target.value,
    });
    setYearErrors(null);
  };
  const handleChange = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleChangeCheckbox = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.checked ? 1 : 0 });
  };

  return (
    <div>
      <Button color="primary" className="rounded-pill" onClick={toggle}>
        <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
        Create New
      </Button>

      <Modal
        modalClassName="custommodal"
        centered
        scrollable
        size="xl"
        toggle={toggle}
        isOpen={modal}
        fade={true}
      >
        <ModalHeader toggle={toggle}>Add Session</ModalHeader>
        <Form method="POST" action="#" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for="name" className="label-required">
                Name
              </Label>
              <Input
                className="rounded-2"
                id="name"
                type="name"
                name="name"
                maxLength={255}
                value={data?.name}
                onChange={handleChange}
                required
                autoFocus
                readOnly
              />
              {errors.name && (
                <Label className="text-danger mt-2" for="name">
                  {errors.name}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="session_type_id">Session</Label>
              <Input
                className="rounded-2"
                id="session_type_id"
                required
                type="select"
                name="session_type_id"
                value={data.session_type_id}
                onChange={onChangeType}
              >
                <option value="">Select Session</option>
                {sessionTypes.map((sessionType) => (
                  <option key={sessionType.id} value={sessionType.id}>
                    {sessionType.name}
                  </option>
                ))}
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="year" className="label-required">
                year
              </Label>
              <Input
                className="rounded-2"
                id="year"
                type="text"
                name="year"
                maxLength={255}
                value={data.year}
                onChange={onChangeYear}
              />
              {yearErrors && (
                <Label className="text-danger mt-2" for="name">
                  {yearErrors}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="campus_id">Campus</Label>
              <Input
                className="rounded-2"
                id="campus_id"
                required
                type="select"
                name="campus_id"
                onChange={handleChange}
              >
                <option value="">Select Campus</option>
                {campuses.map((campus) => (
                  <option key={campus.id} value={campus.id}>
                    {campus.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="enrollment_start" className="label-required">
                Enrollment start Date
              </Label>
              <Input
                className="rounded-2"
                id="enrollment_start"
                type="date"
                name="enrollment_start"
                maxLength={255}
                value={data.enrollment_start}
                onChange={handleChange}
              />
              {errors.enrollment_start && (
                <Label className="text-danger mt-2" for="name">
                  {errors.enrollment_start}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="enrollment_end" className="label-required">
                Enrollment end Date
              </Label>
              <Input
                className="rounded-2"
                id="enrollment_end"
                type="date"
                name="enrollment_end"
                maxLength={255}
                value={data.enrollment_end}
                onChange={handleChange}
              />
              {errors.enrollment_end && (
                <Label className="text-danger mt-2" for="name">
                  {errors.enrollment_end}
                </Label>
              )}
            </FormGroup>
            <div className="row">
              <div className="col-md-4">
                <FormGroup>
                  <Label for="is_active" className="label-required">
                    Active
                  </Label>
                  <Input
                    className="rounded-2"
                    id="is_active"
                    type="checkbox"
                    name="is_active"
                    maxLength={255}
                    value={data.is_active}
                    onChange={handleChangeCheckbox}
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label for="is_result_unlocked" className="label-required">
                    Result Unlocked
                  </Label>
                  <Input
                    className="rounded-2"
                    id="is_result_unlocked"
                    type="checkbox"
                    name="is_result_unlocked"
                    maxLength={255}
                    value={data.is_result_unlocked}
                    onChange={handleChangeCheckbox}
                  />
                  {errors.is_result_unlocked && (
                    <Label
                      className="text-danger mt-2"
                      for="is_result_unlocked"
                    >
                      {errors.is_result_unlocked}
                    </Label>
                  )}
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggle}
              color="light"
              className="rounded-2"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="rounded-2"
              disabled={loading}
            >
              {loading && (
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              )}
              Submit
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Create;
