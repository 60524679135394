import React from "react";

const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="199.167"
      height="48"
      viewBox="0 0 199.167 48">
      <g
        id="Group_751"
        data-name="Group 751"
        transform="translate(-30.917 -15.672)">
        <text
          id="logo"
          data-name="University of South Asia"
          transform="translate(94.084 34.672)"
          fill="#072244"
          fontSize="18"
          fontFamily="Poppins"
          fontWeight="800">
          <tspan x="0" y="0">
            UNIVERSITY OF
          </tspan>
          <tspan fontSize="22">
            <tspan x="0" y="21">
              SOUTH ASIA
            </tspan>
          </tspan>
        </text>
        <g
          id="Group_242"
          data-name="Group 242"
          transform="translate(30.917 16)">
          <path
            id="Path_1"
            data-name="Path 1"
            d="M258.991,67.732a28.083,28.083,0,1,0-50.748,16.575h45.33A27.947,27.947,0,0,0,258.991,67.732Z"
            transform="translate(-202.825 -39.649)"
            fill="#072244"
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M238.873,63.289H223.612l8.83-8.884H247.7Z"
            transform="translate(-194.129 -33.476)"
            fill="#fff"
          />
          <path
            id="Path_3"
            data-name="Path 3"
            d="M224.81,53.373V66.426l-9.535-9.235V44.138Z"
            transform="translate(-197.616 -37.771)"
            fill="#fff"
          />
          <path
            id="Path_4"
            data-name="Path 4"
            d="M222.827,53.373V66.426l9.535-9.235V44.138Z"
            transform="translate(-194.457 -37.771)"
            fill="#fff"
          />
          <path
            id="Path_5"
            data-name="Path 5"
            d="M213.064,63.289h15.261L219.5,54.4H204.235Z"
            transform="translate(-202.235 -33.476)"
            fill="#fff"
          />
          <g
            id="Group_1"
            data-name="Group 1"
            transform="translate(13.508 32.252)">
            <path
              id="Path_6"
              data-name="Path 6"
              d="M218.462,62.515h2.9v6.259a5.832,5.832,0,0,1-.26,1.757,3.894,3.894,0,0,1-.816,1.447,3.237,3.237,0,0,1-1.164.871,5.3,5.3,0,0,1-2.034.35,11.583,11.583,0,0,1-1.5-.106,3.634,3.634,0,0,1-1.357-.427,3.519,3.519,0,0,1-1-.906,3.449,3.449,0,0,1-.62-1.21,6.979,6.979,0,0,1-.268-1.776V62.515h2.9v6.408a1.954,1.954,0,0,0,.427,1.342,1.692,1.692,0,0,0,2.36.009,1.952,1.952,0,0,0,.427-1.35Z"
              transform="translate(-212.349 -62.335)"
              fill="#fff"
            />
            <path
              id="Path_7"
              data-name="Path 7"
              d="M219.7,69.6l2.764-.193a2.517,2.517,0,0,0,.366,1.146,1.473,1.473,0,0,0,1.282.638,1.328,1.328,0,0,0,.96-.326,1.022,1.022,0,0,0,.336-.756,1.007,1.007,0,0,0-.321-.73,3.5,3.5,0,0,0-1.488-.61,6,6,0,0,1-2.727-1.275,2.7,2.7,0,0,1-.821-2.028,2.984,2.984,0,0,1,.421-1.53,2.871,2.871,0,0,1,1.264-1.132,5.337,5.337,0,0,1,2.312-.413,4.347,4.347,0,0,1,2.749.749,3.348,3.348,0,0,1,1.126,2.383l-2.739.18a1.712,1.712,0,0,0-.46-1.033,1.371,1.371,0,0,0-.964-.322,1.081,1.081,0,0,0-.765.24.772.772,0,0,0-.255.584.6.6,0,0,0,.211.451,2.216,2.216,0,0,0,.974.387,11.367,11.367,0,0,1,2.73.928,3.017,3.017,0,0,1,1.2,1.164,3.231,3.231,0,0,1,.374,1.556,3.62,3.62,0,0,1-.5,1.864,3.263,3.263,0,0,1-1.4,1.294,5.141,5.141,0,0,1-2.265.44A3.662,3.662,0,0,1,219.7,69.6Z"
              transform="translate(-209.272 -62.388)"
              fill="#fff"
            />
            <path
              id="Path_8"
              data-name="Path 8"
              d="M232.81,71.286h-3.3l-.458,1.735h-2.967l3.535-10.506h3.17l3.534,10.506h-3.044Zm-.6-2.272-1.037-3.776-1.028,3.776Z"
              transform="translate(-206.602 -62.335)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
