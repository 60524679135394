import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../../css/modal.css';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import API from '../../../utils/API';
import axios from 'axios';

const Edit = ({ academicSession, fetchAcademicSessions }) => {
  // Modal open state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState({
    name: '',
    type: '',
    year: '',
    start_date: '',
    end_date: '',
    is_active: 0,
  });

  useEffect(() => {
    setData(academicSession);
  }, []);

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await API.put(`api/academic-sessions/${data.id}`, data);
      setLoading(false);
      fetchAcademicSessions();
      toggle();
      setErrors([]);
    } catch (error) {
      setErrors(
        error?.response?.data?.errors ? error.response.data.errors : []
      );
      setLoading(false);
    }
  }
  const handleChange = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const onChangeType = (e) => {
    if (errors.length) setErrors([]);
    setData({
      ...data,
      [e.target.name]: e.target.value,
      name: e.target.value + '-' + data.year,
    });
  };
  const onChangeYear = (e) => {
    if (errors.length) setErrors([]);
    setData({
      ...data,
      [e.target.name]: e.target.value,
      name: data.type + '-' + e.target.value,
    });
  };

  return (
    <div>
      <Button
        color='primary'
        className='btn btn-sm btn-primary d-block w-100 mb-2'
        onClick={toggle}
      >
        Edit
      </Button>

      <Modal
        modalClassName='custommodal'
        centered
        scrollable
        size='xl'
        toggle={toggle}
        isOpen={modal}
        fade={true}
      >
        <ModalHeader toggle={toggle}>Add Academic Sessions</ModalHeader>
        <Form method='POST' action='#' onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for='name' className='label-required'>
                Name
              </Label>
              <Input
                className='rounded-2'
                id='name'
                type='name'
                name='name'
                // value={`${type} ${type ? "-" : ""} ${year}`}
                maxLength={255}
                value={data.name}
                // onChange={handleChange}
                required
                autoFocus
                readOnly
              />
              {errors.name && (
                <Label className='text-danger mt-2' for='name'>
                  {errors.name}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label for='type' className='label-required'>
                Type
              </Label>
              <Input
                className='rounded-2'
                id='type'
                type='select'
                name='type'
                maxLength={255}
                value={data.type}
                onChange={onChangeType}
                required
              >
                <option value=''>Select sessions type</option>
                <option>Fall</option>
                <option>Spring</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for='year' className='label-required'>
                year
              </Label>
              <Input
                className='rounded-2'
                id='year'
                type='year'
                name='year'
                maxLength={255}
                value={data.year}
                onChange={onChangeYear}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for='start_date' className='label-required'>
                Start Date
              </Label>
              <Input
                className='rounded-2'
                id='start_date'
                type='date'
                name='start_date'
                maxLength={255}
                value={data.start_date}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for='end_date' className='label-required'>
                End Date
              </Label>
              <Input
                className='rounded-2'
                id='end_date'
                type='date'
                name='end_date'
                maxLength={255}
                value={data.end_date}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type='button'
              onClick={toggle}
              color='light'
              className='rounded-2'
            >
              Cancel
            </Button>
            <Button
              type='submit'
              color='primary'
              className='rounded-2'
              disabled={loading}
            >
              {loading && (
                <Spinner className='me-2' size='sm'>
                  Loading...
                </Spinner>
              )}
              Submit
            </Button>{' '}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Edit;
