import React from "react";

const GuestLayout = (props) => {
  return (
    <div className="bg-light">
      <div
        className="container d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default GuestLayout;
