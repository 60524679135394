import React from "react";
import Logo from "../../svgs/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faUserShield,
  faUserGear,
  faBell,
  faBuildingCircleCheck,
  faDashboard,
  faUserPlus,
  faCalendarTimes,
  faCalendarDays,
  faFileInvoice,
  faSwatchbook,
  faUsers,
  faComputer,
  faUserGraduate,
  faFolderTree,
  faPersonChalkboard,
  faTimeline,
  faTimesCircle,
  faGraduationCap,
  faRepeat,
  faBuilding,
  faBuildingColumns,
} from "@fortawesome/free-solid-svg-icons";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/auth";
import { Link, NavLink } from "react-router-dom";
import CanAny from "../CanAny";

const AppLayout = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="vh-100 d-flex flex-column" style={{ overflow: "hidden" }}>
      <div className="d-flex shadow">
        <div
          style={{ height: "75px", width: "300px" }}
          className="bg-white d-flex align-items-center justify-content-center"
        >
          <Link to="/home">
            <Logo />
          </Link>
        </div>
        <div
          style={{ height: "75px", flex: "1" }}
          className="bg-light-blue d-flex align-items-center justify-content-end"
        >
          <UncontrolledDropdown className="dropdown me-2">
            <DropdownToggle
              className="btn btn-light text-light-blue"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="d-flex align-items-center">
                <img
                  src={`https://ui-avatars.com/api/?name=${auth.authData.data.name}`}
                  className="me-2 rounded-circle"
                  height="24px"
                  alt=""
                />
                {auth.authData.data.name}
              </span>
            </DropdownToggle>
            <DropdownMenu end flip={true} className="animate slideIn">
              <DropdownItem tag={Link} to="/profile">
                {" "}
                Profile
              </DropdownItem>
              <DropdownItem>Change Password</DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <div className="me-4">
            <button
              type="button"
              className="btn btn-light text-light-blue"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <FontAwesomeIcon icon={faBell}></FontAwesomeIcon>
            </button>
          </div>
        </div>
      </div>

      <div
        className="d-flex"
        style={{ flex: "1", height: "calc(100% - 75px)" }}
      >
        <nav
          id="main-nav"
          style={{ width: "300px", overflowY: "scroll" }}
          className="bg-dark-blue"
        >
          <ul className="nav flex-column">
            <li className="nav-item">
              <NavLink className="nav-link" aria-current="page" to="/home">
                <FontAwesomeIcon
                  icon={faDashboard}
                  className="me-2"
                ></FontAwesomeIcon>
                Dashboard
              </NavLink>
            </li>
          </ul>
          <div className="nav-divider mx-2"></div>
          <small className="mx-2 mt-3 mb-1 text-light d-block">
            Fee Management
          </small>
          <ul className="nav flex-column">
            <li className="nav-item">
              <NavLink className="nav-link" to="/admissions">
                <FontAwesomeIcon
                  icon={faUserPlus}
                  className="me-2"
                ></FontAwesomeIcon>
                Admissions
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink className="nav-link" to="/voucher-report">
                <FontAwesomeIcon
                  icon={faUserPlus}
                  className="me-2"
                ></FontAwesomeIcon>
                Cantt Voucher Reports
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/raiwind/daily-voucher-report">
                <FontAwesomeIcon
                  icon={faUserPlus}
                  className="me-2"
                ></FontAwesomeIcon>
                Daily Raiwind Vouchers
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/raiwind/voucher-report">
                <FontAwesomeIcon
                  icon={faUserPlus}
                  className="me-2"
                ></FontAwesomeIcon>
                Raiwind Voucher Report
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <CanAny>
                <NavLink className="nav-link" to="/student-accounts">
                  <FontAwesomeIcon
                    icon={faUserGraduate}
                    className="me-2"></FontAwesomeIcon>
                  Student Accounts &amp; Details
                </NavLink>
              </CanAny>
            </li>
            <li className="nav-item">
              <CanAny>
                <NavLink className="nav-link" to="/student-accounts">
                  <FontAwesomeIcon
                    icon={faFileInvoice}
                    className="me-2"></FontAwesomeIcon>
                  Cost Centers
                </NavLink>
              </CanAny>
            </li>
            <li className="nav-item">
              <CanAny>
                <NavLink className="nav-link" to="/student-accounts">
                  <FontAwesomeIcon
                    icon={faFileInvoice}
                    className="me-2"></FontAwesomeIcon>
                  Sub Cost Centers
                </NavLink>
              </CanAny>
            </li> */}
            <li className="nav-item">
              <CanAny>
                <NavLink className="nav-link" to="/chart-of-accounts">
                  <FontAwesomeIcon
                    icon={faFolderTree}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Chart of Accounts
                </NavLink>
              </CanAny>
            </li>
          </ul>
          <CanAny>
            <div className="nav-divider mx-2"></div>
            <small className="mx-2 mt-3 mb-1 text-light d-block">HRM</small>
            <ul className="nav flex-column">
              <li className="nav-item">
                <NavLink className="nav-link" to="/employees">
                  <FontAwesomeIcon
                    icon={faUsers}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Employees
                </NavLink>
              </li>

              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/payroll-auditing">
                  <FontAwesomeIcon
                    icon={faUserGear}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Payroll Auditing
                </NavLink>
              </li> */}
            </ul>
            {/* <div className="nav-divider mx-2"></div>
            <small className="mx-2 mt-3 mb-1 text-light d-block">
              Examinations
            </small>
            <ul className="nav flex-column">
              <li className="nav-item">
                <NavLink className="nav-link" to="/transcript">
                  <FontAwesomeIcon
                    icon={faSwatchbook}
                    className="me-2"></FontAwesomeIcon>
                  Transcripts
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/gazette">
                  <FontAwesomeIcon
                    icon={faPersonChalkboard}
                    className="me-2"></FontAwesomeIcon>
                  Gazette
                </NavLink>
              </li>
            </ul> */}
            {/* <div className="nav-divider mx-2"></div>
            <small className="mx-2 mt-3 mb-1 text-light d-block">
              Examinations
            </small>
            <ul className="nav flex-column">
              <li className="nav-item">
                <NavLink className="nav-link" to="/transcript">
                  <FontAwesomeIcon
                    icon={faBuildingColumns}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Transcripts
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/transcript">
                  <FontAwesomeIcon
                    icon={faBuildingColumns}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Gazette
                </NavLink>
              </li>
            </ul> */}
            <div className="nav-divider mx-2"></div>
            <small className="mx-2 mt-3 mb-1 text-light d-block">
              Academics
            </small>
            <ul className="nav flex-column">
              <li className="nav-item">
                <NavLink className="nav-link" to="/academics/campuses">
                  <FontAwesomeIcon
                    icon={faBuildingColumns}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Campuses
                </NavLink>
              </li>
              {
                /* <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/academics/academics-sessions">
                  <FontAwesomeIcon
                    icon={faRepeat}
                    className="me-2"></FontAwesomeIcon>
                  Academic Sessions
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/academics/sessions">
                  <FontAwesomeIcon
                    icon={faUserGear}
                    className="me-2"></FontAwesomeIcon>
                  Sessions
                </NavLink>
              </li> */
                <li className="nav-item">
                  {/* <NavLink className="nav-link" to="/academics/session-details">
                  <FontAwesomeIcon
                    icon={faUserGear}
                    className="me-2"></FontAwesomeIcon>
                  Session Detail{" "}
                </NavLink> */}
                  <NavLink className="nav-link" to="/academics/blocks">
                    <FontAwesomeIcon
                      icon={faBuilding}
                      className="me-2"
                    ></FontAwesomeIcon>
                    Blocks
                  </NavLink>
                </li>
              }
              <li className="nav-item">
                <NavLink className="nav-link" to="/academics/Rooms">
                  <FontAwesomeIcon
                    icon={faBuilding}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Rooms
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/academics/faculties">
                  <FontAwesomeIcon
                    icon={faPersonChalkboard}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Faculties
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link" to="/academics/departments">
                  <FontAwesomeIcon
                    icon={faBuildingCircleCheck}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Departments
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/academics/days">
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Days
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/academics/programs">
                  <FontAwesomeIcon
                    icon={faComputer}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Programs
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/academics/study-levels">
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Study Levels
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/academics/timeslots">
                  <FontAwesomeIcon
                    icon={faTimeline}
                    className="me-2"
                  ></FontAwesomeIcon>
                  TimeSlots
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/academics/courses">
                  <FontAwesomeIcon
                    icon={faSwatchbook}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Courses
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/academics/academic-calendar">
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Academic Calendar
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/academics/sections">
                  <FontAwesomeIcon
                    icon={faBuilding}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Sections
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/academics/time-tables">
                  <FontAwesomeIcon
                    icon={faCalendarTimes}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Time Tables
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/academics/Enrollments">
                  <FontAwesomeIcon
                    icon={faUserGear}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Enrollments
                </NavLink>
              </li>
            </ul>
            {/* <div className="nav-divider mx-2"></div>
            <small className="mx-2 mt-3 mb-1 text-light d-block">
              User Management
            </small>
            <ul className="nav flex-column">
              <li className="nav-item">
                <NavLink className="nav-link" to="/users">
                  <FontAwesomeIcon
                    icon={faUserGear}
                    className="me-2"></FontAwesomeIcon>
                  Users
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/roles-and-permissions">
                  <FontAwesomeIcon
                    icon={faUserShield}
                    className="me-2"></FontAwesomeIcon>
                  Roles &amp; Permissions
                </NavLink>
              </li>
            </ul> */}
            <div className="nav-divider mx-2"></div>
            <small className="mx-2 mt-3 mb-1 text-light d-block">Faculty</small>
            <ul className="nav flex-column">
              <li className="nav-item">
                <NavLink className="nav-link" to="/lecture-timetable">
                  <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                  Lecture Timetable
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/exams-schedule">
                  <FontAwesomeIcon
                    icon={faUserShield}
                    className="me-2"></FontAwesomeIcon>
                  Exams Schedule
                </NavLink>
              </li> */}
            </ul>
          </CanAny>
        </nav>

        <main
          style={{ flex: "1", overflowY: "auto" }}
          className="bg-secondary h-100 pb-5"
        >
          {props.children}
        </main>
      </div>
    </div>
  );
};

export default AppLayout;
