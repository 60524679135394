import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/modal.css";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import API from "../../../utils/API";

const Create = ({ block, fetchBlocks }) => {
  // Modal open state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [campuses, setCampuses] = useState([]);
  const [data, setData] = useState({
    name: "",
    campus_id: "",
    created_at: "",
    updated_at: "",
  });

  useEffect(() => {
    fetchCampuses();
  }, []);

  const fetchCampuses = async () => {
    try {
      setLoading(true);
      const response = (await API.get("/api/campuses")).data;
      setCampuses(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // Toggle for Modal
  const toggle = () => setModal(!modal);
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await API.post("/api/blocks", data);
      setLoading(false);
      fetchBlocks();
      toggle();
      setErrors([]);
    } catch (error) {
      setErrors(
        error?.response?.data?.errors ? error.response.data.errors : []
      );
      setLoading(false);
    }
  }
  const handleChange = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Button color="primary" className="rounded-pill" onClick={toggle}>
        <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
        Create New
      </Button>
      <Modal
        centered
        scrollable
        size="lg"
        toggle={toggle}
        isOpen={modal}
        fade={true}>
        <ModalHeader toggle={toggle}>Add Block</ModalHeader>
        <Form method="POST" action="#" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label htmlFor="name" className="label-required">
                Name
              </Label>
              <Input
                className="rounded-2"
                id="name"
                type="text"
                name="name"
                maxLength={255}
                value={data.name}
                onChange={handleChange}
                required
                autoFocus
              />
              {errors.name && (
                <Label className="text-danger mt-2" htmlFor="name">
                  {errors.name}
                </Label>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="campus_id">Campus</Label>
              <Input
                className="rounded-2"
                id="campus_id"
                required
                type="select"
                name="campus_id"
                onChange={handleChange}>
                <option value="">Select Campus</option>
                {campuses.map((campus) => (
                  <option key={campus.id} value={campus.id}>
                    {campus.name}
                  </option>
                ))}
              </Input>
            </FormGroup>

            {errors.campus && (
              <Label className="text-danger mt-2" htmlFor="name">
                {errors.campus}
              </Label>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggle}
              color="light"
              className="rounded-2">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="rounded-2"
              disabled={loading}>
              {loading && (
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              )}
              Submit
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Create;
