import React, { useEffect, useState } from "react";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { Card, CardBody, Container, Col, Row, Button } from "reactstrap";
import { Space, Table, Tag } from "antd";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Create from "./Create";
import Edit from "./Edit";
import { Form, Input } from "antd";
import { toast } from "react-toastify";

export const ShowResult = () => {
  const location = useLocation();
  const { exam_type_id, result_id } = location.state;

  console.log("id", result_id);

  const [marks, setMarks] = useState();
  const [refresh, setRefresh] = useState(false);

  const paramData = useParams();

  const fetchMarksgData = async () => {
    try {
      const response = await (
        await API.get(`/api/marks/${paramData.id}?exam_type_id=${exam_type_id}`)
      ).data.data;
      //   toast.success(response.data.message);
      setMarks(response);
      console.log("result:", response);
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    fetchMarksgData();
  }, []);

  return (
    <AppLayout>
      <Breadcrumbs
        title="Grading"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Grading",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3 d-flex align-items-center justify-content-between border-bottom">
            <h1 className="mb-0">Grading</h1>
          </div>
          <CardBody>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Sr</th>
                  <th scope="col">Roll No.</th>
                  <th scope="col">Marks</th>
                </tr>
              </thead>
              <tbody>
                {marks?.map((data, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data?.roll_number}</td>
                    <td>
                      <span>{data?.marks} </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};
