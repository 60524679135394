import React, { useState } from "react";
import "../../../css/modal.css";
import { Button, Modal, ModalBody, Spinner } from "reactstrap";
import API from "../../../utils/API";

function Delete(props) {
  let { employee, refresh, setRefresh } = props;
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setModal(!modal);

  const DeleteEmployee = (id) => {
    API.delete(`/api/employee/${id}`)
      .then((res) => {
        setRefresh(!refresh);
        setModal(!modal);
        setLoading(true);
      })
      .then((err) => {
        setLoading(false);
      });
  };
  return (
    <div>
      <button
        className="btn btn-sm btn-danger d-block w-100 mb-2"
        onClick={toggle}
      >
        Delete
      </button>

      <Modal
        centered
        scrollable
        size="md"
        modalClassName="delete-modal"
        toggle={toggle}
        isOpen={modal}
        fade={true}
      >
        <ModalBody className="py-4">
          <h3 className="text-center">Are you sure?</h3>
          <p className="text-center  mt-3 mb-4 fs-4">
            Employee record will be deleted
          </p>
          <div className="btns-delete-modal">
            <Button
              type="button"
              onClick={toggle}
              color="primary"
              className="rounded-2"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="danger"
              className="rounded-2 ms-2"
              onClick={() => DeleteEmployee(employee?.id)}
              disabled={loading}
            >
              {loading && (
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              )}
              Delete
            </Button>{" "}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Delete;
