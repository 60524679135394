import { faFileExcel, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, NavLink } from "react-router-dom";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import moment from "moment";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Spinner,
  Table,
} from "reactstrap";
import Breadcrumbs from "../../components/Breadcrumbs";
import Container from "../../components/Container";
import AppLayout from "../../components/layouts/AppLayout";
import * as FileSaver from "file-saver";
import API from "../../utils/API";
import { CSSTransition } from "react-transition-group";
const Index = () => {
  const [vouchers, setVouchers] = useState([]);
  const [campuses, setCampuses] = useState([]);
  const [applicantsFirstNames, setApplicantsFirstNames] = useState([]);
  const [programsNames, setProgramsNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState();
  const [totalFee, setTotalFee] = useState(0);
  // const [accounts, setAccounts] = useState([]);
  const [formData, setFormData] = useState({
    start_date: "",
    end_date: "",
    fee_type: "",
    campus_id: "",
    account_id: "",
    paid_at: "",
    paid_start: "",
    paid_end: "",
    received_by: "",
  });

  useEffect(() => {
    fetchVouchers();
  }, []);

  const fetchVouchers = async (e) => {
    try {
      setLoading(true);
      const data = (await API.get("/api/report/voucher")).data;
      // const accountsData = (await API.get("/api/accounts")).data.data;
      setVouchers(data.vouchers);
      setCampuses(data.campuses);
      setTotalFee(data.total_collection);
      setApplicantsFirstNames(data.applicants_first_names); // Fetch first_name data
      setProgramsNames(data.programs_names); // Fetch program_name data
      // setAccounts(accountsData);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const resetVouchers = (e) => {
    e.preventDefault();
    setFormData({
      start_date: "",
      end_date: "",
      fee_type: "",
      campus_id: "",
      account_id: "",
      paid_at: "",
      paid_start: "",
      paid_end: "",
      received_by: "",
    });

    fetchVouchers();
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await API.post("/api/report/voucher/filters", formData);

      if (response) {
        setLoading(false);
        setVouchers(response.data.vouchers);
        setTotalFee(response.data.total_collection);
      }

      setLoading(false);
    } catch {
      setLoading(false);
    }
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangeCheckbox = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked ? "on" : "" });
  };

  const fileType = "xlsx";
  const exportData = () => {
    const fileData = vouchers.map((voucher) => {
      return {
        "Voucher No.": voucher?.id,
        "Applicant ID": "A-" + voucher?.chargeable_id,
        Campus: voucher?.relationships?.chargeable?.relationships?.campus?.name,
        "Fee Type": voucher?.fee_type,
        Total: voucher?.total,
        "Payment Method": voucher.account_details
          ? voucher.account_details?.name
          : "",
        "Received By": voucher.received_by ? voucher.received_by?.name : "",
        "Scholarship Type": voucher?.scholarship_type,
        "Scholarship Discount": voucher.scholarship_discount
          ? voucher.scholarship_discount + " %"
          : "N/A",
        "Created at": moment(voucher?.created_at).format("DD/MM/YY"),
        "Paid at": voucher.paid_at
          ? moment(voucher?.paid_at).format("DD/MM/YY")
          : "",
      };
    });
    const ws = XLSX.utils.json_to_sheet(fileData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const ExcelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([ExcelBuffer], { type: fileType });
    FileSaver.saveAs(data, "CanttVoucherReports" + ".xlsx");
  };

  return (
    <AppLayout>
      <Breadcrumbs
        title="Vouchers"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Vouchers",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3 d-flex align-items-center justify-content-between border-bottom pb-4">
            <h1 className="mb-0">Voucher Reports</h1>
          </div>
          {errors && (
            <p
              className="text-danger mt-2 text-right"
              style={{
                textAlign: "right",
                position: "absolute",
                right: "238px",
                top: "52px",
              }}
              for="name"
            >
              {errors}
            </p>
          )}
          <Card className="border-0 border-bottom">
            <CardBody>
              <div className="d-flex flex-wrap justify-content-between">
                <Form method="POST" action="#" onSubmit={handleSubmit}>
                  <FormGroup className="d-flex align-items-center">
                    <Label style={{ width: "200px" }} for="exampleEmail">
                      Start Date
                    </Label>
                    <Input
                      onChange={handleChange}
                      type="date"
                      name="start_date"
                      className="me-3"
                      value={formData.start_date}
                    />

                    <Label style={{ width: "200px" }} for="exampleEmail">
                      End Date
                    </Label>
                    <Input
                      onChange={handleChange}
                      name="end_date"
                      value={formData.end_date}
                      type="date"
                    />
                  </FormGroup>

                  <FormGroup className="d-flex align-items-center">
                    <select
                      onChange={handleChange}
                      value={formData.fee_type}
                      className="form-control"
                      name="fee_type"
                    >
                      <option value="">Select Fee Type</option>
                      <option value={"Admission Fee"}>Admission Fee</option>
                      <option value={"Application Fee"}>Application Fee</option>
                      <option value={"Semester Fee"}>Semester Fee</option>
                    </select>
                  </FormGroup>

                  <FormGroup className="d-flex align-items-center">
                    <select
                      onChange={handleChange}
                      value={formData.campus_id}
                      className="form-control"
                      name="campus_id"
                    >
                      <option value="">Select Campus</option>
                      {campuses.length > 0 ? (
                        campuses.map((campus, index) => (
                          <option value={campus.id}>{campus.name}</option>
                        ))
                      ) : (
                        <>
                          <option>No Data</option>
                        </>
                      )}
                    </select>
                  </FormGroup>

                  <FormGroup className="d-flex align-items-center">
                    <select
                      onChange={handleChange}
                      value={formData.account_id}
                      className="form-control"
                      name="account_id"
                    >
                      <option value="">Select Account</option>
                      <option value={4}>Bank Alfalah A/C</option>
                      <option value={7}>Cash at Tufail Campus</option>
                      {/* {accounts.length > 0 ? (
                        accounts.map((account, index) => (
                          <option value={account.id}>{account.name}</option>
                        ))
                      ) : (
                        <>
                          <option>No Data</option>
                        </>
                      )} */}
                    </select>
                  </FormGroup>
                  <FormGroup className="d-flex align-items-center">
                    <select
                      onChange={handleChange}
                      value={formData.received_by}
                      className="form-control"
                      name="received_by"
                    >
                      <option value="">Select Accountant</option>
                      <option value={11}>Adnan Khan</option>
                      <option value={12}>Ayan Asim</option>
                      <option value={13}>Amjad</option>
                      <option value={14}>Rana Muaaz</option>
                      <option value={16}>Rafaqat</option>
                    </select>
                  </FormGroup>
                  <FormGroup className="d-flex align-items-center">
                    <Label style={{ width: "200px" }} for="exampleEmail">
                      Paid Start Date
                    </Label>
                    <Input
                      onChange={handleChange}
                      type="date"
                      name="paid_start"
                      className="me-3"
                      value={formData.paid_start}
                    />

                    <Label style={{ width: "200px" }} for="exampleEmail">
                      Paid End Date
                    </Label>
                    <Input
                      onChange={handleChange}
                      name="paid_end"
                      value={formData.paid_end}
                      type="date"
                    />
                  </FormGroup>

                  <Input
                    onChange={handleChangeCheckbox}
                    className="me-2"
                    checked={formData.paid_at === "on"}
                    name="paid_at"
                    type="checkbox"
                  />
                  <Label for="exampleEmail">Paid</Label>

                  <div>
                    <button type="submit" className="btn btn-primary me-3">
                      Submit
                    </button>
                    <button onClick={resetVouchers} className="btn btn-primary">
                      Reset
                    </button>
                  </div>
                </Form>
                <div>
                  <div
                    className="btn btn-success rounded-0"
                    onClick={exportData}
                  >
                    <FontAwesomeIcon icon={faPrint} className="me-2" /> Export
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <CardBody>
            <Table hover responsive>
              <thead>
                <tr className="bg-shade-blue">
                  <th>#</th>
                  <th>Voucher No</th>
                  <th>Applicant ID</th>
                  <th>Full Name</th> {/* Add First Name here */}
                  <th>Program Name</th> {/* Add Program Name here */}
                  <th>Campus</th>
                  <th>Fee Type</th>
                  <th>Total</th>
                  <th>Payment Method</th>
                  <th>Received By</th> 
                  <th>Scholarship Type</th>
                  <th>Scholarship Discount</th>
                  <th>Created at</th>
                  <th>Paid at</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr className="text-center">
                    <td colSpan={10}>
                      <Spinner style={{ width: "30px", height: "30px" }} />
                    </td>
                  </tr>
                ) : vouchers.length > 0 ? (
                  vouchers.map((voucher, index) => (
                    <>
                      <tr key={`voucher-${voucher.id}`}>
                        <th>{index + 1}</th>
                        <th className="fw-normal">{voucher.id}</th>
                        <th className="fw-normal">
                          A-{voucher?.chargeable_id}
                        </th>
                        <th className="fw-normal">
  			{voucher?.relationships?.chargeable?.name ?? "N/A"}
			</th>
			<th className="fw-normal">
 			 {voucher?.relationships?.chargeable?.relationships?.program?.name ?? "N/A"}
			</th>
                        <th className="fw-normal">
                          {
                            voucher?.relationships?.chargeable?.relationships
                              ?.campus?.name
                          }
                        </th>
                        <td>{voucher?.fee_type}</td>
                        <td>{voucher?.total}</td>
                        <td>{voucher?.account_details?.name}</td>
                        <td>{voucher?.received_by?.name}</td>
                        <td>
                          {voucher.scholarship_type
                            ? voucher.scholarship_type
                            : "N/A"}
                        </td>
                        <td>
                          {voucher.scholarship_discount
                            ? voucher.scholarship_discount + " %"
                            : "N/A"}
                        </td>
                        <td>{moment(voucher.created_at).format("DD/MM/YY")}</td>
                        <td>
                          {voucher.paid_at
                            ? moment(voucher?.paid_at).format("DD/MM/YY")
                            : ""}
                        </td>
                      </tr>
                    </>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {loading ? (
                        <Spinner style={{ width: "30px", height: "30px" }} />
                      ) : (
                        "No vouchers Exists"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>

              {vouchers.length > 0 ? (
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      {" "}
                      <b> Total </b> = {totalFee}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              ) : (
                <></>
              )}
            </Table>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};

export default Index;
