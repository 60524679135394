import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Input, Spinner, Table } from 'reactstrap';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Container from '../../../components/Container';
import AppLayout from '../../../components/layouts/AppLayout';
import API from '../../../utils/API';
import Create from './Create';
import Delete from './Delete';
import Edit from './Edit';

const Index = () => {
  const [academicSessions, setAcademicSessions] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchAcademicSessions = async () => {
    try {
      const data = (await API.get('/api/academic-sessions')).data.data;
      setAcademicSessions(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const toggleAdmission = async (id) => {
    try {
      const data = academicSessions.find((session) => session.id == id);

      if (!parseInt(data.is_active)) {
        data.is_active = 1;
      } else {
        data.is_active = 0;
      }

      setLoading(true);

      await API.put(`api/academic-sessions/${id}`, data);

      fetchAcademicSessions();
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAcademicSessions();
  }, [refresh]);

  return (
    <AppLayout>
      <Breadcrumbs
        title='Academic Sessions'
        links={[
          {
            title: 'Dashboard',
            link: null,
          },
          {
            title: 'Academic Sessions',
            link: null,
          },
        ]}
      />
      <Container>
        <Card className='border-0 shadow mt-5' style={{ minHeight: '70vh' }}>
          <CardBody>
            <div className='d-flex align-items-center justify-content-between mb-4'>
              <h1 className='mb-0'>All Sessions</h1>
              <Create
                academicSession={academicSessions}
                fetchAcademicSessions={fetchAcademicSessions}
              />
            </div>
            <Table hover responsive>
              <thead>
                <tr className='bg-shade-blue'>
                  <th>#</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Year</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Admissions</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {academicSessions.length > 0 && !loading ? (
                  academicSessions.map((academicSession, index) => (
                    <tr
                      key={`academicSession-${academicSession.id}`}
                      style={{ verticalAlign: 'middle' }}
                    >
                      <th scope='row'>{index + 1}</th>
                      <td>{academicSession.name}</td>
                      <td>{academicSession.type}</td>
                      <td>{academicSession.year}</td>
                      <td>{academicSession.start_date}</td>
                      <td>{academicSession.end_date}</td>
                      <td>
                        <div class='form-check form-switch'>
                          <input
                            class='form-check-input'
                            type='checkbox'
                            onChange={(e) =>
                              toggleAdmission(academicSession.id)
                            }
                            checked={parseInt(academicSession.is_active)}
                            id=''
                          />
                        </div>
                      </td>
                      <td>
                        <Edit
                          academicSession={academicSession}
                          fetchAcademicSessions={fetchAcademicSessions}
                        />
                        <Delete
                          academicSession={academicSession}
                          refresh={refresh}
                          setRefresh={setRefresh}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      {loading ? (
                        <Spinner style={{ width: '30px', height: '30px' }} />
                      ) : (
                        'No Campuses Exists'
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};

export default Index;
