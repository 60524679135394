import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Spinner, Table } from "reactstrap";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Container from "../../../components/Container";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Create from "./Create";
import Delete from "./Delete";
import Edit from "./Edit";

const Index = () => {
  const [departments, setDepartments] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchDepartments = async () => {
    try {
      const data = (await API.get("/api/departments")).data.data;
      setDepartments(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, [refresh]);

  return (
    <AppLayout>
      <Breadcrumbs
        title="Departments"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Departments",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <CardBody>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h1 className="mb-0">All Departments</h1>
              <Create
                department={departments}
                fetchDepartments={fetchDepartments}
              />
            </div>
            <Table hover responsive>
              <thead>
                <tr className="bg-shade-blue">
                  <th>#</th>
                  <th>Name</th>
                  <th>Active</th>
                  <th>Academic</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {departments.length > 0 ? (
                  departments.map((department, index) => (
                    <tr
                      key={`department-${department.id}`}
                      style={{ verticalAlign: "middle" }}>
                      <th scope="row">{index + 1}</th>
                      <td>{department.name}</td>
                      {department.is_active == 1 ? <td>Yes</td> : <td>No</td>}
                      {department.is_academic == 1 ? <td>Yes</td> : <td>No</td>}
                      <td>
                        {moment(department.created_at).format("DD/MM/YY")}
                      </td>
                      <td>
                        {moment(department.updated_at).format("DD/MM/YY")}
                      </td>
                      <td>
                        <Edit
                          department={department}
                          fetchDepartments={fetchDepartments}
                        />
                        <Delete
                          department={department}
                          refresh={refresh}
                          setRefresh={setRefresh}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {loading ? (
                        <Spinner style={{ width: "30px", height: "30px" }} />
                      ) : (
                        "No Departments Exists"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};

export default Index;
