import "../../../css/modal.css";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";

import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  NavLink,
  Spinner,
  Table,
} from "reactstrap";
import API from "../../../utils/API";

const Enrollment = ({
  showModal,
  setShowModal,
  selectedTimetable,

  fetchTimetable: fetchTimetable,
}) => {
  // Modal open state
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const [enrollments, setEnrollments] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setMeta] = useState(1);

  // Toggle for Modal
  const toggle = () => setShowModal(!showModal);

  const enrollStudent = async (enrollment) => {
    try {
      let enrollmentData = {
        timetable_id: selectedTimetable.id,
        campus_id: selectedTimetable.campus_id,
        is_repeat: 1,
        is_active: selectedTimetable.is_active,
        roll_number: enrollment.roll_number,
      };

      const data = (await API.post("/api/enrollments", enrollmentData)).data
        .data;

      toast("Student Enrolled successfull!");
      fetchEnrollments(selectedTimetable, currentPage);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchEnrollments = async (timetable, currentPage, filter) => {
    try {
      setLoading(true);

      let url = `/api/bulk-enrollment?timetable_id=${timetable.id}`;
      if (filter) {
        url = url + `&search=${filterValue}`;
        setCurrentPage(1);
      } else {
        url = url + `&page=${currentPage}`;
      }
      const data = (await API.get(url)).data;
      setLoading(false);

      let cPage = 1;
      if (filter) {
        cPage = 1;
      } else {
        cPage = currentPage;
      }
      setEnrollments(
        data.data.map((item, index) => {
          item.index = index + 1 + (cPage - 1) * 20;
          return item;
        })
      );

      setMeta(data.meta.last_page);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageClick = async (page) => {
    fetchEnrollments(selectedTimetable, page.selected + 1);
    setCurrentPage(page.selected + 1);
  };

  useEffect(() => {
    if (selectedTimetable) fetchEnrollments(selectedTimetable, currentPage);
  }, [selectedTimetable]);

  const handleFilter = (e) => {
    setFilterValue(e.target.value);
  };

  if (!showModal) return;

  return (
    <div>
      <NavLink className="rounded-pill bg-none" onClick={toggle}>
        Enroll Student
      </NavLink>

      <Modal
        modalClassName="custommodal"
        centered
        scrollable
        size="xl"
        toggle={toggle}
        isOpen={showModal}
        fade={true}>
        <ModalHeader toggle={toggle}>Enroll Student</ModalHeader>
        <div className="row">
          <div className="col-md-4">
            <FormGroup className="mt-4 ms-3">
              <Input
                className="rounded-2"
                placeholder="search"
                value={filterValue}
                onChange={(e) => handleFilter(e, selectedTimetable)}
              />
            </FormGroup>
          </div>
          <div className="col-md-1 mt-4 p-0">
            <button
              className="btn btn-sm btn-primary d-block w-100 mb-2 btn btn-primary p-2"
              type="button"
              onClick={() =>
                fetchEnrollments(selectedTimetable, currentPage, true)
              }>
              Search
            </button>
          </div>
        </div>

        <Form>
          <ModalBody>
            <Table hover responsive>
              <thead>
                <tr className="bg-shade-blue">
                  <th style={{ width: "120px" }}>#</th>
                  <th>Roll Number</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {enrollments.length > 0 ? (
                  enrollments.map((enrollment, index) => (
                    <tr
                      key={`enrollment-${enrollment.id}`}
                      style={{ verticalAlign: "middle" }}>
                      <th style={{ width: "120px" }} scope="row">
                        {enrollment.index}
                      </th>
                      <td>{enrollment.roll_number}</td>
                      <td className="text-center">
                        <Button
                          onClick={() => enrollStudent(enrollment)}
                          type="button"
                          color="primary"
                          className="rounded-2">
                          Enroll
                        </Button>{" "}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {loading ? (
                        <Spinner style={{ width: "30px", height: "30px" }} />
                      ) : (
                        "No Students Exists"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
              {enrollments.length > 0 ? (
                <div className="w-100 d-table-caption pt-5">
                  <ReactPaginate
                    breakLabel={"..."}
                    nextLabel={">>"}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={meta}
                    previousLabel={"<<"}
                    margibPageDisply={3}
                    containerClassName={
                      "Pagination d-flex justify-content-center list-unstyled w-100"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              ) : (
                <tr>
                  <td colSpan={10} className="text-center"></td>
                </tr>
              )}
            </Table>
          </ModalBody>
        </Form>
      </Modal>
    </div>
  );
};
export default Enrollment;
