import "../../../css/modal.css";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import API from "../../../utils/API";
import axios from "axios";

const Edit = ({ day, fetchDays }) => {
  // Modal open state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const [data, setData] = useState({
    days: "",
    is_active: 0,
  });

  useEffect(() => {
    setData(day);
  }, []);

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await API.put(`api/days/${data.id}`, data);
      setLoading(false);
      fetchDays();
      toggle();
      setErrors([]);
    } catch (error) {
      setErrors(
        error?.response?.data?.errors ? error.response.data.errors : []
      );
      setLoading(false);
    }
  }
  const handleChangeCheckbox = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.checked ? 1 : 0 });
  };
  const handleChange = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Button
        color="primary"
        className="btn btn-sm btn-primary d-block w-100 mb-2"
        onClick={toggle}>
        Edit
      </Button>

      <Modal
        centered
        scrollable
        size="lg"
        toggle={toggle}
        isOpen={modal}
        fade={true}>
        <ModalHeader toggle={toggle}>Update Day</ModalHeader>
        <Form method="POST" action="#" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for="days" className="label-required">
                Days
              </Label>
              <Input
                className="rounded-2"
                id="days"
                type="text"
                name="days"
                maxLength={255}
                value={data.days}
                onChange={handleChange}
                required
                autoFocus
              />
              {errors.name && (
                <Label className="text-danger mt-2" for="name">
                  {errors.name}
                </Label>
              )}
            </FormGroup>

            <FormGroup className="mt-3">
              <Label for="is_active" className="label-required">
                Active
              </Label>
              <Input
                className="rounded-2"
                id="is_active"
                type="checkbox"
                name="is_active"
                maxLength={255}
                value={data.is_active}
                checked={data.is_active}
                onChange={handleChangeCheckbox}
                autoFocus
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggle}
              color="light"
              className="rounded-2">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="rounded-2"
              disabled={loading}>
              {loading && (
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              )}
              Update
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Edit;
