import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AppLayout from "../../../components/layouts/AppLayout";
import Container from "../../../components/Container";
import { Card, CardBody, Table, Spinner, Button, Form } from "reactstrap";
import API from "../../../utils/API";
import Create from "./CreatePayroll";
import Edit from "./EditPayroll";
import Delete from "./DeletePayroll";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const PayrollAuditing = (props) => {
  const [payroll, setpayroll] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const fetchPayroll = async () => {
    try {
      const data = (await API.get(`/api/payroll`)).data?.data;
      setpayroll(data);
      setLoading(true);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPayroll();
  }, [refresh]);

  const fileType = "xlsx";
  const exportData = () => {
    const ws = XLSX.utils.json_to_sheet(payroll);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const ExcelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([ExcelBuffer], { type: fileType });
    FileSaver.saveAs(data, "myfile" + ".xlsx");
  };

  return (
    <AppLayout>
      <Breadcrumbs
        title="Auditing Payroll"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Auditing Payroll",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3 d-flex align-items-center justify-content-between border-bottom">
            <h1 className="mb-0">Auditing Payroll</h1>
            <Create payroll={payroll} fetchPayroll={fetchPayroll} />
          </div>
          <div className="border-bottom">
            <button
              onClick={exportData}
              className="btn btn-primary rounded-0 float-end me-3 mt-3 mb-3 rounded-pill"
            >
              <FontAwesomeIcon icon={faFileExcel} className="me-2" />
              Export Payroll List
            </button>
          </div>

          <CardBody>
            <Table hover responsive>
              <thead>
                <tr className="bg-shade-blue">
                  <th>#</th>
                  <th>Employee Id</th>
                  <th>Net Salary</th>
                  <th>Gross Salary</th>
                  <th>Income Tax</th>
                  <th>Loan</th>
                  <th>Advance</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {payroll?.length > 0 ? (
                  payroll?.map((pay, index) => (
                    <tr className="table-row" key={`pay-${pay?.id}`}>
                      <th scope="row">{index + 1}</th>
                      <td>{pay?.employee_id}</td>
                      <td>{pay?.net_salary}</td>
                      <td>{pay?.gross_salary}</td>
                      <td>{pay?.income_tax}</td>
                      <td>{pay?.loan}</td>
                      <td>{pay?.advance}</td>
                      <td>
                        <div className="w-100">
                          <Edit
                            pay={pay}
                            id={pay.id}
                            fetchPayroll={fetchPayroll}
                          />
                        </div>
                        <div className="mt-2">
                          <Delete
                            pay={pay}
                            refresh={refresh}
                            setRefresh={setRefresh}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {loading ? (
                        <Spinner style={{ width: "30px", height: "30px" }} />
                      ) : (
                        "No Payroll Data Exists"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};

export default PayrollAuditing;
