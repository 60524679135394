import React, { useEffect, useState } from "react";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { Card, CardBody, Container, Col, Row, Button } from "reactstrap";
import { Space, Table, Tag } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Create from "./Create";
import Edit from "./Edit";
import { Form, Input } from "antd";
import { toast } from "react-toastify";
export const AddResult = () => {
  const location = useLocation();
  const { exam_type_id, result_id } = location.state;

  console.log(result_id);

  const [marks, setMarks] = useState();
  const [refresh, setRefresh] = useState(false);
  const [enrolledStudents, setEnrolledStudents] = useState([]);
  const navigation = useNavigate();

  const paramData = useParams();
  const onFinish = (values) => {
    fetchMarksgData(values);
  };

  const onFinishFailed = (error) => {
    console.log(error);
  };

  const fetchMarksgData = async (data) => {
    try {
      const response = await API.post(`/api/marks`, data);
      toast.success(response.data.message);

      navigation(-1);
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    }
  };
  const fetchMarks = async () => {
    try {
      const response = await (
        await API.get(`/api/marks/${paramData.id}`)
      ).data.data;
      console.log(response);
    } catch (error) {
      console.error(error);
      // toast.error(error.response.data.message);
    }
  };
  const studentAttendance = async () => {
    try {
      const response = (await API.get(`/api/enrollment/${paramData.id}`)).data
        .data;
      setEnrolledStudents(response);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    // fetchMarksgData();
    studentAttendance();
    fetchMarks();
  }, [refresh]);

  return (
    <AppLayout>
      <Breadcrumbs
        title="Grading"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Grading",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3 d-flex align-items-center justify-content-between border-bottom">
            <h1 className="mb-0">Add Marks</h1>
            {/* <Create
              //   gradingData={gradingData}
              //   setGradingData={setGradingData}
              setRefresh={setRefresh}
              refresh={refresh}
            /> */}
          </div>
          <CardBody>
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off">
              <Form.Item
                initialValue={result_id}
                style={{ display: "none" }}
                name={"result_id"}>
                <input style={{ display: "none" }} />
              </Form.Item>{" "}
              <Form.Item
                initialValue={exam_type_id}
                style={{ display: "none" }}
                name={"exam_type_id"}>
                <input style={{ display: "none" }} />
              </Form.Item>{" "}
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Sr</th>
                    <th scope="col">Roll No.</th>
                    <th scope="col">Marks</th>
                  </tr>
                </thead>
                <tbody>
                  {enrolledStudents?.map((data, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data?.relationships?.student?.roll_number}</td>

                      <td>
                        <Form.Item
                          initialValue={
                            data?.relationships?.student?.relationships
                              ?.applicant?.name
                          }
                          style={{ display: "none" }}
                          name={["results", index, "name"]}>
                          <input style={{ display: "none" }} />
                        </Form.Item>
                        <Form.Item
                          initialValue={
                            data?.relationships?.student?.roll_number
                          }
                          style={{ display: "none" }}
                          name={["results", index, "roll_number"]}>
                          <input style={{ display: "none" }} />
                        </Form.Item>
                        <Form.Item
                          name={["results", index, "marks"]}
                          rules={[
                            {
                              required: true,
                              message: "Please Type Marks!",
                            },
                          ]}>
                          <Input placeholder="Enter Marks" />
                        </Form.Item>
                      </td>
                      <td className="d-flex">
                        {" "}
                        {/* <Edit
                        id={data?.id}
                        data={data}
                        // setGradingData={setGradingData}
                        setRefresh={setRefresh}
                        refresh={refresh}
                      /> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Button type="primary">Submit</Button>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};
