import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Input, Select } from "antd";

import API from "../../../utils/API";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

const Edit = ({ data, refresh, setRefresh }) => {
  // Modal open state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const onFinish = (values) => {
    updateGrade(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const toggle = () => setModal(!modal);

  const updateGrade = async (e) => {
    try {
      setLoading(true);
      const response = await API.put(`/api/grading/${data.id}`, e);

      setModal(false);
      setLoading(false);
      setRefresh(!refresh);
      toast.success("Grade Updated Successfully");
    } catch (error) {
      toast.success(error.response.data.message);
    }
  };

  return (
    <div>
      <Button color="primary" className="rounded-pill btn-sm" onClick={toggle}>
        <FontAwesomeIcon icon={faEdit} className="me-2" />
        Edit Grade
      </Button>
      <Modal
        centered
        scrollable
        size="md"
        modalClassName="delete-modal"
        toggle={toggle}
        isOpen={modal}
        fade={true}
      >
        <ModalBody className="py-4">
          <h3 className="text-center">Modify Grade</h3>
          <Form name="control-ref" layout="vertical" onFinish={onFinish}>
            <Form.Item
              initialValue={params?.id}
              style={{ display: "none" }}
              name="timetable_id"
            >
              <input style={{ display: "none" }} />
            </Form.Item>
            <Form.Item
              name="exam_type_id"
              label="Exam Type"
              initialValue={data.exam_type_id}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Select a option and change input text above"
                disabled
              >
                <option selected value={data?.relationships?.exam_type?.id}>
                  {data?.relationships?.exam_type?.type
                    .charAt(0)
                    .toUpperCase() +
                    data?.relationships?.exam_type?.type.slice(1)}
                </option>
              </Select>
            </Form.Item>
            <Form.Item
              name="total_weightage"
              label="Weightage"
              initialValue={data.total_weightage}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="total_marks"
              label="Total Marks"
              initialValue={data.total_marks}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <div className="btns-delete-modal mt-3">
              <Button
                type="button"
                onClick={toggle}
                color="secondary"
                className="rounded-2 m-2"
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                color="primary"
                className="m-2 btn btn-primary"
              >
                Update
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Edit;
