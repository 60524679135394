import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import PropTypes from "prop-types";

const Breadcrumbs = ({ title, links }) => {
  return (
    <div className="shadow bg-white px-5 py-3 w-100 d-flex align-items-center justify-content-between">
      <h1 className="mb-0 fw-bold">{title}</h1>
      <Breadcrumb
        className="bg-secondary d-flex px-3 py-2 rounded"
        listClassName="mb-0"
      >
        {links.map((link, index) => (
          <BreadcrumbItem key={`breadcrumb-${index}`} active={link.is_active}>
            {link.href ? (
              <a href={link.href}>{link.title}</a>
            ) : (
              <span>{link.title}</span>
            )}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </div>
  );
};

Breadcrumbs.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string,
    })
  ).isRequired,
};

export default Breadcrumbs;
