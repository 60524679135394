import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Spinner, Table } from "reactstrap";
import Breadcrumbs from "../../components/Breadcrumbs";
import Container from "../../components/Container";
import AppLayout from "../../components/layouts/AppLayout";
import API from "../../utils/API";
import Create from "./Create";

const Index = () => {
  const [accounts, setAccounts] = useState([]);

  const [loading, setLoading] = useState(true);

  const fetchAccounts = async () => {
    try {
      const data = (await API.get("/api/accounts")).data.data;

      setAccounts(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  return (
    <AppLayout>
      <Breadcrumbs
        title="Chart of Accounts"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Accounts",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <CardBody>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h1 className="mb-0">All Acccounts</h1>
              <Create accounts={accounts} fetchAccounts={fetchAccounts} />
            </div>
            <Table hover responsive>
              <thead>
                <tr className="bg-shade-blue">
                  <th>#</th>
                  <th>Account ID</th>
                  <th>Name</th>
                  <th>Account Type</th>
                  <th>Account Detail Type</th>
                  <th>Balance</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {accounts.length > 0 ? (
                  accounts.map((account, index) => (
                    <tr
                      key={`account-${account.id}`}
                      style={{ verticalAlign: "middle" }}
                    >
                      <th scope="row">{index + 1}</th>
                      <td>{account.account_id}</td>
                      <td>{account.name}</td>
                      <td>{account.relationships.account_type.name}</td>
                      <td>
                        {account.relationships.account_detail_type
                          ? account.relationships.account_detail_type.name
                          : "None"}
                      </td>
                      <td></td>
                      <td>{moment(account.created_at).format("DD/MM/YY")}</td>
                      <td>{moment(account.updated_at).format("DD/MM/YY")}</td>
                      <td>
                        <button className="btn btn-sm btn-primary d-block w-100 mb-2">
                          Edit
                        </button>
                        <button className="btn btn-sm btn-danger d-block w-100 mb-2">
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {loading ? (
                        <Spinner style={{ width: "30px", height: "30px" }} />
                      ) : (
                        "No Accounts Exists"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};

export default Index;
