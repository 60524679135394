import React, { useEffect } from "react";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { MidTerm } from "./MidTerm";
import { FinalTerm } from "./FinalTerm";

export const QuestionBankManagement = () => {
  return (
    <AppLayout>
      <Breadcrumbs
        title="Question Bank Management"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Question Bank Management",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3 d-flex align-items-center justify-content-between border-bottom">
            <h2 className="mb-0">Question Bank Management</h2>
          </div>
          <CardBody>
            <ul class="nav nav-tabs timeTableNav" id="mainTab" role="tablist">
              <li class="nav-item d-grid" role="presentation">
                <button
                  class="nav-link active"
                  id="midterm-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#midterm"
                  type="button"
                  role="tab"
                  aria-controls="midterm"
                  aria-selected="true"
                >
                  Midterm Questions
                </button>
              </li>
              <li class="nav-item d-grid" role="presentation">
                <button
                  class="nav-link"
                  id="finalterm-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#finalterm"
                  type="button"
                  role="tab"
                  aria-controls="finalterm"
                  aria-selected="false"
                >
                  Finalterm Questions
                </button>
              </li>
            </ul>
            <div class="tab-content" id="mainTabContent">
              <div
                class="tab-pane fade show active "
                id="midterm"
                role="tabpanel"
                aria-labelledby="midterm-tab"
              >
                <MidTerm style={{ marginTop: "20px" }} />
              </div>
              <div
                class="tab-pane fade mb-5"
                id="finalterm"
                role="tabpanel"
                aria-labelledby="finalterm-tab"
              >
                <FinalTerm />
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};
