import React, { useEffect, useState } from "react";
import AppLayout from "../../components/layouts/AppLayout";
import API from "../../utils/API";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import moment from "moment";
import FullscreenLoader from "../../components/FullscreenLoader";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const LectureTimetable = () => {
  const auth = useSelector((state) => state.auth);

  const [timeTable, setTimeTable] = useState(null);
  const [pastCourses, setPastCourses] = useState(null);
  const fetchLectureTimetable = async () => {
    try {
      const response = (await API.get("/api/timetable")).data.data;
      setTimeTable(response);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchPastLectures = async () => {
    try {
      const response = (await API.get("/api/timetable/past-courses")).data.data;
      setPastCourses(response);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchLectureTimetable();
    fetchPastLectures();
  }, []);

  return (
    <AppLayout>
      {/* <Breadcrumbs
        title="Lecture Timetable"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Lecture Timetable",
            link: null,
          },
        ]}
      /> */}
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3 d-flex align-items-center justify-content-between border-bottom">
            <h1 className="mb-0">Lecture Timetable</h1>
          </div>
          <CardBody>
            <ul className="nav nav-tabs timeTableNav" id="myTab" role="tablist">
              <li className="nav-item d-grid" role="presentation">
                <button
                  className="nav-link active"
                  id="currentCourses-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#currentCourses"
                  type="button"
                  role="tab"
                  aria-controls="currentCourses"
                  aria-selected="true"
                >
                  Current Courses
                </button>
              </li>
              <li className="nav-item d-grid" role="presentation">
                <button
                  className="nav-link"
                  id="pastCourses-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#pastCourses"
                  type="button"
                  role="tab"
                  aria-controls="pastCourses"
                  aria-selected="false"
                >
                  Past Courses
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="currentCourses"
                role="tabpanel"
                aria-labelledby="currentCourses-tab"
              >
                {!timeTable ? (
                  <FullscreenLoader />
                ) : (
                  <div>
                    {timeTable?.map((timetable, index) => (
                      <Col>
                        <div className="course shadow">
                          <Row>
                            <Col>
                              <h2>
                                {timetable?.relationships?.course?.course_code}{" "}
                                - {timetable?.relationships?.course?.name}
                                <p className="small mb-0">
                                  (
                                  {moment(
                                    timetable?.relationships?.detail
                                      ?.relationships?.slot?.start,
                                    "HH:mm a"
                                  ).format("HH:mm a")}{" "}
                                  -
                                  {moment(
                                    timetable?.relationships?.detail
                                      ?.relationships?.slot?.end,
                                    "HH:mm a"
                                  ).format("HH:mm a")}
                                  )
                                </p>
                                <p className="small">
                                  Days:{" "}
                                  {
                                    timetable?.relationships?.detail
                                      ?.relationships?.day?.days
                                  }
                                </p>
                              </h2>
                              <p>{auth.authData.data.name}</p>
                            </Col>
                            <Col>
                              <div className="mb-1 bg-light d-flex justify-content-between px-2">
                                {" "}
                                <p className="mb-0">Semester:</p>{" "}
                                <p className="mb-0">{timetable?.semester}</p>
                              </div>
                              <div className="mb-1 bg-light d-flex justify-content-between px-2">
                                {" "}
                                <p className="mb-0">Department:</p>{" "}
                                <p className="mb-0">
                                  {timetable?.relationships?.department?.name}
                                </p>
                              </div>
                              <div className="mb-1 bg-light d-flex justify-content-between px-2">
                                {" "}
                                <p className="mb-0">Section:</p>{" "}
                                <p className="mb-0">
                                  {" "}
                                  Section:{" "}
                                  {timetable?.relationships?.section?.name}
                                </p>
                              </div>
                              <div className="mb-1 bg-light d-flex justify-content-between px-2">
                                Block (
                                {
                                  timetable?.relationships?.detail
                                    ?.relationships?.room?.block_id
                                }{" "}
                                ) - Room (
                                {
                                  timetable?.relationships?.detail
                                    ?.relationships?.room?.name
                                }
                                )
                              </div>
                            </Col>
                          </Row>
                          <div className="courseIcons text-center">
                            <Row>
                              <Col>
                                <Link
                                  to={`/student-attendance/${timetable?.id}`}
                                  className="btn btn-primary d-grid btn-sm"
                                >
                                  Attendance
                                </Link>
                              </Col>
                              <Col>
                                <Link
                                  to={`/grading/${timetable?.id}`}
                                  className="btn btn-primary d-grid btn-sm"
                                >
                                  Grading
                                </Link>
                              </Col>
                              <Col>
                                <a
                                  href=""
                                  className="btn btn-primary d-grid btn-sm"
                                >
                                  Notice Board{" "}
                                </a>
                              </Col>
                              <Col>
                                <Link
                                  to={`/question-bank-management/${timetable?.id}`}
                                  className="btn btn-primary d-grid btn-sm"
                                >
                                  Question Bank
                                </Link>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </div>
                )}
              </div>
              <div
                className="tab-pane fade"
                id="pastCourses"
                role="tabpanel"
                aria-labelledby="pastCourses-tab"
              >
                {!pastCourses ? (
                  <FullscreenLoader />
                ) : (
                  <div>
                    {pastCourses?.map((pastCourses, index) => (
                      <div className="course shadow">
                        <Row>
                          <Col>
                            <h2>
                              {pastCourses?.campus_id} -
                              {pastCourses?.relationships?.course?.name}
                              <p className="small mb-0">
                                (
                                {moment(
                                  pastCourses?.relationships?.detail
                                    ?.relationships?.slot?.start,
                                  "HH:mm a"
                                ).format("HH:mm a")}{" "}
                                -
                                {moment(
                                  pastCourses?.relationships?.detail
                                    ?.relationships?.slot?.end,
                                  "HH:mm a"
                                ).format("HH:mm a")}
                                )
                              </p>
                              <p className="small">
                                {
                                  pastCourses?.relationships?.detail
                                    ?.relationships?.day?.days
                                }
                              </p>
                            </h2>
                            <p>{auth.authData.data.name}</p>
                          </Col>
                          <Col>
                            <div className="mb-1 bg-light d-flex justify-content-between px-2">
                              {" "}
                              <p className="mb-0">Semester:</p>{" "}
                              <p className="mb-0">{pastCourses?.semester}</p>
                            </div>
                            <div className="mb-1 bg-light d-flex justify-content-between px-2">
                              {" "}
                              <p className="mb-0">Department:</p>{" "}
                              <p className="mb-0">
                                {pastCourses?.relationships?.department?.name}
                              </p>
                            </div>
                            <div className="mb-1 bg-light d-flex justify-content-between px-2">
                              {" "}
                              <p className="mb-0">Section:</p>{" "}
                              <p className="mb-0">
                                {" "}
                                Section:{" "}
                                {pastCourses?.relationships?.section?.name}
                              </p>
                            </div>
                            <div className="mb-1 bg-light d-flex justify-content-between px-2">
                              Block (
                              {
                                pastCourses?.relationships?.detail
                                  ?.relationships?.room?.block_id
                              }{" "}
                              ) - Room (
                              {
                                pastCourses?.relationships?.detail
                                  ?.relationships?.room?.name
                              }
                              )
                            </div>
                          </Col>
                        </Row>
                        <div className="courseIcons text-center">
                          <Row>
                            <Col>
                              <a
                                href=""
                                className="btn btn-primary d-grid btn-sm"
                              >
                                {" "}
                                Attendance{" "}
                              </a>
                            </Col>
                            <Col>
                              <a
                                href=""
                                className="btn btn-primary d-grid btn-sm"
                              >
                                {" "}
                                LMS{" "}
                              </a>
                            </Col>

                            <Col>
                              <a
                                href=""
                                className="btn btn-primary d-grid btn-sm"
                              >
                                Notice Board{" "}
                              </a>
                            </Col>
                            <Col>
                              <a
                                href=""
                                className="btn btn-primary d-grid btn-sm"
                              >
                                {" "}
                                Question Bank{" "}
                              </a>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};
