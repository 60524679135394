import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/modal.css";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import API from "../../../utils/API";

const Create = ({ department, fetchDepartments }) => {
  // Modal open state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const defaltData = {
    name: "",
  };
  const [data, setData] = useState(defaltData);

  // Toggle for Modal
  const toggle = () => setModal(!modal);
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await API.post("/api/departments", data);
      setLoading(false);
      fetchDepartments();
      toggle();
      setData(defaltData);
      setErrors([]);
    } catch (error) {
      setErrors(
        error?.response?.data?.errors ? error.response.data.errors : []
      );
      setLoading(false);
    }
  }
  const handleChangeCheckbox = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.checked ? 1 : 0 });
  };
  const handleChange = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Button color="primary" className="rounded-pill" onClick={toggle}>
        <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
        Create New
      </Button>

      <Modal
        centered
        scrollable
        size="lg"
        toggle={toggle}
        isOpen={modal}
        fade={true}>
        <ModalHeader toggle={toggle}>Add Department</ModalHeader>
        <Form method="POST" action="#" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for="name" className="label-required">
                Name
              </Label>
              <Input
                className="rounded-2"
                id="name"
                type="text"
                name="name"
                maxLength={255}
                value={data.name}
                onChange={handleChange}
                required
                autoFocus
              />
              {errors.name && (
                <Label className="text-danger mt-2" for="name">
                  {errors.name}
                </Label>
              )}
            </FormGroup>
            <div className="row mt-4">
              <div className="col-md-4">
                <FormGroup>
                  <Label for="is_active" className="label-required">
                    Active
                  </Label>
                  <Input
                    className="rounded-2"
                    id="is_active"
                    type="checkbox"
                    name="is_active"
                    maxLength={255}
                    value={data.is_active}
                    onChange={handleChangeCheckbox}
                    autoFocus
                  />
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label for="is_academic" className="label-required">
                    Academic
                  </Label>
                  <Input
                    className="rounded-2"
                    id="is_academic"
                    type="checkbox"
                    name="is_academic"
                    maxLength={255}
                    value={data.is_academic}
                    onChange={handleChangeCheckbox}
                    autoFocus
                  />
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggle}
              color="light"
              className="rounded-2">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="rounded-2"
              disabled={loading}>
              {loading && (
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              )}
              Submit
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Create;
