import React, { useEffect, useState } from "react";
import GuestLayout from "../../components/layouts/GuestLayout";
import Logo from "../../svgs/Logo";
import {
  Card,
  CardBody,
  Input,
  Form,
  FormGroup,
  Button,
  Label,
  UncontrolledAlert,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/auth";
import { CLEAR_AUTH_ERRORS } from "../../constants/actionTypes";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const redirect = searchParams.get("redirect");

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.authData) navigate(redirect);
  }, [auth.authData, navigate, redirect]);

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(data));
  };

  const handleChange = (e) => {
    if (auth.error)
      dispatch({
        type: CLEAR_AUTH_ERRORS,
      });

    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <GuestLayout>
      <Card style={{ minWidth: "500px", maxWidth: "100%" }}>
        <CardBody>
          <Link to="/" className="d-block text-center pt-4 mb-4">
            <Logo />
          </Link>

          {auth.error && (
            <UncontrolledAlert color="danger" toggle={function noRefCheck() {}}>
              {auth.error}
            </UncontrolledAlert>
          )}

          <Form
            method="POST"
            action="#"
            onSubmit={handleSubmit}
            className="mb-4">
            <FormGroup>
              <Label for="email" className="label-required">
                Email
              </Label>

              <Input
                id="email"
                type="email"
                name="email"
                value={data.email}
                onChange={handleChange}
                autoComplete="email"
                required
                autoFocus
              />
            </FormGroup>
            <FormGroup className="mt-4">
              <Label for="password" className="label-required">
                Password
              </Label>
              <Input
                id="password"
                type="password"
                name="password"
                onChange={handleChange}
                value={data.password}
                required
                autoComplete="current-password"
              />
            </FormGroup>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <a className="text-muted small" href="#">
                Forgot your password?
              </a>
              <Button type="submit" color="primary" disabled={auth.loading}>
                {auth.loading && (
                  <Spinner className="me-2" size="sm">
                    Loading...
                  </Spinner>
                )}
                Login
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </GuestLayout>
  );
};

export default Login;
