import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/modal.css";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import API from "../../../utils/API";

function Delete(props) {
  // Modal open state
  let { academicSession, refresh, setRefresh } = props;
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  const deleteCampus = (id) => {
    API.delete(`/api/academic-sessions/${id}`).then((res) => {
      setRefresh(!refresh);
      setModal(!modal);
    });
  };
  return (
    <div>
      <button
        className="btn btn-sm btn-danger d-block w-100 mb-2"
        onClick={toggle}>
        Delete
      </button>

      <Modal
        centered
        scrollable
        size="md"
        modalClassName="delete-modal"
        toggle={toggle}
        isOpen={modal}
        fade={true}>
        <ModalBody className="py-4">
          <h3 className="text-center">Are you sure?</h3>
          <p className="text-center  mt-3 mb-4 fs-4">
            Academic Session record will be deleted
          </p>
          <div className="btns-delete-modal">
            <Button
              type="button"
              onClick={toggle}
              color="primary"
              className="rounded-2">
              Cancel
            </Button>
            <Button
              type="submit"
              color="danger"
              className="rounded-2 ms-2"
              onClick={() => deleteCampus(academicSession.id)}
              disabled={loading}>
              {loading && (
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              )}
              Delete
            </Button>{" "}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Delete;
