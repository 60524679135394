import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import FullscreenLoader from "../components/FullscreenLoader";

const ProtectedRoute = (props) => {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();

  let redirect = "/";

  if (location.pathname !== "/") redirect = `/?redirect=${location.pathname}`;

  if (!auth.authData) {
    return <Navigate to={redirect} replace />;
  } else {
    return props.component;
  }
};

export default ProtectedRoute;
