import React, { useState, useEffect } from "react";
import {
  Card,
  Container,
  Input,
  CardBody,
  Table,
  Spinner,
  Button
} from "reactstrap";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";

function Transcript() {
  const [transcript, setTranscript] = useState([]);
  const [studentDetail, setstudentDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rollno, setrollno] = useState("");
 
  const searchStudent = async () => {
    try {
      const data = (await API.get(`/api/transcript?roll_number=${rollno}`)).data;
      setTranscript(data?.transcript);
      setstudentDetail(data);


    } catch (error) {}
  };



  const print = () => {
    window.print();
  };

  useEffect(() => {
    searchStudent();
  }, [])
  
  
  const handleChange = (ev) =>{

    setrollno(ev.target.value)
    
  }

  const keyPress = () =>{

      searchStudent();
    
  }

 
  
  return (
    <AppLayout>
      <Breadcrumbs
        title="Transcript"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Transcript",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="shadow border-0 mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3 d-flex align-items-center justify-content-between border-bottom">
            <h1 className="mb-0">Transcript</h1>          

            <Input
              id="searchInput"
              name="search"
              placeholder="Enter Roll no"
              type="search"
              className="w-25"
              onChange={handleChange}
              onKeyPress={keyPress}
            />
          </div>
          <CardBody id="section-to-print">
          {Object.keys(transcript).length > 0 ? (
              <div>
               <Button id="no-print" className="mb-2" onClick={print}>
                 Print
               </Button>
               <table
                 style={{ width: "100%", backgroundColor: "#D6EEEE" }}
                 className="table-transcript"
                 hover
                 responsive
               >
                 <tr className="table-transcript">
                   <th style={{ width: "15%" }} className="table-transcript">
                     Name
                   </th>
                   <td colSpan={3} className="table-transcript">
                   { studentDetail?.studentDetail?.name }
                   </td>
                   <th colSpan={3} className="table-transcript">
                     Registration no
                   </th>
                   <td colSpan={4} className="table-transcript">
                   { studentDetail?.studentDetail?.reg_no }
                   </td>
                 </tr>
                 <tr className="table-transcript">
                   <th className="table-transcript">Father/ Guardian Name</th>
                   <td colSpan={3} className="table-transcript">
                   { studentDetail?.studentDetail?.father_name }
                   </td>
                   <th rowSpan={2} colSpan={2} className="table-transcript">
                     Program
                   </th>
                   <td rowSpan={2} colSpan={4} className="table-transcript">
                   { studentDetail?.studentDetail?.program }
                   </td>
                 </tr>
                 <tr className="table-transcript">
                   <th className="table-transcript">Admission Date</th>
                   <td className="table-transcript">
                    { studentDetail?.studentDetail?.admission_date}
                   </td>

                   <th className="table-transcript">Session</th>
                   <td className="table-transcript">
                   { studentDetail?.studentDetail?.session }
                   </td>
                 </tr>

                 <tr className="table-transcript">
                   <th className="table-transcript">Completion Date</th>
                   <td className="table-transcript">
                  22-09-2092
                   </td>
                   <th className="table-transcript">No of semesters</th>
                   <td className="table-transcript">
                   { studentDetail?.studentDetail?.semester }
                   </td>
                   <th colSpan={2} className="table-transcript">
                     Degree
                   </th>
                   <td colSpan={4} className="table-transcript">
                   { studentDetail?.studentDetail?.degree }
                   </td>
                   {/* colspan */}
                 </tr>
               </table>
               <div>
                 <Table
                   className="table-transcript mt-4"
                   style={{ width: "100%" }}
                   hover
                   responsive
                 >
                   <tr className="table-transcript">
                     <th className="table-transcript">Cours Id</th>
                     <th className="table-transcript">Course Title</th>
                     <th className="table-transcript">CR.HRS</th>
                     <th className="table-transcript">Grade</th>
                   </tr>
                  {transcript.map((item,i)=>(
                    <>
                     <tr className="table-transcript">
                     <td className="table-transcript">
                      {item.id}
                     </td>
                     <td className="table-transcript">
                       {item.course_name }
                       </td>
                     <td className="table-transcript">
                     {item.credit_hours}
                     </td>
                     <td className="table-transcript">
                     {item.grade}
                     </td>
                   </tr>
                    </>
                  ))}
                 </Table>
                 <h4>Cumilated</h4>
                 <div>
                   <Table
                     className="table-transcript"
                     style={{ width: "100%" }}
                     hover
                     responsive
                   >
                     <tr className="table-transcript">
                       <th
                         style={{ backgroundColor: "black", color: "white" }}
                         className="table-transcript"
                       >
                         Credit Hours Completed
                       </th>
                       <th
                         style={{ backgroundColor: "black", color: "white" }}
                         className="table-transcript"
                       >
                         Credit Hours Counted Towards CGPA
                       </th>
                       <th
                         style={{ backgroundColor: "black", color: "white" }}
                         className="table-transcript"
                       >
                         Quality Points
                       </th>
                       <th
                         style={{ backgroundColor: "black", color: "white" }}
                         className="table-transcript"
                       >
                         CGPA
                       </th>
                     </tr>
                     <tr className="table-transcript">
                       <td className="table-transcript">
                        { studentDetail?.studentDetail?.credit_hours}
                       </td>
                       <td className="table-transcript">
                       { studentDetail?.studentDetail?.credit_hours}
                       </td>
                       <td className="table-transcript">
                       { studentDetail?.studentDetail?.quality_points}
                       </td>
                       <td className="table-transcript">
                        { studentDetail?.studentDetail?.cgpa}
                       </td>
                     </tr>
                   </Table>
                   <div className="d-flex justify-content-between mt-3">
                     <div style={{width:"40%"}}>
                       <p>
                         GPA <span style={{marginLeft:"15%"}}>GRADE POINT AVERAGE</span>
                       </p>
                       <p>
                         CGPA{" "}
                         <span style={{marginLeft:"12.5%"}}>
                           CUMULATIVE GRADE POINT AVERAGE
                         </span>
                       </p>
                     </div>
                     <div style={{width:"53%"}}>
                       <p>
                         CR. HRS{" "}
                         <span style={{marginLeft:"20%"}}>COURSE CREDIT HOURS</span>
                       </p>
                       <p>
                         CGPA{" "}
                         <span style={{marginLeft:"23%"}}>
                           TOTAL QUALITY POINTS/TOTAL CR.HRS
                         </span>
                       </p>
                       <p>
                         QUALITY POINTS{" "}
                         <span style={{marginLeft:"10%"}}>GRADE POINTS * CR.HRS</span>
                       </p>
                     </div>
                   </div>
                   <div className="d-flex justify-content-between mt-3">
                     <div>
                       <p>
                         A <span className="ms-2">4.0</span>
                         <span className="ms-2">GRADE POINTS</span>
                       </p>
                       <p>
                         B <span className="ms-2">3.0</span>
                         <span className="ms-2">GRADE POINTS</span>
                       </p>
                       <p>
                         C <span className="ms-2">2.0</span>
                         <span className="ms-2">GRADE POINTS</span>
                       </p>
                       <p>
                         D <span className="ms-2">1.0</span>
                         <span className="ms-2">GRADE POINTS</span>
                       </p>
                       <p>(")NON GPA NON CREDIT COURSES</p>
                     </div>
                     <div className="transcript-li">
                       <p>
                         B+ <span className="ms-2">3.5</span>
                         <span className="ms-2">GRADE POINTS</span>
                       </p>
                       <p>
                         C+ <span className="ms-2">2.5</span>
                         <span className="ms-2">GRADE POINTS</span>
                       </p>
                       <p>
                         D+ <span className="ms-2">1.5</span>
                         <span className="ms-2">GRADE POINTS</span>
                       </p>
                       <p>
                         F <span className="ms-4">0.0</span>
                         <span className="ms-2">GRADE POINTS</span>
                       </p>
                     </div>
                     <div className="transcript-paragraph">
                       <p>
                         P <span className="ms-2">Exempted</span>
                       </p>
                       <p>
                         W <span className="ms-2">Withdraw</span>
                       </p>
                       <p>
                         Au <span className="ms-2">Audit</span>
                       </p>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
            ) : (
              <tr>
              <td colSpan={10} className="text-center">
                {loading ? (
                  <Spinner style={{ width: "30px", height: "30px" }} />
                ) : (
                  "No Transcript Data Exists"
                )}
              </td>
            </tr>
            ) }
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
}

export default Transcript;