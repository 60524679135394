import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Spinner, Table } from "reactstrap";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Container from "../../../components/Container";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import Create from "./Create";
import Delete from "./Delete";
import Edit from "./Edit";
import ReactPaginate from "react-paginate";

const Index = () => {
  const [academicCalendars, setAcademicCalendars] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setMeta] = useState(1);

  const fetchAcademicCalendars = async (currentPage) => {
    try {
      const data = (
        await API.get(`/api/academic-calendars?page=${currentPage}`)
      ).data;
      setAcademicCalendars(
        data.data.map((item, index) => {
          item.index = index + 1 + (currentPage - 1) * 10;
          return item;
        })
      );
      setMeta(data.meta.last_page);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handlePageClick = async (page) => {
    fetchAcademicCalendars(page.selected + 1);
    setCurrentPage(page.selected + 1);
  };

  useEffect(() => {
    fetchAcademicCalendars(currentPage);
  }, [refresh]);

  return (
    <AppLayout>
      <Breadcrumbs
        title="Academic Calendar"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Academic Calendar",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="border-0 shadow mt-5" style={{ minHeight: "70vh" }}>
          <CardBody>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h1 className="mb-0">All Academic Calendar</h1>
              <Create
                academicCalendar={academicCalendars}
                fetchAcademicCalendar={fetchAcademicCalendars}
                currentPage={currentPage}
              />
            </div>
            <Table hover responsive>
              <thead>
                <tr className="bg-shade-blue">
                  <th>#</th>
                  <th>name</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {academicCalendars.length > 0 ? (
                  academicCalendars.map((academicCalendar, index) => (
                    <tr
                      key={`academicCalendar-${academicCalendar.id}`}
                      style={{ verticalAlign: "middle" }}>
                      <th scope="row">{academicCalendar.index}</th>
                      <td>{academicCalendar.name}</td>
                      {academicCalendar.is_active == 1 ? (
                        <td>active</td>
                      ) : (
                        <td>inactive</td>
                      )}
                      <td>
                        {moment(academicCalendar.created_at).format("DD/MM/YY")}
                      </td>
                      <td>
                        {moment(academicCalendar.updated_at).format("DD/MM/YY")}
                      </td>
                      <td>
                        <Edit
                          academicCalendar={academicCalendar}
                          fetchAcademicCalendar={fetchAcademicCalendars}
                          currenPage={currentPage}
                        />
                        <Delete
                          academicCalendar={academicCalendar}
                          refresh={refresh}
                          setRefresh={setRefresh}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {loading ? (
                        <Spinner style={{ width: "30px", height: "30px" }} />
                      ) : (
                        "No Academic Calendar Exists"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
              <div className="w-100 d-table-caption pt-5">
                <ReactPaginate
                  breakLabel={"..."}
                  nextLabel={">>"}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  pageCount={meta}
                  previousLabel={"<<"}
                  margibPageDisply={3}
                  containerClassName={
                    "Pagination d-flex justify-content-center list-unstyled w-100"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </Table>
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
};

export default Index;
