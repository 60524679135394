import {
  LOGIN,
  LOGOUT,
  SET_AUTH_ERRORS,
  SET_AUTH_LOADER,
  CLEAR_AUTH_LOADER,
} from "../constants/actionTypes";
import API from "../utils/API";

export const login = (payload) => async (dispatch) => {
  dispatch({
    type: SET_AUTH_LOADER,
  });

  await API.get("/sanctum/csrf-cookie");

  try {
    await API.post("/login", payload);
    const response = await API.get("/api/user");

    dispatch({
      type: LOGIN,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: SET_AUTH_ERRORS,
    });
  }
};

export const loadUser = () => async (dispatch) => {
  dispatch({
    type: SET_AUTH_LOADER,
  });

  try {
    const response = await API.get("/api/user");

    dispatch({
      type: LOGIN,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: CLEAR_AUTH_LOADER,
    });
  }
};

export const logout = () => async (dispatch) => {
  dispatch({
    type: SET_AUTH_LOADER,
  });

  await API.get("/sanctum/csrf-cookie");

  try {
    await API.post("/logout");
    dispatch({
      type: LOGOUT,
    });
  } catch (error) {
    dispatch({
      type: SET_AUTH_LOADER,
    });
  }
};
