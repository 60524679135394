import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import API from "../../utils/API";

const Create = ({ accounts, fetchAccounts }) => {
  // Modal open state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [accountDetails, setAccountDetails] = useState(null);

  const [data, setData] = useState({
    name: "",
    account_id: "",
    account_type_id: "",
    account_detail_type_id: "",
    parent_account_id: "",
    additional_details: "",
    opening_balance: 0,
    opening_balance_as_of: "",
  });

  useEffect(() => {
    const fetchAccountTypes = async () => {
      try {
        setLoading(true);
        const response = (await API.get("/api/account-types")).data;
        setAccountTypes(response);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchAccountTypes();
  }, []);

  useEffect(() => {
    if (data.account_type_id) {
      const fetchAccountDetails = async () => {
        try {
          setLoading(true);
          const response = (
            await API.get(`/api/account-types/${data.account_type_id}`)
          ).data;
          setAccountDetails(response);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      };

      fetchAccountDetails();
    } else {
      setAccountDetails(null);
    }
  }, [data.account_type_id]);

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const response = await API.post("/api/accounts", data);

      setLoading(false);

      fetchAccounts();
      toggle();
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Button color="primary" className="rounded-pill" onClick={toggle}>
        <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
        Create New
      </Button>
      <Modal centered scrollable size="lg" toggle={toggle} isOpen={modal}>
        <ModalHeader toggle={toggle}>New Account</ModalHeader>
        <Form method="POST" action="#" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label htmlFor="name" className="label-required">
                Account Name
              </Label>
              <Input
                className="rounded-0"
                id="name"
                type="name"
                name="name"
                maxLength={255}
                value={data.name}
                onChange={handleChange}
                required
                autoFocus
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="account_id" className="label-required">
                Account ID
              </Label>
              <Input
                className="rounded-0"
                id="account_id"
                type="account_id"
                name="account_id"
                maxLength={255}
                value={data.account_id}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="account_type_id" className="label-required">
                Account Type
              </Label>
              <Input
                className="rounded-0"
                id="account_type_id"
                type="select"
                required
                name="account_type_id"
                value={data.account_type_id}
                onChange={handleChange}
              >
                <option value="">Select an account type</option>
                {accountTypes.map((accountType) => (
                  <option
                    key={`account-type-${accountType.id}`}
                    value={accountType.id}
                  >
                    {accountType.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            {data.account_type_id && data.account_type_id != 2 && (
              <FormGroup>
                <Label htmlFor="account_detail_type_id">
                  Account Detail Type
                </Label>
                <Input
                  className="rounded-0"
                  id="account_detail_type_id"
                  required
                  type="select"
                  name="account_detail_type_id"
                  value={data.account_detail_type_id}
                  onChange={handleChange}
                >
                  <option value="">Select an account detail type</option>
                  {accountDetails?.detail_types?.map((accountDetailType) => (
                    <option
                      key={`account-detail-type-${accountDetailType.id}`}
                      value={accountDetailType.id}
                    >
                      {accountDetailType.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            )}
            <FormGroup>
              <Label htmlFor="parent_account_id">
                Parent Account (Only for subaccounts)
              </Label>
              <Input
                className="rounded-0"
                id="parent_account_id"
                type="select"
                name="parent_account_id"
                value={data.parent_account_id}
                onChange={handleChange}
              >
                <option value="">Select a parent account</option>
                {accounts.map((account) => (
                  <option
                    key={`select-account-${account.id}`}
                    value={account.id}
                  >
                    {account.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="additional_details">Description</Label>
              <Input
                className="rounded-0"
                id="additional_details"
                type="text"
                name="additional_details"
                value={data.additional_details}
                onChange={handleChange}
              />
            </FormGroup>
            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="opening_balance">Opening Balance</Label>
                  <Input
                    className="rounded-0"
                    id="opening_balance"
                    type="number"
                    name="opening_balance"
                    value={data.opening_balance}
                    onChange={handleChange}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="opening_balance_as_of">
                    Opening Balance as of
                  </Label>
                  <Input
                    className="rounded-0"
                    id="opening_balance_as_of"
                    type="date"
                    name="opening_balance_as_of"
                    value={data.opening_balance_as_of}
                    onChange={handleChange}
                  />
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggle}
              color="light"
              className="rounded-0"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="rounded-0"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading && (
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              )}
              Submit
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Create;
