import { loadUser } from "./actions/auth";
import EmployeesData from "./pages/Hrm/Employees/EmployeesData";
import PayrollAuditing from "./pages/Hrm/PayrollAuditing/PayrollAuditing";
import Leaves from "./pages/Hrm/Leaves/Leaves";
import { EmployesProfile } from "./pages/Hrm/Employees/EmployesProfile";

import "./css/bootstrap.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProtectedRoute from "./utils/ProtectedRoute";
import GuestRoute from "./utils/GuestRoute";
import CampusesIndex from "./pages/Academics/Campuses/Index";
import RoomsIndex from "./pages/Academics/Rooms/Index";
import AcademicSessionsIndex from "./pages/Academics/AcademicSessions/Index";
import StudyLevelsIndex from "./pages/Academics/StudyLevels/Index";
import SessionDetailIndex from "./pages/Academics/SessionDetails/Index";
import SessionsIndex from "./pages/Academics/Sessions/Index";
import FacultiesIndex from "./pages/Academics/Faculties/Index";
import DaysIndex from "./pages/Academics/Days/Index";
import ProgramsIndex from "./pages/Academics/Programs/Index";
import DepartmentsIndex from "./pages/Academics/Departments/Index";
import TimeSlotsIndex from "./pages/Academics/TimeSlots/Index";
import AdmissionsIndex from "./pages/Admissions/Index";
import AdmissionsShow from "./pages/Admissions/Show";
import AccountsIndex from "./pages/Accounts/Index";
import VoucherShow from "./pages/Vouchers/Show";
import Login from "./pages/Auth/Login";
import FullscreenLoader from "./components/FullscreenLoader";
import { Profile } from "./pages/Faculty/Profile";
import { LectureTimetable } from "./pages/Faculty/LectureTimetable";
import { ExamsSchedule } from "./pages/Faculty/ExamsSchedule";
import { Grading } from "./pages/Faculty/Grading/Index";
import { QuestionBankManagement } from "./pages/Faculty/QuestionBank/QuestionBankManagement";
import BlocksIndex from "./pages/Academics/Blocks/Index";
import CoursesIndex from "./pages/Academics/Courses/Index";
import AcademicCalendarIndex from "./pages/Academics/AcademicCalendar/Index";
import SectionsIndex from "./pages/Academics/Sections/Index";
import EnrollmentsIndex from "./pages/Academics/Enrollments/Index";
import TimeTablesIndex from "./pages/Academics/TimeTables/Index";
import { Create } from "./pages/Faculty/Attendance/Create";
import { Show } from "./pages/Faculty/Attendance/Show";
import { Edit } from "./pages/Faculty/Attendance/Edit";
import { Index } from "./pages/Faculty/Attendance/Index";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Transcript from "./pages/Hrm/AwardListManagement/Transcript";
import Gazette from "./pages/Hrm/AwardListManagement/Gazette";
import { AddResult } from "./pages/Faculty/Grading/AddResult";
import { ShowResult } from "./pages/Faculty/Grading/ShowResult";
import VoucherReport from "./pages/VoucherReports/Index";
import RaiwindVoucherReport from "./pages/VoucherReports/RaiwindVoucherReports/Index";
import RaiwindDailyVoucherReport from "./pages/Admissions/Raiwind/Index";

function App() {
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  if (auth.loading) return <FullscreenLoader />;

  return (
    <div className="App">
      <ToastContainer />

      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<GuestRoute component={<Login />} />} />
            <Route path="/voucher/:voucherId" element={<VoucherShow />} />
            <Route
              path="/home"
              element={<ProtectedRoute component={<Dashboard />} />}
            />

            {/* Accounts */}
            <Route
              path="/admissions"
              element={<ProtectedRoute component={<AdmissionsIndex />} />}
            />
            <Route
              path="/admissions/:applicantId"
              element={<ProtectedRoute component={<AdmissionsShow />} />}
            />
            <Route
              path="/voucher-report"
              element={<ProtectedRoute component={<VoucherReport />} />}
            />
            <Route
              path="/raiwind/daily-voucher-report"
              element={
                <ProtectedRoute component={<RaiwindDailyVoucherReport />} />
              }
            />
            <Route
              path="/raiwind/voucher-report"
              element={<ProtectedRoute component={<RaiwindVoucherReport />} />}
            />
            <Route
              path="/chart-of-accounts"
              element={<ProtectedRoute component={<AccountsIndex />} />}
            />
            <Route
              path="/employees"
              element={<ProtectedRoute component={<EmployeesData />} />}
            />
            <Route
              path="/leaves"
              element={<ProtectedRoute component={<Leaves />} />}
            />
            <Route
              path="/payroll-auditing"
              element={<ProtectedRoute component={<PayrollAuditing />} />}
            />
            <Route
              path="/employee/:employeeId"
              element={<ProtectedRoute component={<EmployesProfile />} />}
            />
            <Route
              path="/transcript"
              element={<ProtectedRoute component={<Transcript />} />}
            />

            <Route
              path="/gazette"
              element={<ProtectedRoute component={<Gazette />} />}
            />

            <Route
              path="/academics/campuses"
              element={<ProtectedRoute component={<CampusesIndex />} />}
            />
            <Route
              path="/academics/rooms"
              element={<ProtectedRoute component={<RoomsIndex />} />}
            />
            <Route
              path="/academics/academics-sessions"
              element={<ProtectedRoute component={<AcademicSessionsIndex />} />}
            />
            <Route
              path="/academics/sessions"
              element={<ProtectedRoute component={<SessionsIndex />} />}
            />
            <Route
              path="/academics/session-details"
              element={<ProtectedRoute component={<SessionDetailIndex />} />}
            />
            <Route
              path="/academics/study-levels"
              element={<ProtectedRoute component={<StudyLevelsIndex />} />}
            />
            <Route
              path="/academics/blocks"
              element={<ProtectedRoute component={<BlocksIndex />} />}
            />
            <Route
              path="/academics/faculties"
              element={<ProtectedRoute component={<FacultiesIndex />} />}
            />
            <Route
              path="/academics/departments"
              element={<ProtectedRoute component={<DepartmentsIndex />} />}
            />
            <Route
              path="/academics/days"
              element={<ProtectedRoute component={<DaysIndex />} />}
            />
            <Route
              path="/academics/courses"
              element={<ProtectedRoute component={<CoursesIndex />} />}
            />
            <Route
              path="/academics/programs"
              element={<ProtectedRoute component={<ProgramsIndex />} />}
            />
            <Route
              path="/academics/timeslots"
              element={<ProtectedRoute component={<TimeSlotsIndex />} />}
            />
            <Route
              path="/academics/academic-calendar"
              element={<ProtectedRoute component={<AcademicCalendarIndex />} />}
            />
            <Route
              path="/academics/sections"
              element={<ProtectedRoute component={<SectionsIndex />} />}
            />
            <Route
              path="/academics/time-tables"
              element={<ProtectedRoute component={<TimeTablesIndex />} />}
            />
            <Route
              path="/academics/enrollments"
              element={<ProtectedRoute component={<EnrollmentsIndex />} />}
            />
            <Route
              path="/profile"
              element={<ProtectedRoute component={<Profile />} />}
            />
            <Route
              path="/lecture-timetable"
              element={<ProtectedRoute component={<LectureTimetable />} />}
            />
            <Route
              path="/exams-schedule"
              element={<ProtectedRoute component={<ExamsSchedule />} />}
            />
            <Route
              path="/grading/:id"
              element={<ProtectedRoute component={<Grading />} />}
            />

            <Route
              path="/add-result/:id"
              element={<ProtectedRoute component={<AddResult />} />}
            />
            <Route
              path="/result/:id"
              element={<ProtectedRoute component={<ShowResult />} />}
            />
            <Route
              path="/question-bank-management/:id"
              element={
                <ProtectedRoute component={<QuestionBankManagement />} />
              }
            />

            <Route
              path="/student-attendance/:id"
              element={<ProtectedRoute component={<Index />} />}
            />
            <Route
              path="/student-attendance/view/:id/:date"
              element={<ProtectedRoute component={<Show />} />}
            />
            <Route
              path="/mark-attendance/:id"
              element={<ProtectedRoute component={<Create />} />}
            />
            <Route
              path="/student-attendance/edit/:id/:date"
              element={<ProtectedRoute component={<Edit />} />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
