import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const GuestRoute = (props) => {
  const auth = useSelector((state) => state.auth);

  if (auth.authData) {
    return <Navigate to='/home' replace />;
  }

  return props.component;
};

export default GuestRoute;
