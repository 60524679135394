import React, { useState } from "react";
import {
  Container,
  Card,
  Input,
  Button,
  CardBody,
  Table,
} from "reactstrap";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AppLayout from "../../../components/layouts/AppLayout";
import API from "../../../utils/API";
import usalogo from "../../../images/images__2_-removebg-preview.png";
import signature from "../../../images/singature1.png";
import signature1 from "../../../images/singature1-removebg-preview.png";

function Gazette() {
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [data, setData] = useState("");
  const [gazetteData, setgazetteData] = useState([]);

  const gazette = async () => {
    try {
      const response = await API.get(
        `/api/gazette?start_date=${startDate}&end_date=${endDate}`
      );
      setData(response);
      setgazetteData(response?.data?.data);
    } catch (error) {}
  };

  const handleStartDate = (e) => {
    setstartDate(e.target.value);
  };

  const handleEndDate = (ev) => {
    setendDate(ev.target.value);
  };

  return (
    <AppLayout>
      <Breadcrumbs
        title="Gazette"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
          {
            title: "Gazette",
            link: null,
          },
        ]}
      />
      <Container>
        <Card className="shadow border-0 mt-5" style={{ minHeight: "70vh" }}>
          <div className="p-3">
            <h1 className="mb-0 text-center border-bottom p-3">Gazette</h1>
            <div className="gazete-date">
              <div>
                <h6>Start Date</h6>
                <Input onChange={handleStartDate} type="date"></Input>
              </div>
              <div>
                <h6>End Date</h6>
                <Input type="date" onChange={handleEndDate}></Input>
              </div>
            </div>
            <div className="border-bottom p-5">
              <Button
                onClick={gazette}
                color="primary"
                className="rounded-0 mt-5 btn btn-md btn-primary"
                style={{ position: "relative", left: "44.5%" }}
              >
                View Gazette
              </Button>
            </div>
          </div>
          <CardBody>
            {Object.keys(data).length > 0 ? (
              <>
                <div className="d-flex">
                  <div>
                    <img
                      style={{ width: "65%", height: "90%" }}
                      src={usalogo}
                      alt=""
                    />
                  </div>

                  <div className="gazette-list">
                    <h1 className="text-center mt-3">
                      University of South Asia
                    </h1>
                    <h4 className="text-center">List of Graduates</h4>
                    <p className="text-center font-weight-bold">
                      ({startDate} to {endDate})
                    </p>
                  </div>
                </div>
                <div>
                  <Table
                    style={{ width: "100%", backgroundColor: "#e2e3e5" }}
                    hover
                    responsive
                  >
                    <thead>
                      <tr className="bg-shade-blue table-gazette">
                        <th className="table-gazette">SR.No</th>
                        <th className="table-gazette">CNIC</th>
                        <th className="table-gazette">Roll no/ Reg.No</th>
                        <th className="table-gazette">Name</th>
                        <th className="table-gazette">Father Name</th>
                        <th className="table-gazette">Date of Enroll.</th>
                        <th className="table-gazette">Date of Compl.</th>
                      </tr>
                    </thead>
                    <tbody className="table-gazette">
                      {Object.keys(gazetteData).length > 0 ? (
                        <>
                          <tr className="table-gazette">
                            <td className="table-gazette">
                              {gazetteData[0].applicant_id}
                            </td>
                            <td className="table-gazette">
                              {gazetteData[0].cnic}
                            </td>
                            <td className="table-gazette">
                              {gazetteData[0].roll_number}
                            </td>
                            <td className="table-gazette">
                              {gazetteData[0].student_name}
                            </td>
                            <td className="table-gazette">
                              {gazetteData[0].parent_name}
                            </td>
                            <td className="table-gazette">
                              {gazetteData[0].date_of_enrol}
                            </td>
                            <td className="table-gazette">
                              {gazetteData[0].complition_date}
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          <h1>No Data Exists</h1>
                        </>
                      )}
                    </tbody>
                  </Table>
                  <div className="row mt-5">
                    <div className="col-md-3">
                      <div>
                      <img
                          style={{ width: "65%", height: "90%", display: "none" }}
                          src={signature1}
                          alt=""
                        />
                      </div>
                      <div>
                        <h6 style={{marginTop:"33.5%"}}>Errors & Omissions Expected (E&OE)</h6>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div>
                        <img
                          style={{ width: "65%", height: "90%" }}
                          src={signature1}
                          alt=""
                        />
                      </div>
                      <div>
                        <h6>Manager MIS</h6>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div>
                      <img
                          style={{ width: "65%", height: "90%" }}
                          src={signature1}
                          alt=""
                        />
                      </div>
                      <div>
                        <h6>Deputy Controller of Examinations</h6>
                      </div>
                    </div>

                    <div className="col-md-3 ">
                      <div>
                      <img
                          style={{ width: "65%", height: "90%" }}
                          src={signature1}
                          alt=""
                        />
                      </div>
                      <div>
                        <h6>Controller of Examinations</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center"></div>
            )}
          </CardBody>
        </Card>
      </Container>
    </AppLayout>
  );
}

export default Gazette;
