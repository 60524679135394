import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Checkbox, Switch, Form, Input, Select } from "antd";

import API from "../../../utils/API";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Spinner,
} from "reactstrap";

const CreateQuestion = ({
  EmployeesData,
  fetchEmployees,
  setMidTerm,
  midTerm,
  refresh,
  setRefresh,
}) => {
  // Modal open state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [objective, setObjective] = useState(false);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [options, setOptions] = useState([1, 2, 3, 4]);

  const params = useParams();

  const { Option } = Select;
  function onClick(e) {
    let val = questionTypes.filter((item) => item.id == e);
    if (val[0].title == "checkboxes") {
      setObjective(true);
    } else {
      setObjective(false);
    }
  }

  const examType = [
    { label: "Final", value: "final" },
    { label: "Midterm", value: "midterm" },
  ];

  const level = [
    { label: "Level 1: Memory Recall", value: "Level 1: Memory Recall" },
    { label: "Level 2: Comprehensive", value: "Level 2: Comprehensive" },
    { label: "Level 3: Application", value: "Level 3: Application" },
    { label: "Level 4: Analysis", value: "Level 4: Analysis" },
    { label: "Level 5: Evaluation", value: "Level 5: Evaluation" },
    { label: "Level 6: Creation", value: "Level 6: Creation" },
  ];
  const onFinish = (values) => {
    postQuestion(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const toggle = () => setModal(!modal);

  const postQuestion = async (e) => {
    try {
      setLoading(true);
      const response = await API.post("/api/question", e);

      setRefresh(!refresh);
      setModal(false);
      setLoading(false);
      toast.success("Question Created Successfully");
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };
  const fetchQuestionTypes = async () => {
    try {
      const response = await API.get("/api/question-types");
      setQuestionTypes(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchQuestionTypes();
  }, []);

  return (
    <div>
      <Button color="primary" className="rounded-pill" onClick={toggle}>
        <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
        Create New
      </Button>

      <Modal
        modalClassName="custommodal"
        centered
        scrollable
        size="xl"
        toggle={toggle}
        isOpen={modal}
        fade={true}
      >
        <ModalHeader toggle={toggle}>Create New Question</ModalHeader>
        <ModalBody>
          <Form
            name="basic"
            layout="vertical"
            initialValues={{
              exam_question_bank_id: params?.id,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Question"
              name="question"
              rules={[
                {
                  required: true,
                  message: "Please Type Question!",
                },
              ]}
            >
              <Input placeholder="Type question" />
            </Form.Item>

            <Form.Item
              name="type_of"
              label="Exam Type"
              rules={[
                {
                  required: true,
                  message: "Please Select Exam Type!",
                },
              ]}
            >
              <Select placeholder="Select exam type" options={examType} />
            </Form.Item>

            <Form.Item style={{ display: "none" }} name="exam_question_bank_id">
              <input style={{ display: "none" }} initialValue={params?.id} />
            </Form.Item>
            <Form.Item
              name="level"
              label="Level"
              rules={[
                {
                  required: true,
                  message: "Please Select Level!",
                },
              ]}
            >
              <Select placeholder="Select Question level" options={level} />
            </Form.Item>
            <Form.Item
              name="question_type_id"
              label="Question Type"
              rules={[
                {
                  required: true,
                  message: "Please Select Question Type!",
                },
              ]}
            >
              <Select placeholder="Select question type" onSelect={onClick}>
                {questionTypes &&
                  questionTypes.map((type, index) => (
                    <Option key={index} value={type?.id}>
                      {type.title}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            {objective && (
              <div className="form-group mb-3 ">
                {options?.map((option, index) => (
                  <>
                    <div
                      class="form-check mb-3"
                      style={{ display: "inline-flex" }}
                    >
                      <Form.Item
                        initialValue={false}
                        valuePropName="checked"
                        name={["options", index, "is_correct"]}
                        style={{ marginRight: "5px" }}
                      >
                        <Checkbox />
                      </Form.Item>
                      <Form.Item name={["options", index, "option"]}>
                        <Input placeholder={["Option ", index + 1]} />
                      </Form.Item>
                    </div>
                  </>
                ))}
              </div>
            )}

            <Form.Item>
              <Button color="primary" htmlType="submit" disabled={loading}>
                {loading && (
                  <Spinner className="me-2" size="sm">
                    Loading...
                  </Spinner>
                )}
                Submit
              </Button>
            </Form.Item>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CreateQuestion;
