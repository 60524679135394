import "../../../css/modal.css";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";

import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavLink,
  Spinner,
  Table,
} from "reactstrap";
import API from "../../../utils/API";

const BulkEnrollment = ({
  showBulkModal,
  setShowBulkModal,
  selectedTimetable,

  fetchTimetable: fetchTimetable,
}) => {
  // Modal open state
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const [selectedStudents, setSelectedStudents] = useState([]);
  const [enrollments, setEnrollments] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setMeta] = useState(1);

  // Toggle for Modal
  const toggle = () => setShowBulkModal(!showBulkModal);

  const bulkEnrollStudent = async (enrollment) => {
    try {
      let enrollments = selectedStudents.map((item) => {
        return {
          timetable_id: selectedTimetable.id,
          campus_id: selectedTimetable.campus_id,
          is_repeat: 1,
          is_active: selectedTimetable.is_active,
          roll_number: item.roll_number,
          is_admin: 1,
        };
      });
      let d = {
        enrollments: enrollments,
      };
      const data = (await API.post("/api/bulk-enrollment", d)).data.message;
      toast(data);
      toggle();
      fetchEnrollments(selectedTimetable, currentPage);
      setSelectedStudents([]);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchEnrollments = async (timetable, currentPage, filter) => {
    try {
      setLoading(true);
      let url = `/api/bulk-enrollment?timetable_id=${timetable.id}`;
      if (filter) {
        url = url + `&search=${filterValue}`;
        setCurrentPage(1);
      } else {
        url = url + `&page=${currentPage}`;
      }
      const data = (await API.get(url)).data;
      setLoading(false);
      let cPage = 1;
      if (filter) {
        cPage = 1;
      } else {
        cPage = currentPage;
      }

      setEnrollments(
        data.data.map((item, index) => {
          item.index = index + 1 + (cPage - 1) * 20;
          return item;
        })
      );

      setMeta(data.meta.last_page);

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageClick = async (page) => {
    fetchEnrollments(selectedTimetable, page.selected + 1);
    setCurrentPage(page.selected + 1);
  };

  useEffect(() => {
    if (selectedTimetable) fetchEnrollments(selectedTimetable, currentPage);
  }, [selectedTimetable]);

  const handleChangeCheckbox = (e, enrollment) => {
    if (e.target.checked) {
      setSelectedStudents(selectedStudents.concat(enrollment));
    } else {
      setSelectedStudents(
        selectedStudents.filter((item) => item.id !== enrollment.id)
      );
    }
  };

  const handleFilter = (e) => {
    setFilterValue(e.target.value);
  };

  if (!showBulkModal) return;

  return (
    <div>
      <NavLink className="rounded-pill bg-none" onClick={toggle}>
        Enroll Student
      </NavLink>

      <Modal
        modalClassName="custommodal"
        centered
        scrollable
        size="xl"
        toggle={toggle}
        isOpen={showBulkModal}
        fade={true}>
        <ModalHeader toggle={toggle}>Enroll Students</ModalHeader>
        <div className="row">
          <div className="col-md-4">
            <FormGroup className="mt-4 ms-3">
              <Input
                className="rounded-2"
                placeholder="search"
                value={filterValue}
                onInput={(e) => handleFilter(e, selectedTimetable)}
                autoFocus
              />
            </FormGroup>
          </div>
          <div className="col-md-1 mt-4 p-0">
            <button
              className="btn btn-sm btn-primary d-block w-100 mb-2 btn btn-primary p-2"
              type="button"
              onClick={() =>
                fetchEnrollments(selectedTimetable, currentPage, true)
              }>
              Search
            </button>
          </div>
        </div>
        <Form>
          <ModalBody>
            <Table hover responsive>
              <thead>
                <tr className="bg-shade-blue">
                  <th>#</th>
                  <th>Roll Number</th>
                  <th className="text-center">Select</th>
                </tr>
              </thead>
              <tbody>
                {enrollments.length > 0 ? (
                  enrollments.map((enrollment, index) => (
                    <tr
                      key={`enrollment-${enrollment.id}`}
                      style={{ verticalAlign: "middle" }}>
                      <th scope="row">{enrollment.index}</th>
                      <td>{enrollment.roll_number}</td>
                      <td className="text-center">
                        {" "}
                        <FormGroup className="mt-4">
                          <Input
                            className="rounded-2"
                            id="checkbox"
                            type="checkbox"
                            name="checkbox"
                            maxLength={255}
                            onChange={(e) =>
                              handleChangeCheckbox(e, enrollment)
                            }
                          />
                        </FormGroup>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {loading ? (
                        <Spinner style={{ width: "30px", height: "30px" }} />
                      ) : (
                        "No Students Exists"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter className="border-0">
            <Button
              type="button"
              onClick={toggle}
              color="light"
              className="rounded-2">
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => bulkEnrollStudent()}
              color="primary"
              className="rounded-2">
              Submit
            </Button>{" "}
          </ModalFooter>
          {enrollments.length > 0 ? (
            <div className="w-100 pt-3 pb-4">
              <ReactPaginate
                breakLabel={"..."}
                nextLabel={">>"}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={meta}
                previousLabel={"<<"}
                margibPageDisply={3}
                containerClassName={
                  "Pagination d-flex justify-content-center list-unstyled w-100"
                }
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          ) : (
            <tr>
              <td colSpan={10} className="text-center"></td>
            </tr>
          )}
        </Form>
      </Modal>
    </div>
  );
};
export default BulkEnrollment;
