import {
  faArchive,
  faBuildingColumns,
  faReceipt,
  faRepeat,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import AppLayout from "../components/layouts/AppLayout";
import StatCard from "../components/StatCard";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "reactstrap";

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
  return (
    <AppLayout>
      <Breadcrumbs
        title="Dashboard"
        links={[
          {
            title: "Dashboard",
            link: null,
          },
        ]}
      />

      <div className="mx-5">
        <div className="row">
          <div className="col-md-3">
            <StatCard
              icon={faBuildingColumns}
              title="Active Campuses"
              stat="04"
            />
          </div>
          <div className="col-md-3">
            <StatCard icon={faArchive} title="Programs" stat="196" />
          </div>
          <div className="col-md-3">
            <StatCard icon={faArchive} title="Voucher Approvals" stat="1397" />
          </div>
          <div className="col-md-3">
            <StatCard
              icon={faBuildingColumns}
              title="Admissions Intake"
              stat="Fall 2022"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-7 mt-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-end justify-content-between">
                  <div>
                    <div className="small">Last updated 12/04/2022</div>
                    <div className="h2 fw-bold">Recent Activities</div>
                  </div>
                </div>
                <div
                  className="mt-3"
                  style={{ overflow: "auto", height: "500px", width: "100%" }}
                >
                  <Table hover striped>
                    <thead className="bg-dark text-white">
                      <tr>
                        <th>#</th>
                        <th>Activity</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>
                          F19-BSCS-20001 submitted "application fee" for
                          approval
                        </td>
                        <td>12/04/2022</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>
                          F19-BSCS-20002 submitted "application fee" for
                          approval
                        </td>
                        <td>12/04/2022</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>
                          F19-BSCS-20003 requested for "1st Semester Refund"
                        </td>
                        <td>12/04/2022</td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>
                          F19-BSCS-20001 submitted "1st Semester Fee" for
                          approval
                        </td>
                        <td>12/04/2022</td>
                      </tr>
                      <tr>
                        <th scope="row">1</th>
                        <td>
                          F19-BSCS-20001 submitted "application fee" for
                          approval
                        </td>
                        <td>12/04/2022</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>
                          F19-BSCS-20002 submitted "application fee" for
                          approval
                        </td>
                        <td>12/04/2022</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>
                          F19-BSCS-20003 requested for "1st Semester Refund"
                        </td>
                        <td>12/04/2022</td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>
                          F19-BSCS-20001 submitted "1st Semester Fee" for
                          approval
                        </td>
                        <td>12/04/2022</td>
                      </tr>
                      <tr>
                        <th scope="row">1</th>
                        <td>
                          F19-BSCS-20001 submitted "application fee" for
                          approval
                        </td>
                        <td>12/04/2022</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>
                          F19-BSCS-20002 submitted "application fee" for
                          approval
                        </td>
                        <td>12/04/2022</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 mt-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-end justify-content-between">
                  <div>
                    <div className="small">Breakdown for</div>
                    <div className="h2 fw-bold">Expenses</div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="py-1 px-2 me-2 bg-secondary">
                      <FontAwesomeIcon
                        icon={faRepeat}
                        className="text-light-blue"
                      ></FontAwesomeIcon>
                    </div>
                    <select
                      name="fiscal_year"
                      id="fiscal_year"
                      className="form-select rounded-0 bg-transparent border-0 border-bottom"
                    >
                      <option value="2022">Fiscal Year 22</option>
                      <option value="2021">Fiscal Year 21</option>
                    </select>
                  </div>
                </div>
                <div
                  className="mt-3"
                  style={{ height: "500px", width: "100%" }}
                >
                  <Pie
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                    data={{
                      labels: [
                        "General",
                        "Salaries",
                        "Utilities",
                        "Repair & Mantainance",
                        "Fee & Subscriptions",
                        "Insurance",
                      ],
                      datasets: [
                        {
                          label: "Expense Breakdown",
                          data: [20, 60, 15, 2, 2, 1],
                          backgroundColor: [
                            "rgba(255, 99, 132, 1)",
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 206, 86, 1)",
                            "rgba(75, 192, 192, 1)",
                            "rgba(153, 102, 255, 1)",
                            "rgba(255, 159, 64, 1)",
                          ],
                          hoverOffset: 4,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Dashboard;
