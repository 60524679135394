import { faPlusCircle, faMultiply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../css/modal.css";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import API from "../../../utils/API";

const Create = ({ academicCalendar, fetchAcademicCalendar, currentPage }) => {
  // Modal open state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const defaltData = {
    name: "",
    type: "",
    is_active: 0,
    dates: [],
  };
  const [data, setData] = useState(defaltData);

  // Toggle for Modal
  const toggle = () => setModal(!modal);
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData(document.getElementById("form"));
      formData.append("is_active", data.is_active);
      dates.forEach((date, index) => {
        formData.append(`dates[${index}][id]`, date.id);
        formData.append(`dates[${index}][occasion]`, date.occasion);
      });

      dates.forEach((date, index) => {
        formData.append(`dates[${index}][id]`, date.id);
        formData.append(`dates[${index}][date]`, date.date);
      });
      const response = await API.post("/api/academic-calendars", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      fetchAcademicCalendar(currentPage);
      toggle();
      setData(defaltData);
      setDates([]);
      setErrors([]);
    } catch (error) {
      setErrors(
        error?.response?.data?.errors ? error.response.data.errors : []
      );
      setLoading(false);
    }
  }

  const handleChange = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleChangeCheckbox = (e) => {
    if (errors.length) setErrors([]);
    setData({ ...data, [e.target.name]: e.target.checked ? 1 : 0 });
  };

  const [dates, setDates] = useState([]);

  let addDate = () => {
    setDates([
      ...dates,
      {
        id: uuidv4(),
        occasion: "",
        date: "",
      },
    ]);
  };

  let removeDate = (id) => {
    const filteredDates = dates.filter((date) => date.id !== id);
    setDates(filteredDates);
  };

  const handleOccasionChange = (e, id) => {
    const updatedDates = dates.map((date) => {
      if (date.id !== id) return date;
      date.occasion = e.target.value;

      return date;
    });

    setDates(updatedDates);
  };
  const handleDateChange = (e, id) => {
    const updatedDates = dates.map((date) => {
      if (date.id !== id) return date;
      date.date = e.target.value;

      return date;
    });

    setDates(updatedDates);
  };

  return (
    <div>
      <Button color="primary" className="rounded-pill" onClick={toggle}>
        <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
        Create New
      </Button>

      <Modal
        modalClassName="custommodal"
        centered
        scrollable
        size="xl"
        toggle={toggle}
        isOpen={modal}
        fade={true}>
        <ModalHeader toggle={toggle}>Add Academic Calendar</ModalHeader>
        <Form id="form" method="POST" action="#" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for="name" className="label-required">
                Name
              </Label>
              <Input
                className="rounded-2"
                id="name"
                type="text"
                name="name"
                maxLength={255}
                value={data.name}
                onChange={handleChange}
                required
                autoFocus
              />
              {errors.name && (
                <Label className="text-danger mt-2" for="name">
                  {errors.name}
                </Label>
              )}
            </FormGroup>

            <FormGroup>
              <Label For="type" className="label-required">
                Type
              </Label>
              <Input
                className="rounded-2"
                id="type"
                type="select"
                name="type"
                maxLength={255}
                value={data.type}
                onChange={handleChange}
                required>
                <option value="">Select Type</option>
                <option>Events</option>
                <option>Festivals</option>
                <option>Academics</option>
              </Input>
              {errors.type && (
                <Label className="text-danger mt-2" for="type">
                  {errors.type}
                </Label>
              )}
            </FormGroup>

            <FormGroup>
              <Label className="label-required">Date</Label>

              {dates.map((date, index) => (
                <div
                  style={{ width: "93%" }}
                  className="form-inline position-relative d-flex"
                  key={`date-${index}`}>
                  <Input
                    type="text"
                    value={date.occasion}
                    onChange={(e) => handleOccasionChange(e, date.id)}
                    className="rounded-2 mb-3 me-3"
                    placeholder="Occasion"
                    autoFocus
                    required
                  />

                  <Input
                    type="date"
                    value={date.date}
                    onChange={(e) => handleDateChange(e, date.id)}
                    className="rounded-2 mb-3"
                    placeholder="date"
                    autoFocus
                    required
                  />

                  {index > 0 ? (
                    <button
                      type="button"
                      className="button remove calendar-remove"
                      onClick={() => removeDate(date.id)}>
                      <FontAwesomeIcon
                        icon={faMultiply}
                        className=""></FontAwesomeIcon>
                    </button>
                  ) : null}
                </div>
              ))}
              <div className="button-section text-end">
                <button
                  className="button add rounded-2 btn btn-primary"
                  type="button"
                  onClick={() => addDate()}>
                  Add Another
                </button>
              </div>
            </FormGroup>

            <FormGroup>
              <Label for="is_active" className="label-required">
                Active
              </Label>
              <Input
                className="rounded-2"
                id="is_active"
                type="checkbox"
                name="is_active"
                maxLength={255}
                value={data.is_active}
                onChange={handleChangeCheckbox}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={toggle}
              color="light"
              className="rounded-2">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="rounded-2"
              disabled={loading}>
              {loading && (
                <Spinner className="me-2" size="sm">
                  Loading...
                </Spinner>
              )}
              Submit
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Create;
