import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Checkbox, Switch, Form, Input, Select } from "antd";

import API from "../../../utils/API";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

const Create = ({ setGradingData, gradingData, refresh, setRefresh }) => {
  // Modal open state
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [examTypes, setExamTypes] = useState();

  const params = useParams();
  const { Option } = Select;

  const onFinish = (values) => {
    addGrade(values);
    setModal(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    toast.success(errorInfo);
  };
  const toggle = () => setModal(!modal);

  const addGrade = async (e) => {
    try {
      setLoading(true);
      const response = await API.post("/api/grading", e);

      setModal(false);
      setLoading(false);
      setRefresh(!refresh);
      response.log(response.errors.message, "error");
      toast.success("Grade Added Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const fetchExamTypes = async () => {
    try {
      const response = await API.get("/api/exam-types");
      setExamTypes(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchExamTypes();
  }, [refresh]);

  return (
    <div>
      <Button color="primary" className="rounded-pill" onClick={toggle}>
        <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
        Add New
      </Button>
      <Modal
        centered
        scrollable
        size="md"
        modalClassName="delete-modal"
        toggle={toggle}
        isOpen={modal}
        fade={true}
      >
        <ModalBody className="py-4">
          <h3 className="text-center">Add Grade</h3>
          <Form
            name="control-ref"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              initialValue={params?.id}
              style={{ display: "none" }}
              name="timetable_id"
            >
              <input style={{ display: "none" }} />
            </Form.Item>
            <Form.Item
              name="exam_type_id"
              label="Exam Type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Select Exam type" allowClear>
                {examTypes?.map((type, index) => (
                  <option key={index} value={type?.id}>
                    {type?.type.charAt(0).toUpperCase() + type?.type.slice(1)}
                  </option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="total_weightage"
              label="Weightage"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="total_marks"
              label="Total Marks"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <div className="btns-delete-modal mt-3">
              <Button
                type="button"
                onClick={toggle}
                color="secondary"
                className="rounded-2 m-2"
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                color="primary"
                className="m-2 btn btn-primary"
              >
                Add
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Create;
